@import "variables";
@import "mixins";

/* HTML Inputs */

.checkbox, .radio {
	display: inline-block;

	input[type="radio"] {
		&:not(:checked):not(:disabled):hover {
			+ label {
				&:before {
					background-color: @color-xlight-grey2;
					outline: 3px solid @color-xlight-grey2;
				}
			}
		}

		+ label {
			&:before {
				top: 2px;
				border-color: var(--color-form-border);
			}

			&:after {
				top: 5px;
				background-color: var(--color-secondary);
			}
		}

		&:checked {
			&:hover {
				+ label {
					&:before {
						outline: 3px solid @color-xlight-grey2;
					}
				}
			}

			+ label {
				&:before {
					border-color: var(--color-secondary);
				}

				&:after {
					color: @color-white;
				}
			}
		}
	}

	input[type="radio"]:focus {

		+ label {
			&:before {
				box-shadow: 0 0 0 2px @color-outline;
				outline: none;
			}
		}
	}

	input[type="checkbox"], input[type="radio"] {
		cursor: pointer;

		+ label {
			font-weight: 400;
			padding-left: 5px;
			padding-right: 3px;
		}
	}

	input[type="checkbox"] {
		margin: 0px 0px 0px -22px;
		height: 100%;

		+ label {
			line-height: normal;
		}

		&:before {
			background-color: @color-white;
			border: 1px solid var(--color-form-border);
		}

		&:not(:checked):not(:disabled):hover {
			+ label {
				&:before {
					background-color: @color-xlight-grey2;
					outline: 2px solid @color-xlight-grey2;
				}
			}
		}

		&:checked {
			&:hover {
				+ label {
					&:before {
						outline: 2px solid @color-xlight-grey2;
					}
				}
			}

			+ label {
				&:before {
					background-color: var(--color-secondary);
					border-color: var(--color-secondary);
				}

				&:after {
					content: '\e968';
					font-family: 'goreact-custom2';
					font-size: .6em;
					font-weight: 700;
					display: flex;
					justify-content: center;
					align-items: center;
					color: @color-white;
					padding-right: 2px;
				}
			}
		}
	}
}

::placeholder {
	color: @color-off-gray !important;
}
