/* Copyright 2013 Rob Wu <rob@robwu.nl>
 * https://github.com/Rob--W/grab-to-pan.js
 **/
.grab-to-pan-grab {
	cursor: grab !important;
}
.grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link) {
	cursor: inherit !important;
}
.grab-to-pan-grab:active,
.grab-to-pan-grabbing {
	cursor: grabbing !important;
}
