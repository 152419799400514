@import '~go-styles/src/_/variables';

feedback-session-live-view {
	display: flex;
	flex: 1;
	min-width: 0;
	background-color: black;

	.live-display {
		display: flex;
		flex: 1;
		min-width: 0;

		> media-preview {
			flex: 1;
			min-width: 0;
		}
	}

	.live-display-overlay {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		position: absolute;
		pointer-events: none;
		color: @color-xlight-grey;
		font-size: 30px;

		.live-stream-off {
			color: @color-white;
			margin-top: 18px;
			font-size: initial;
			text-align: center;
		}
	}

	.mobile-recording-time-display {
		margin-top: 5px;
		color: #aaa;
	}

	.message-overlay {
		position: absolute;
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.live-stream-ended-message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 20px;
		color: @color-xlight-grey;
		text-align: center;
		min-width: 100px;

		// TODO: This could use some re-work
		.heading,
		div,
		p {
			display: flex;
			margin: 0;
			justify-content: center;
			color: @color-white;
		}

		div {
			flex: 1;
			align-items: center;
		}

		.heading {
			width: 100%;
			align-items: flex-end;
		}

		p {
			align-items: flex-start;
		}
	}
}
