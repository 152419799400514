@import '~go-styles/src/_/variables';
@controls-size: 150px;

universal-media-chooser {

	.umc-change-warning {
		position: absolute;
		top: 0;
		z-index: 100;
		right: 0;
		left: 0;
		margin: 10px;
	}
}

umc-record-audio,
umc-record-session,
umc-record-video,
umc-library,
umc-upload-video,
umc-youtube-link,
universal-media-chooser,
universal-media-chooser-header {
	display: block;
}

.universal-media-chooser {
	position: relative;
	background-color: @color-white;

	&.umc-upload-video {
		background-color: @color-dark;
	}

	&.umc-record-session {
		background-color: @color-white;
	}

	&.umc-record-audio,
	&.umc-record-video {
		background-color: @color-black;

		ngx-status-overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	& umc-record-session {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.umc-record-session-info-text {
	text-align: left;
}

.umc-record-session-info-text-continue {
	margin-bottom: 30px;
}

.media-chooser-component,
.media-uploader-container,
.umc-record-sidebar,
.umc-record-start,
.universal-media-chooser,
umc-library,
umc-record-audio,
umc-record-session,
umc-record-video,
umc-record-video [ng-transclude],
umc-upload-video,
universal-media-chooser,
universal-media-chooser object {
	min-height: inherit;
	max-height: inherit;
	height: 100%;
}

umc-record-audio [ng-transclude],
umc-record-video [ng-transclude] {
	display: inline-block;
	width: @controls-size;
	padding: 5px;
	box-sizing: border-box;
	background-color: @color-xdark;
	height: 100%;

	.umc-record-button-area {
		text-align: center;
		display: flex;
		justify-content: center;

		button {
			display: flex;
			align-items: center;

			.record-btn-indicator {
				margin-right: 5px;
			}
		}
	}

	.umc-time-display {
		margin: 2px;
		padding: 2px 8px;
		text-align: center;
		min-width: 53px;
		margin-top: 3px;
		font-size: 24px;
		color: @color-light-grey;
		line-height: 20px;
		vertical-align: middle;
	}

	.btn.record-btn {
		color: @color-white;
		background-color: @color-dark2;
		border: none;
		text-shadow: none;
		font-size: 16px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		padding: 6px 10px;

		&:hover {
			background-color: @color-xdark;
		}

		.record-btn-indicator {
			border-radius: 50%;
			width: 16px;
			height: 16px;
			display: inline-block;
			vertical-align: middle;
			margin: 0 1px;
			opacity: .90;
		}

		.record-btn-label {
			vertical-align: middle;
			margin: 0 1px;
		}
	}
}

umc-record-session {
	> div {
		display: flex;
		flex-direction: column;
		padding: 15px;
	}
}

umc-upload-video,
umc-zoom {
	.fine-uploader-container,
	> div {
		height: 100%;
		min-height: inherit;
		max-height: inherit;
	}

	// fixes for fine-uploader in umc context
	.fine-uploader-container {
		display: block;

		.outer {
			position: static;
		}

		.inner,
		.middle,
		.outer,
		ul.files {
			display: block;
			height: 100%;
		}

		.fine-uploader.single ul.files {
			&::after {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			> li.file {
				display: inline-block;
				vertical-align: middle;
			}

			.file .control-bar button.btn,
			.file input {
				margin-bottom: 7px;
			}

			button.btn {
				&.btn-light-grey {
					background-color: @color-med-grey;
				}

				&.btn-med-grey {
					background-color: @color-dark-grey;
					color: @color-white;
				}
			}
		}

		.fine-uploader .qq-upload-button {
			font-size: 100px;
		}

		.fine-uploader.single .selection-area {
			height: 100%;
			padding: 0 50px; // approved by dallin, while erik likes 10%
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: @color-white;

			&:lang(de) {
				// German locale
				padding: 0 45px;
			}

			&::after {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			> div {
				display: inline-block;
				vertical-align: middle;
			}

			i.ficon-upload-outlined {
				display: block;
				margin: 0 auto;
				font-size: .8em;
				line-height: 1;
			}
		}
	}

	@media (max-width: @screen-tablet) {
		.fine-uploader-container {
			.fine-uploader .qq-upload-button {
				font-size: 30px;
			}

			.fine-uploader.single .selection-area {
				padding: 0px;
			}

			i.ficon-upload-outlined {
				font-size: 2em !important;
			}
		}
	}

	@media (max-width: @screen-xs-min) {
		.fine-uploader-container {
			.fine-uploader .qq-upload-button {
				padding: 8px;
				margin: 20px;
			}
		}

		.upload-message {
			padding: 0 5px;
		}
	}
}

.umc-record-start {
	width: 100%;
	position: relative;
	left: 0;
}

.umc-record-sidebar {
	width: 100%;
}

universal-media-chooser {
	display: block;
	height: 100%;

	.library-media-chooser {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.menu-bar {
		height: 40px;
		background-color: @color-light-grey2;
		padding: 0 10px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		.media-title {
			flex: 1;
		}
	}
}

umc-youtube-link {
	display: block;
	height: 100%;

	&.invalid .url {
		background-color: @color-red2;
		color: @color-white;
	}

	&.invalid .invalid-url-error-message {
		display: block;
		color: @color-red2;
	}

	.youtube-link {
		height: 100%;
		position: relative;
	}

	ng-form {
		display: flex;
		flex-flow: column;
		height: 100%;

		.form-group {
			margin: 10px 0;

			input {
				width: 80%;
			}
		}

		.input-container {
			padding: 5px 15px;
		}

		.playlist-container {
			height: 100%;
			overflow: hidden;
		}
	}
}

// slightly darker colors to compensate for darker background
.feedback-tree .umc-option {
	@darken-pct: 15%;
	color: darken(@color-med-grey, @darken-pct);

	&:not(.active):hover {
		color: darken(@color-grey, @darken-pct);
	}
}

.resize-sensor-480 .umc-option {
	padding: 0 5px;

	.umc-option-icon {
		font-size: clamp(15px, 1rem, 30px)
	}
}

.umc-option {
	display: inline-block;
	padding: 0;
	padding: 0 10px;
	color: @color-emperor;
	cursor: pointer;
	position: relative;
	text-align: center;
	@border-size: 10px;
	@border-width: 1px;

	.umc-option-icon {
		font-size: clamp(20px, 1rem, 40px)
	}

	// make sure text falls under icon
	i + span {
		display: block;
	}

	&.active {

		color: @color-link;

		.umc-option-icon {
			color: var(--color-secondary);
		}

		&::after,
		&::before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			z-index: 1;
		}

		&::after {
			border-color: rgba(245, 245, 245, 0);
			border-top-color: @color-off-white;
			border-width: @border-size;
			margin-left: -1 * @border-size;
		}

		&::before {
			border-color: rgba(221, 221, 221, 0);
			border-top-color: @color-light-grey2;
			border-width: @border-size + @border-width;
			margin-left: (@border-size + @border-width) * -1;
		}
	}

	&:not(.active):hover {
		color: @color-grey;
	}
}

.umc-header {
	padding: 5px;
	background-color: @color-off-white;
}

.zoom-loading-spinner {
	font-size: 40px;
}

.zoom-tab-sections-container {
	display: flex;
	height: 100%;
}

.zoom-tab-sections-side-nav {
	flex: 1;
	border-right: 1px solid #e5e5e5;
}

.zoom-tab-sections-side-nav .zoom-tabs-wrapper > div {
	padding: 10px 15px;
	cursor: pointer;
}

.zoom-tab-sections-side-nav a {
	color: #000;
	text-decoration: none !important;
}

.zoom-tab-sections-content {
	flex: 2;
}

.zoom-tab-sections-content .zoom-computer-recordings-tab .qq-upload-button .title,
.fine-uploader-container .fine-uploader .qq-upload-button .title {
	white-space: normal;
}

.zoom-cloud-recordings-tab {
	display: flex;
	height: 100%;

	uib-dropdown {
		position: absolute;
		top: 20px;
		right: 18.5px;
		font-size: 20px;
	}
}

.zoom-center-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 20px 40px;

	.connect-to-zoom-btn {
		margin-top: 10px;
		line-height: 21px;
	}

	&.zoom-cloud-recordings-tab-content {
		> span {
			text-align: center;
		}
	}
}

.zoom-tab-link {
	padding: 10px 15px;
	border: none;
	font: inherit;
	color: inherit;
	width: 100%;
	background: transparent;
	text-align: left;
	&.zoom-tab-link-active {
		background: #e5e5e5;
	}
}

.zoom-recording-header {
	position: absolute;
	top: 25px;
	font-size: 16px;
	font-weight: bold;
	padding: 0 25px;
}

.full {
	width: 100%;
}

.zoom-cloud-content-wrapper {
	height: 100%;
}

.a11y-btn {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	font-size: 20px;
	background: transparent;

	.preview {
		text-decoration: none;
	}
}

.zoom-recording-list {
	position: relative;
	top: 50px;
	height: calc(100% - 50px);
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	.zoom-recording-item {
		position: relative;
		cursor: pointer;
		border-top: 1px solid #eae9e9;
		padding: 8px 25px;
		line-height: 22px;

		&:hover {
			background-color: #edfbff;
		}

		&:before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			border-left: 6px solid transparent;
		}

		.zoom-item-row {
			display: flex;

			.left {
				flex: 3;
				max-width: 250px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.right {
				flex: 1;
				text-align: right;

				.preview {
					color: @color-goreact-badge-font;
					cursor: pointer;
				}
			}

			&.second-row {
				font-size: 12px;
			}

			.start {
				max-width: 176px;
			}

			.recording-type {
				font-size: 10px;
				font-family: proxima-nova, 'Open Sans';
				font-style: italic;
			}
		}

		&.selected {
			&:before {
				border-left: 6px solid #74a7de;
			}

			background-color: #edfbff;

			.topic {
				font-weight: bold;
			}
		}
	}
}

.zoom-recording-list-spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.zoom-recording-list-zero-state {
	display: flex;
	justify-content: center;
	flex-flow: column;
	position: relative;
	top: 50px;
	height: calc(100% - 50px);

	.zoom-recording-list-zero-state-info-one {
		margin-bottom: 0;
	}

	.zoom-recording-list-zero-state-info-two {
		font-size: 12px;
		color: @color-med-grey;
	}

	.zoom-recording-list-zero-state-content {
		text-align: center;
		padding: 20px;
	}

	.sync-zoom-recordings-button-container {
		margin-top: 30px;
	}
}

@media (max-width: @screen-xsm-min) {
	universal-media-chooser-header {
		width: 100%;
	}

	.umc-header {
		width: 100%;
		display: flex;
	}

	.umc-option {
		padding: 0 5px;
	}
}

@media (max-width: @screen-tablet) {
	.zoom-center-content {
		padding: 20px;
	}
}
