@import '~go-styles/src/_/variables';

.modal.rubric-editor-modal {
	z-index: 1071 !important;

	.modal-dialog {
		@media (min-width: 1280px) {
			width: 1280px;
		}
		@media (max-width: 1279px) {
			width: 100%!important;
		}

		&.modal-full {
			.modal-body {
				height: ~'calc(100% - 55px)';
				padding: 0;
			}
		}
	}

	.modal-header {
		background-color: #333;
		padding: 10px;
	}

	.modal-title {
		font-size: 1.3em;
	}

	.modal-body {
		height: 600px;
		padding: 0;

		.rb-canvas {
			overflow-y: auto;
		}
	}

	.modal-title {
		color: #ffffff;
		display: inline-block;
		margin-top: 4px;
	}

	.rubric-editor-total {
		display: flex;
		margin: 0 30px;
		color: @color-white;
		align-items: center;

		.badge {
			font-size: 16px !important;
			padding-left: 12px;
			padding-right: 12px;
			color: @color-white;
			margin-left: 8px;
		}
	}

	.preview-mode {
		// Fixes issue with button being 1px too tall
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.ficon-print {
		font-size: 13px;
	}

	.toolbar {
		display: flex;
		flex-direction: row;
		align-items: center;

		button + button {
			margin-left: 10px;
		}
	}

}
