@import '~go-styles/src/_/variables';

comment-sync-event-viewer {
	@header-height: 42px;
	@footer-height: 42px;
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
	color: @color-white;
	background-color: @color-dark;

	> header {
		height: @header-height;
		display: flex;
		align-items: center;
		padding: 10px;
		background-color: @color-dark;
		border-bottom: 1px solid #3a3a3a;

		.title {
			color: @color-white;
			font-weight: 300;
			font-size: 18px;
			margin-bottom: 0;
			margin-top: 0;
			padding-bottom: 2px;
			flex: 1;

			// Show ellipsis when it gets too small
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			> span {
				vertical-align: middle;

				&:first-child {
					margin-right: 5px;
				}
			}
		}
	}
	> section {
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: inherit;

		.zero-state {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 5%;

			> .zero-state-icon {
				font-size: 90px;
			}

			> .zero-state-description {
				color: @color-white;
				max-width: 300px;
				padding: 20px 40px;
			}
		}
	}

	> footer {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: @footer-height;
		background-color: @color-dark;
		padding: 20px 10px;
		z-index: 100;

		button.close-btn {
			margin-right: 10px;
			display: none;
		}
	}

	.feedback-item-name {
		color: @color-white;
	}

	.ficon-app-edit {
		color: @color-white;
	}

	.edit-btn:hover {
		> * {
			color: @color-dark;
		}
	}

	@media (max-width: @screen-md-min) {
		> footer {
			button.close-btn {
				display: block;
			}
		}
	}

	@media (max-width: @screen-xs-min) {
		> footer {
			button.close-btn {
				display: block;
			}
		}
	}
}
