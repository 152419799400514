@import '../stimulus-video-viewer/style';
@import '../stimulus-document-viewer/style';

stimulus-media-display {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  .stimulus-media-display-loading-overlay {
	color: @color-white;
	text-align: center;
	pointer-events: none;

	> .inner {
	  background-color: @color-black;
	  margin-top: -60px;
	  padding: 10px;
	  border-radius: 5px;
	  white-space: nowrap;
	  opacity: 0.65;
	}
  }

  .stimulus-media-display-restrict-preview-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	color: @color-white;
	background-color: @color-black;
	text-align: center;
	opacity: 1;
	z-index: 4;
  }

  .stimulus-media-display-loading-overlay,
  .stimulus-media-display-restrict-preview-overlay {
	> .inner {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
  }
}
