@import './_/variables';

/*  Extended ficon class properties   */
.btn [class^="ficon-"],
.nav [class^="ficon-"],
.btn [class*=" ficon-"],
.nav [class*=" ficon-"] {
  display: inline;
  /* keeps button heights with and without icons the same */
}
/*.btn [class^="ficon-"].ficon-large,*/
/*.nav [class^="ficon-"].ficon-large,*/
/*.btn [class*=" ficon-"].ficon-large,*/
/*.nav [class*=" ficon-"].ficon-large {*/
/*line-height: .9em;*/
/*}*/
.btn [class^="ficon-"].ficon-spin,
.nav [class^="ficon-"].ficon-spin,
.btn [class*=" ficon-"].ficon-spin,
.nav [class*=" ficon-"].ficon-spin {
  display: inline-block;
}
.nav-tabs [class^="ficon-"],
.nav-pills [class^="ficon-"],
.nav-tabs [class*=" ficon-"],
.nav-pills [class*=" ficon-"] {
  /* keeps button heights with and without icons the same */
}
.nav-tabs [class^="ficon-"],
.nav-pills [class^="ficon-"],
.nav-tabs [class*=" ficon-"],
.nav-pills [class*=" ficon-"] {
  line-height: .9em;
}
li [class^="ficon-"],
.nav li [class^="ficon-"],
li [class*=" ficon-"],
.nav li [class*=" ficon-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}
li [class^="ficon-"].ficon-large,
.nav li [class^="ficon-"].ficon-large,
li [class*=" ficon-"].ficon-large,
.nav li [class*=" ficon-"].ficon-large {
  /* increased font size for ficon-large */

  width: 1.5625em;
}
ul.ficons {
  list-style-type: none;
  text-indent: -0.75em;
}
ul.ficons li [class^="ficon-"],
ul.ficons li [class*=" ficon-"] {
  width: .75em;
}
.ficon-muted {
  color: @color-xlight-grey;
}
.ficon-border {
  border: solid 1px @color-xlight-grey;
  padding: .2em .25em .15em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
[class^="ficon-"].pull-left,
[class*=" ficon-"].pull-left {
  margin-right: .3em;
}
[class^="ficon-"].pull-right,
[class*=" ficon-"].pull-right {
  margin-left: .3em;
}
.btn [class^="ficon-"].pull-left.ficon-2x,
.btn [class*=" ficon-"].pull-left.ficon-2x,
.btn [class^="ficon-"].pull-right.ficon-2x,
.btn [class*=" ficon-"].pull-right.ficon-2x {
  margin-top: .18em;
}
.btn [class^="ficon-"].ficon-spin.ficon-large,
.btn [class*=" ficon-"].ficon-spin.ficon-large {
  line-height: .8em;
}
.btn.btn-small [class^="ficon-"].pull-left.ficon-2x,
.btn.btn-small [class*=" ficon-"].pull-left.ficon-2x,
.btn.btn-small [class^="ficon-"].pull-right.ficon-2x,
.btn.btn-small [class*=" ficon-"].pull-right.ficon-2x {
  margin-top: .25em;
}
.btn.btn-large [class^="ficon-"],
.btn.btn-large [class*=" ficon-"] {
  margin-top: 0;
}
.btn.btn-large [class^="ficon-"].pull-left.ficon-2x,
.btn.btn-large [class*=" ficon-"].pull-left.ficon-2x,
.btn.btn-large [class^="ficon-"].pull-right.ficon-2x,
.btn.btn-large [class*=" ficon-"].pull-right.ficon-2x {
  margin-top: .05em;
}
.btn.btn-large [class^="ficon-"].pull-left.ficon-2x,
.btn.btn-large [class*=" ficon-"].pull-left.ficon-2x {
  margin-right: .2em;
}
.btn.btn-large [class^="ficon-"].pull-right.ficon-2x,
.btn.btn-large [class*=" ficon-"].pull-right.ficon-2x {
  margin-left: .2em;
}
.ficon-spin {
  display: inline-block;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
  0% { -moz-transform: rotate(0deg); }
  100% { -moz-transform: rotate(359deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); }
}
@-o-keyframes spin {
  0% { -o-transform: rotate(0deg); }
  100% { -o-transform: rotate(359deg); }
}
@-ms-keyframes spin {
  0% { -ms-transform: rotate(0deg); }
  100% { -ms-transform: rotate(359deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
