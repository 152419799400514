.session-editor-modal {
	@media (max-width: 767px) {
		.modal-dialog {
			margin: 10px auto;
		}
	}

	.goreact-modal-body {
		session-editor {
			flex: 1;
		}
	}

	.goreact-modal-footer {
		.save-for-later-btn {
			margin-right: auto;
		}
	}
}
