@import '~go-styles/src/_/variables';

// Have to manually set this or else the modal backdrop covers nested side panel modals
.library-collection-viewer.modal-backdrop, .activity-editor-panel + .modal-backdrop {
	z-index: 1049 !important;
}

.modal.library-collection-viewer {
	@slideTransition: right 0.4s ease-in-out, left 0.4s ease-in-out;
	// We need to trick angular-animate to add the ng-enter and ng-enter-active classes the child DOM elements will use
	transition: @slideTransition;

	// Have to manually set this or else the collection item viewer menu shows up behind this
	~ .cdk-overlay-container{
		z-index: 1080 !important;
	}

	.goreact-modal-header {
		border-bottom: 1px solid @color-separator;
	}

	.modal-title {
		margin-left: 8px;
		text-transform:  capitalize;
	}

	> .modal-dialog {
		width: 100%;
		height: 100%;
		overflow: hidden;
		box-shadow: 0px 2px 10px 0 rgba(0,0,0,.25);
	}

	.modal-content {
		height: 100%;
		display: flex;
		flex-direction: column;

		right: 0;
		transition: @slideTransition;
	}

	&.slide-out .modal-content {
		right: 100%;
	}

	&.slide-in.ng-enter .modal-content {
		left: 100%;
	}

	&.slide-in.ng-enter-active .modal-content {
		left: 0;
	}

	&.slide-in.ng-leave .modal-content {
		left: 0;
	}

	&.slide-in.ng-leave-active .modal-content {
		left: 100%;
	}

	.goreact-modal-body {
		width: 100%;
		height: 100%;
		padding: 0px;
		min-height: 0;
	}

	// First breakpoint
	@media (min-width: @maxModalWidth) {
		> .modal-dialog {
			width: 820px;
			height: 700px;
		}
	}

	// Smaller mobile break
	@media (min-width: 526px) {
		> .modal-dialog {
			max-height: calc(100% - (2 * @modalTopMargin));
		}
	}
}
