stimulus-video-viewer {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  > media-preview {
	color: white;
	background-color:@color-black;
  }
}
