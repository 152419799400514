/** GENERAL FORMATTING STYLES  */
@import 'variables';
@import 'mixins';

html {
	cursor: auto;
}

body , html, a, a:hover {
  font-family: proxima-nova, Open Sans, Arial, Helvetica, sans-serif;
	color: @color-darker-grey-font;
}

i {
	color: @color-emperor;
}

/** ANGULAR CLOAK */

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none;
}

.vertical-align {
  .vertical-align();
}

.block {
  display: block;
}

a i:hover {
  text-decoration: none;
  cursor: pointer;
}

// Apply this to a span with a tooltip wrapping a button that may be disabled
.tooltip-wrap {
  display: flex;
}

.center {text-align: center;}
.text-align-right {text-align: right;}
.valign {vertical-align: middle;}
.nobr {white-space: nowrap;}


.normal {font-weight: normal;}
.bold {font-weight: bold;}
.italic {font-style: italic;}

.font-9 {font-size: 9px;}
.font-10 {font-size: 10px;}
.font-12 {font-size: 12px;}
.font-13 {font-size: 13px;}
.small-font {font-size: 14px;}
.medium-font {font-size: 16px;}
.large-font {font-size: 18px;}

.padtop4 {padding-top: 4px;}
.padtop6 {padding-top: 6px;}
.padtop8 {padding-top: 8px;}
.padtop12 {padding-top: 12px;}
.padbottom4 {padding-bottom: 4px;}
.padbottom6 {padding-bottom: 6px;}
.padbottom8 {padding-bottom: 8px;}
.padbottom12 {padding-bottom: 12px;}

.centered {
  position: absolute;
  left: 0;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}
.inline-block {
  display: inline-block;
}
.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.absolute10 {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
}

.capitalize {
  text-transform:capitalize;
}
select.capitalize,
select.capitalize option {
  text-transform:capitalize;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.caret-white {
  border-top-color: @color-white;
  border-bottom-color: @color-white;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.placeholder {
  color: @color-darker-grey-font;
}
.rotate-90 {  /* for text rotation */
  -webkit-transform: rotate(-90deg); /* Safari & Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -ms-transform: rotate(-90deg);  /* IE */
}
.rotate-180 {
  -webkit-transform: rotate(180deg); /* Safari & Chrome */
  -moz-transform: rotate(180deg); /* Firefox */
  -ms-transform: rotate(180deg);  /* IE */
}

/** Overlay */

.overlay {

  .centered {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    height: 100px;
  }

  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 170px;
    margin: auto;
    text-align: center;
    max-width: 750px;
  }
}

.rounded-corners {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.rounded-corners4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.rounded-corners6 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.rounded-corners8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.rounded-corners10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.rounded-corners12 {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.rounded-corners-top {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}

.rounded-corners-left {
  -webkit-border-radius: 10px 0px 0px 10px;
  -moz-border-radius: 10px 0px 0px 10px;
  border-radius: 10px 0px 0px 10px;
}

.rounded-corners-right {
  -webkit-border-radius: 0px 10px 10px 0px;
  -moz-border-radius: 0px 10px 10px 0px;
  border-radius: 0px 10px 10px 0px;
}

.rounded-corners-top-left {
  -webkit-border-radius: 10px 0px 0px 0px;
  -moz-border-radius: 10px 0px 0px 0px;
  border-radius: 10px 0px 0px 0px;
}

.rounded-corners-bottom {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

.rounded-corners-bottom-left {
  -webkit-border-radius: 0px 0px 0px 10px;
  -moz-border-radius: 0px 0px 0px 10px;
  border-radius: 0px 0px 0px 10px;
}
.rounded-corners-bottom-right {
  -webkit-border-radius: 0px 0px 10px 0px;
  -moz-border-radius: 0px 0px 10px 0px;
  border-radius: 0px 0px 10px 0px;
}

.rounded-corners-top-left-none {
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
}

/** SHADOWS */

.shadow {
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

.shadow-light {
  -webkit-box-shadow: 0 3px 3px rgba(175,175,175, 0.5), inset 0 -1px 0 rgba(175,175,175, 0.1);
  -moz-box-shadow: 0 3px 3px rgba(175,175,175, 0.5), inset 0 -1px 0 rgba(175,175,175, 0.1);
  box-shadow: 0 3px 3px rgba(175,175,175, 0.5), inset 0 -1px 0 rgba(175,175,175, 0.1);
}

.inner-shadow {
  /*-webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);*/
  /*-moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);*/
  /*box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);*/
}

.shadow-small {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

.shadow-large {
  -webkit-box-shadow: 0 7px 4px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 7px 4px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 4px rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

.shadow-top {
  -moz-box-shadow: 0 -5px 10px -10px #000000;
  -webkit-box-shadow: 0 -5px 10px -10px #000000;
  box-shadow: 0 -5px 10px -10px #000000;
}

/** Circle shadows */

.circle-shadow {
  padding: 1px;
  display: inline-block;
  border-radius: 50px 50px;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 1px 1px @color-light-grey;
  cursor: pointer;
  text-align: center;
}

.circle-shadow:hover {
  box-shadow: inset 0 0 1px 1px @color-med-grey;
}

.circle-shadow-medium {
  width: 30px;
  height: 30px;
  line-height: 28px;
}

/* This parent can be any width and height */
.centered-container {
  text-align: center;

  /* May want to do this if there is risk the container may be narrower than the element inside */
  white-space: nowrap;

  /* The ghost, nudged to maintain perfect centering */
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }

  /* The element to be centered, can also be of any width and height */
  .centered-content {
    display: inline-block;
    vertical-align: middle;
  }
}

/** Input reset */

.input-reset-resetwrap {
  position: relative;
  display: inline-block;
}

.input-reset {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  cursor: pointer;
  margin: 4px 6px 0 0;
}

// Goreact Alert background color
.goreact-alert-default {
  background-color: @color-message-block-bg;
}

// Override alert colors
.alert {
  color: @color-emperor;
  &.alert-info {
    background-color: #ebf7fc;
  }

  &.alert-warning {
    background-color: #fdfae7;
  }

  &.alert-danger {
    background-color: #f9e3e4;
  }
}

.subtext {
	font-size: 14px;
}

// Media Footer Bar
.media-footer-bar {
	display: flex;
	background: @color-secondary-header-background;
	height: 50px;
	padding: 5px 0;
	align-items: center;
	justify-content: center;
	color: @color-white;
}

.zero-header {
	font-size: 0px;
	margin: 0 !important;
}
