@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/token';

presenter-list {
	.presenter-name, .presenter-list {
		display: flex;

		.presenter-label {
			align-self: center;
			font-size: 12px;
			margin-left: 10px;
		}
	}

	.token-list {
		display: grid;
		grid-auto-flow: column;
		gap: 10px;
	}

	.collated-list {
		display: none;

		.presenter-list {
			.presenter-label {
				color: @color-off-gray;
			}
		}

		.popover {
			left: auto !important;
			right: 20px;
			width: 200px;

			.popover-inner {
				overflow: hidden;

				.popover-content {
					padding: 20px;
					width: 210px;
					max-height: 250px;
					overflow-y: scroll;
				}
			}
		}

		.presenter-popover-container {
			display: grid;
			grid-auto-flow: row;
			gap: 15px;
		}
	}

	@media (max-width: @screen-collapse-min) {
		.single-presenter {
			.presenter-label {
				display: none;
			}
		}
		
		.token-list {
			display: none;
		}

		.collated-list {
			display: inline-block;
		}
	}
}