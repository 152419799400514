@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/mixins';

.activity-info {
  @col-width: 220px;
  position: relative;
	display: block;

	.instructions-wrapper {
		display: flex;

		@media (max-width: @screen-sm-min) {
			flex-direction: column;
		  }
	}

	.recording-section, .feedback-section {
		flex: 1;
	}

  .activity-instructions-custom {
		font-size: 12px;
		font-weight: 300;
		color: @color-dark-grey;
		margin-bottom: 0;
  }

  .media {
		margin-top: 0;

	&,
	.media-body {
		overflow: visible;
	}

	a {
	  cursor: pointer;
	  position: relative;
	  float: left;
	}

	.media-right {
	  padding-left: 0;
	}

	.media-body {
	  width: auto;
	}
  }

  .activity-attachments-panel {
	margin-top: 10px;

	panel-body {
		overflow: auto;
		max-height: 100px;
	}

	activity-attachments {
		flex: 1;
	}
  }
}

.activity-information {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	gap: 20px;

	.info-card {
		.wrapper {
			.content-grid {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 20px;

				.btn-none {
					text-decoration: underline;
					padding: 0;
				}
			}

			h5 {
				font-weight: 600;
				margin: 0;
				padding: 15px 0 20px;
			}
			background-color: @color-white;
		}

		go-attachment {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			media-thumbnail {
				width: 100px;
				border-radius: 5px;

				.thumbnail-cover {
					.thumbnail-cover-overlay {
						background-color: rgba(0,0,0,.25);
					}
					.preview-icon-container {
						opacity: 0.85;
					}
				}
			}

			.title {
				color: @color-off-gray;
				font-size: 12px;
				max-width: 100%;
				margin: 10px 0px 0px 0px;
				span {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: pointer;
				}
			}
		}
	}
}

.no-information {
	font-size: 16px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	> .no-information {
		justify-content: center;
		text-align: center;
	}
}
