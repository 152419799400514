@import '~go-styles/src/_/variables';
@import 'rubric-grade-totals-bar/style';

rubric-feedback-viewer {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-top: 1px solid @color-separator;

	// Allow zero state to fill all space
	ngx-zero-state {
		flex: 1;
	}

	uib-dropdown.feedback-filter {
		button.dropdown-toggle {
			min-width: 200px;
		}
	}

	[uib-dropdown-menu][role="listbox"] li {
		padding: 3px 12px;

		span.rubric-feedback-viewer__dropdown-template--points {
			margin-inline-start: auto;
		}
	}

	.rubric-viewer-header-select {
		display: flex;
		column-gap: 10px;
		align-items: center;
	}

	.rubric-feedback-viewer-header {

		.sub-header-title {
			padding-left: 10px;
		}

		button.close-feedback-panel {
			opacity: unset;
			padding: 0 10px;
		}
	}

	.sub-header {
		uib-dropdown {
			button.action-menu-item {
				> span {
					height: 23px;
				}

				.action-menu-icon {
					width: unset;
					font-size: 20px;
					line-height: 22px;
					padding-right: 0px;
				}

				span.action-menu-action {
					margin-right: 0px;
					margin-left: 6px;
					white-space: nowrap;
				}
			}
		}

		.sidebar-toggle {
			display: none;
		}
	}

	@media (max-width: @screen-xs-min) {
		.sub-header {
			.sidebar-toggle {
				height: 40px;
				display: block;

				button {
					border-right: 1px solid @color-separator;
					opacity: unset;
					padding: 0 10px;
					height: 100%;
				}
			}
		}
	}
}

@rubric-feedback-viewer-header-footer-height: 40px;
@rubric-feedback-viewer-header-footer-font-size: 1.5em;
@rubric-feedback-viewer-internal-padding-left-right: 15px;
@rubric-feedback-viewer-internal-padding: 30px @rubric-feedback-viewer-internal-padding-left-right;

// We need to .rich-dropdown modifier to make sure style
// overrides work
.rubric-feedback-viewer__dropdown-template {
	display: flex;
	flex-wrap: wrap;
}
.rubric-feedback-viewer-header .rich-dropdown {
	> button.btn {
		// Hide score in selected template
		.rubric-feedback-viewer__dropdown-template--points,
		.rubric-feedback-viewer__dropdown-template--published {
			display: none;
		}
	}
	.rubric-feedback-viewer__dropdown-template--published {
		margin-right: 20px;
	}
}

li.active > a.rubric-feedback-viewer__dropdown-template .rubric-feedback-viewer__dropdown-template--icon {
	color: white;
}

.rubric-feedback-viewer__dropdown-template--user-name {
	flex: 1;

	// Add some padding to ellipsis in button
	// so its not so jarring
	padding-right: 15px;
	padding-left: 10px;
	min-width: 75px;
}

// Content
.rubric-feedback-viewer-content {
	flex: 1;
	padding: @rubric-feedback-viewer-internal-padding;
	overflow-y: auto;
}

// Footer
.rubric-feedback-viewer-footer {
	border-top: 1px solid @color-separator;
	height: @rubric-feedback-viewer-header-footer-height;
	padding: @rubric-feedback-viewer-internal-padding;
	background: @color-background-light;
	color: @color-dark-background;
	font-size: 16px;
	display: flex;
	align-items: center;

	.rubric-feedback-viewer-footer__buttons {
		margin-left: auto;
		> button {
			margin-left: 15px;
		}
	}
}
