@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/button';

datetimepicker {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	&[disabled] {
		> button {
			pointer-events: none;
			cursor: not-allowed;
		}
	}

	> input {
		border: 1px solid @color-input-outline;
		border-radius: 5px !important;
		padding: 2px 0 0 @default-select-internal-padding-left-right !important;
		width: 100%;
		height: @default-select-height !important;
		display: flex;
		align-items: center;
		position: relative;
		color: @color-darker-grey-font !important;
	}

	> button.date-picker-toggle {
		height: @default-select-height;
		width: @default-select-height;
		font-size: @default-select-font-size;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		z-index: 1;

		.ficon-app-calendar {
			margin-top: 4px;
		}
	}

	> button.date-picker-toggle.hidden {
		display: none;
	}
}

.uib-datepicker {
	.uib-title,
	.btn-default.pull-left,
	.btn-default.pull-right {
		border: none;
	}

	// fix active day state
	.uib-day > button,
	.uib-months > td > button,
	.uib-years > td > button {
		border: none;
		&.active, &.active:hover {
			background-color: @color-emperor;
			border-color: transparent;

			span {
				color: @color-white;
			}
		}

		&:hover {
			background-color: @color-menu-bg-hover;

			span {
				color: @color-dark-grey;
			}
		}
	}

	.uib-weeks td {
		btn.default {
			color: @color-black;
		}

		&.current-date {
			button.btn-default {
				border: 1px solid @color-emperor;
			}

			> button span.text-info {
				font-weight: bold;
				color: @color-darker-grey-font;
			}
		}

		> button.btn-info {
			background-color: transparent;

			&.active {
				background-color: @color-emperor;
			}
		}

		> button span {
			color: @color-black;
		}

		> button span.text-muted {
			color: @color-emperor;
		}

		> button.active span.text-info {
			color: @color-white;
			font-weight: bold;
		}
	}



}

.uib-datepicker-popup {
	.uib-button-bar {
		display: flex;
		justify-content: flex-end;
		padding: 2px 9px 2px;
	}

	// fix button colors
	li > .btn-group {
		display: flex;
		align-items: center;

		> * {
			margin-right: 15px;
		}

		.btn-danger,
		.btn-info {
			.tertiary-btn;
		}
	}

	li > button.uib-close {
		.primary-btn;
	}

	[uib-datepicker] {
		table {
			&:focus {
				outline: transparent;
			}
			button.btn-default {
				border: none;
			}
		}
	}

	table.uib-timepicker {
		margin: 2px auto;

		// Fix AM/PM switcher
		.uib-time > button {
			.secondary-btn;
			border-color: @color-light-grey;
			padding: 7px 16px;
		}

		td {
			padding: 0 3px;
		}

		.btn-link {
			padding: 0 10px;
			margin: 0;

			&:hover {
				background-color: @color-xlight-grey;
			}
		}
	}

	.glyphicon {
		color: @color-emperor;
		font-family: 'fontawesome';
	}
	.glyphicon-chevron-left:before {
		content: "\f053";
	}
	.glyphicon-chevron-right:before {
		content: "\f054";
	}
	.glyphicon-chevron-up:before {
		content: "\f077";
	}
	.glyphicon-chevron-down:before {
		content: "\f078";
	}
}
