@import '~go-styles/src/_/variables';

feedback-session-playback-view {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 0;
	height: 100%;

	media-preview {
		height: inherit;
	}

	.post-discard-bar {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 45px;
		color: white;
	}

	.media-processing-message {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		color: white;

		.processing-icon {
			font-size: 2em;
			margin-right: 10px;
		}

		.action-buttons {
			margin: 20px 0px;
			justify-content: center;

			&.layout-column {
				margin: 20px 0px;
			}
		}

		.still-processing {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;

			.still-processing-text {
				margin: auto 0;
				text-align: center;
			}
		}
	}

	.action-buttons,
	.post-discard-mode,
	.trim-mode {
		display: flex;
		gap: 8px;

		.link-btn {
			color: @color-white;

			&:focus,
			&:hover {
				background-color: @color-off-gray;
			}
		}

		.link-group-buttons {
			.icon-btn-recorder {
				display: flex;
				font-size: 20px;
			}
		}

		.group-buttons {
			display: flex;
			gap: 8px;
		}

		&.layout-column {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 8px 0px;

			.link-group-buttons {
				display: flex;
				gap: 15px;
			}
		}
	}

	.save-draft-btn,
	.deactivate-trim-btn {
		background-color: @color-white;
	}
}
