/** GoReact COLORS */

.red {color: @color-primary !important;}
.red2 {color: @color-red2 !important;}
.green {color: @color-green !important;}
.green2 {color: @color-green2 !important;}
.yellow {color: @color-yellow !important;}
.yellow2 {color: @color-yellow2 !important;}
.yellow-dark {color: @color-yellow-dark !important;}
.secondary {color: var(--color-secondary) !important;}
.secondary-hover {color: var(--color-secondary-hover) !important;}
.xdark2 {color: @color-xdark2 !important;}
.xdark {color: @color-xdark !important;}
.dark {color: @color-dark !important;}
.dark2 {color: @color-dark2 !important;}
.dark-grey {color: @color-dark-grey !important;}
.dark-grey2 {color: @color-dark-grey2 !important;}
.grey {color: @color-grey !important;}
.grey2 {color: @color-grey2 !important;}
.med-grey {color: @color-med-grey !important;}
.med-grey2 {color: @color-med-grey2 !important;}
.light-grey {color: @color-light-grey !important;}
.light-grey2 {color: @color-light-grey2 !important;}
.xlight-grey {color: @color-xlight-grey !important;}
.white {color: @color-white !important;}
.white:hover {color: @color-white !important;}
.transparent {color: transparent !important;}

.background-red {background-color: @color-red !important;}
.background-green {background-color: @color-green !important;}
.background-yellow {background-color: @color-yellow !important;}
.background-secondary {background-color: var(--color-secondary) !important;}
.background-dark {background-color: @color-dark !important;}
.background-dark-grey {background-color: @color-dark-grey !important;}
.background-grey {background-color: @color-grey !important;}
.background-med-grey {background-color: @color-med-grey !important;}
.background-light-grey {background-color: @color-light-grey !important;}
.background-xlight-grey {background-color: @color-xlight-grey !important;}
