md-toast#sync-event-toast {
  padding: 0;
  min-width: 0;

  .md-toast-content {
	width: auto;
	min-height: 40px;
	padding: 0 20px;
  }
}
