@import '~go-styles/src/_/variables';

.rb-points {
	.rb-title {
		font-weight: bold;
	}

	.rb-points-input {
		width: 75px;
		display: inline-block;
		text-align: center;

		input:focus,
		select:focus {
			outline: none;
		}

		&.form-control[readonly] {
			background-color: @color-off-white;
		}
	}

	.rb-points-tally {
		display: inline-block;

		.rb-points-out-of {
			margin: 5px 10px;
		}
	}

	&.rb-edit {
		.rb-points-title {
			margin-bottom: 10px;
		}

		.rb-points-desc {
			float: left;
			padding-top: 2px;
		}

		.rb-points-options {
			margin-top: 10px;

			> label {
				padding-right: 10px;
			}
		}
	}

	&.rb-live {
		.rb-points-title {
			margin-bottom: 10px;
		}

		.rb-points-total {
			font-weight: bold;
			font-size: 15px;
		}

		.rb-points-desc {
			float: left;
			padding-top: 2px;
		}

		.rb-points-options {
			margin-top: 10px;

			> label {
				padding-right: 10px;
			}
		}
	}
}
