.rb-text {

	.text-linebreak {
		margin-top: 25px;
	}

  .rb-title {
    font-weight: bold;
  }

  &.rb-edit {

    .rb-textarea {
			min-height: 80px;
			resize: none;
      border: 1px solid @color-silver;
    }

    .rb-placeholder {
      padding: 8px;
    }
  }

  &.rb-live {
    .rb-textarea {
      min-height: 80px;
      resize: none;
    }
  }
}
