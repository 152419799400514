@import 'stimulus-media-display/style';

stimulus-response-viewer {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

	bg-splitter {
		bg-handle {
			display: block !important;
		}
	}
}
