@import '~go-styles/src/_/variables';

go-media-player {
	display: flex;
	position: relative;
	background-color: black;
	flex-direction: column;

	&.audio {
		background: transparent;
	}

	&.minimized-controls {
		border-radius: 10px;
	}

	.player-container {
		// This is needed since jwplayer css will use absolute positioning to fill its parent
		position: relative;
		display: flex;
		flex: 1;
		justify-content: center;
		// 100% - player controls height
		height: calc(100% - clamp(70px, 2.8125rem, 90px));

		.player-wrapper {
			position: relative;
			display: flex;
			flex: 1;
			justify-content: center;

			&.minimized-controls {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				overflow: hidden;
			}
		}

		.connection-quality-overlay {
			position: absolute;
			right: 10px;
			bottom: 10px;
		}
	}

	.shield {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.opentok-session-adapter-container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		grid-gap: 2px;
		flex: 1;
	}

	.html5-player-adapter-container video {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.hls-player {
		max-width: 100%;
	}
}
