@import 'variables';

.side-panel.modal {

	&.fade.in .modal-dialog {
		transform: translate(0, 0);
	}

	&.fade .modal-dialog {
		transform: translate(100%, 0);
	}

	.modal-dialog {
		height: 100%;
		width: 100%;
		margin: 0;
		display: flex;
		margin-left: auto;
		position: absolute;
		right: 0;
	}

	.modal-content {
		height: inherit;
		width: inherit;
		border-radius: 0;
		border: none;
		display: flex;
	}

	.side-panel-container {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.side-panel-header {
		display: flex;
		flex: 0 0 55px;
		background-color: @color-white;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		color: @color-emperor;

		h4 {
			margin: 0;
			padding: 10px;
			font-weight: bold;
		}

		.close-btn {
			background: transparent;
			border: none;
			font-size: 1.3em;
			margin-right: 6px;
		}
	}

	.side-panel-body {
		display: flex;
		flex: 1 1 auto;
		padding: 10px 20px;
		overflow: auto;
		flex-direction: column;
	}

	.side-panel-footer {
		display: flex;
		flex: 0 0 55px;
		justify-content: flex-end;
		align-items: center;
		background-color: @color-white;
		padding: 10px 15px;

		button {
			margin: 0 5px;
		}
	}
}
