/** EMBEDDED FONTS **/

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(//themes.googleusercontent.com/static/fonts/opensans/v6/k3k702ZOKiLJc3WVjuplzKRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(//themes.googleusercontent.com/static/fonts/opensans/v6/PRmiXeptR36kaC0GEAetxhbnBKKEOwRKgsHDreGcocg.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(//themes.googleusercontent.com/static/fonts/opensans/v6/xjAJXh38I15wypJXxuGMBrrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(//themes.googleusercontent.com/static/fonts/opensans/v6/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(//themes.googleusercontent.com/static/fonts/opensans/v6/EInbV5DfGHOiMmvb1Xr-hqRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(//themes.googleusercontent.com/static/fonts/opensans/v6/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
	font-family: 'proxima-nova';
	src: url('fonts/proximanova-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'proxima-nova';
	src: url('fonts/proximanova-regularit-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'proxima-nova';
	src: url('fonts/proximanova-bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'proxima-nova';
	src: url('fonts/proximanova-boldit-webfont.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: 'proxima-nova';
	src: url('fonts/proximanova-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'proxima-nova';
	src: url('fonts/proximanova-semiboldit-webfont.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}
