@import '~go-styles/src/_/variables';

.activity-instructions-modal {

	.goreact-modal-body {
		overflow: auto;
		margin-bottom: 15px;
	}

	.instructions-item {
		.instructions-item-heading {
			margin-top: 0;
			font-weight: 500;
			text-align: left;
		}

	}

	.instructions-media {
		position: relative;
		cursor: pointer;
		margin-right: 10px;
		margin-bottom: 10px;

		img {
			max-width: 140px;
		}
	}

	.instructions-text {
		img {
			max-width: 100%;
		}
	}

	.recording-instructions-head-phones {
		position: relative;
		color: #555;
		border: 5px solid #555;
		width: 58px;
		height: 61px;
		border-bottom-color: transparent;
		border-radius: ~"36px / 32px 32px 16px 16px";
		margin: 10px 15px 5px;
	}

	.recording-instructions-head-phones::before {
		content: '';
		pointer-events: none;
		position: absolute;
		width: 10px;
		height: 25px;
		left: 0;
		bottom: 0;
		border-radius: 2px;
		box-shadow: inset 0 0 0 32px,38px 0 0 0;
	}

	.recording-instructions-head-phones::after {
		content: '';
		pointer-events: none;
		position: absolute;
		width: 4px;
		height: 12px;
		left: -10px;
		bottom: 6px;
		border-radius: 10px;
		box-shadow: inset 0 0 0 32px,64px 0 0 0;
	}

	.video-preview {
		height: 200px;
		margin-left: auto;
		margin-right: auto;
	}

	.head-phones-notice {
		margin-bottom: 10px;
	}

	.ficon-app-information-circle-o {
		font-weight: bold;
		vertical-align: text-top;
	}

	.left-space {
		margin-left: -2px;
	}

	&.embiggen {
		.modal-dialog {
			width: 880px;
			max-width: 100vw;

			.modal-content {
				display: flex;
				flex-direction: column;

				.goreact-modal-body {
					margin-bottom: 0px;

					.instructions-media {
						margin-right: 0;

						.video-preview {
							height: unset;
							max-height: 65vh;
							aspect-ratio: 16 / 9;
						}
					}
				}

				@media (min-width: @screen-sm-min) {
					max-height: calc(100vh - 6rem);
				}

				@media (max-width: @screen-sm-min) {
					border-radius: unset;
				}
			}
		}
	}
}
