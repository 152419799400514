@import "caption/caption";

comment-captions {
	max-height: 25%;
	overflow-y: auto;
	margin: 0 10px;
	text-align: center;

	&::-webkit-scrollbar {
		width: 12px;
		background-color: rgba(17,17,17,.8);
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		background-color: @color-light-grey;
		border: 3px solid transparent;
		background-clip: content-box;
	}

	&::-webkit-scrollbar-button {
		display:none
	}

	.comment-creator-name {
		&:after {
			content: ':';
			margin-left: 1px;
		}
	}

	.inner,
	.inner strong {
		display: inline-block;
		pointer-events: none;
		padding: 5px 8px;
		background-color: @color-xdark2;
		color: @color-white;
		font-size: 12px;
		opacity: 0.8;
		border-radius: 5px;

		&.noBorderRadiusRight {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}
	}
}
