@import '~go-styles/src/_/variables';

.rich-dropdown {
	&.btn-group.open .dropdown-toggle {
		box-shadow: none;
	}

	&.readonly,
	&[readonly] {
		.dropdown .btn {
			background: none;
			opacity: 1;
			border-color: transparent;
			box-shadow: none;
			cursor: default;

			&:hover {
				background: none;
				border-color: transparent;
			}

			.caret {
				display: none;
			}
		}
	}

	.dropdown-menu {
		max-width: inherit;
		.category {
			padding: 2px 10px;
			margin: 0;
			font-size: 14px;
			font-weight: bold;
		}

		> li[disabled] {
			cursor: not-allowed;
			opacity: 0.5;

			* {
				cursor: not-allowed !important;
			}
		}
	}

	&.dropdown {
		.btn {
			width: inherit;
			text-align: left;
			background-color: transparent;
			white-space: nowrap;

			padding: 5px 10px;
			border-radius: 3px;

			> span {
				max-width: 90%;
				text-overflow: ellipsis;
				overflow-x: hidden;
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
			}

			+ .dropdown-menu {
				width: auto;
				min-width: 100%;
				max-height: 43vh;
				overflow-y: auto;
			}

			&:hover {
				background-color: transparent;
			}

			a {
				text-decoration: none;
				color: #333;
			}
		}

		.caret {
			margin-left: 4px;
			vertical-align: middle;
		}
	}
}

.rd-title-description {
	strong {
		display: block;
		color: unset;
	}
	p {
		font-size: 14px;
		margin: 0;
		color: unset;
		font-weight: normal;
	}
}

// We are already bold, so select needs to make the title bolder
li {
	&:hover, &:focus, &.uib-active-item {
		.rd-title-description strong {
			font-weight: bolder;
		}
	}
}
