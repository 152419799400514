.rb-break {

  &.rb-edit {
    color: @color-light-grey;
    text-align: center;
    font-style: italic;
    border-bottom: none;
    padding: 4px !important;
  }

  &.rb-live {
    display: none;
  }

  &.rb-print {
    display: none;
  }
}
