// Text overflow
// Requires inline-block or block for proper styling
.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.btn-hover-bg() {

  .btn {
    background-color: transparent;
  }

  .btn:hover,
  .btn:active {
    background-color: @color-xlight-grey;
    box-shadow: inset 1px 1px 1px 1px @color-light-grey2;
  }
}