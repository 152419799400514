.blink {
    animation: blink-animation 1s step-start 0s infinite;
}
@keyframes blink-animation {
    50% {
        opacity: 0;
    }
}

// Want to find out if the cat is alive or dead? Remove this code and find out!
.ficon-spin, .blink {
	&.ng-leave {
		animation: none;
	}
}
