@import '~go-styles/src/_/variables';

panel {
	@border-radius-size: 3px;
	display: flex;
	flex-direction: column;
	background-color:@color-white;
	border-radius: @border-radius-size;

	panel-heading {
		display: block;
		background-color: @color-off-white;
		padding: 8px 10px;
		border-top-right-radius: @border-radius-size;
		border-top-left-radius: @border-radius-size;
		border: 1px solid @color-gallery;
		flex: 1;
	}

	panel-body {
		display: block;
		padding: 8px 10px;
		border: 1px solid @color-gallery;
		flex: 1;
	}

	panel-footer {
		display: block;
		padding: 8px 10px;
		border: 1px solid @color-gallery;
		border-bottom-right-radius: @border-radius-size;
		border-bottom-left-radius: @border-radius-size;
		flex: 1;
	}

	ng-transclude[ng-transclude-slot="heading"]:not(:empty) + ng-transclude[ng-transclude-slot="body"]:not(:empty) panel-body {
		border-top: none;
	}

	ng-transclude[ng-transclude-slot="body"]:not(:empty) + ng-transclude[ng-transclude-slot="footer"]:not(:empty) panel-footer {
		border-top: none;
	}

	> ng-transclude {
		display: flex;
		flex-direction: column;
		flex: 1;

		&:empty {
			display: none;
		}
	}
}
