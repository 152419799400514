@import 'variables';

[popover], .popover {
  font-family: proxima-nova, Open Sans, Arial, Helvetica, sans-serif;
}

.popover {

  &,
  .popover-title {
		background-color: @color-dark-grey;

		.popover-content p {
			color: @color-white
		}

  }

  .popover-title {
	font-size: 16px;
	border-bottom: none;
  }

  .popover-inner {
	background: @color-dark-grey;
  }

  &.top > .arrow, &.top > .arrow::after {
	border-top-color: @color-dark-grey;
  }
  &.bottom > .arrow, &.bottom > .arrow::after {
	border-bottom-color: @color-dark-grey;
  }
  &.left > .arrow, &.left > .arrow::after {
	border-left-color: @color-dark-grey;
  }
  &.right > .arrow, &.right > .arrow::after {
	border-right-color: @color-dark-grey;
  }

	.popover-content {
		background-color: @color-dark-grey;

		span p {
			color: @color-white;
		}
	}
}
