@import '~go-styles/src/_/variables';

feedback-session-header {
	display: block;
	height: @header-height;
	color: @color-white;
	background-color: @color-indigo;
	padding: 0 10px;
	font-size: 15px;
	width: 100vw;

	.exit-btn {
		display: flex;
		align-items: flex-end;

		&:focus, &:hover {
			background-color: @color-payne-grey;
		}

		i {
			margin-right: 10px;
		}
	}

	ngx-speed-grader {
		background-color: @color-payne-grey;
	}

	a {
		cursor: pointer;
	}

	.add-slides-btn {
		background: transparent;
		border: none;
		font-size: 16px;

		&:disabled {
			cursor: not-allowed;
		}
	}

	.desc {
		margin-right: 4px;
		vertical-align: middle;
	}

	.video-details-btn {
		color: @color-white;

		&:hover, &:focus {
			color: @color-light-grey;
		}
	}

	.collapse-left.show-mobile {
		display: none;

		.feedback-session-header-item {
			margin-right: 0px;
		}

		.ficon-app-dots-vertical {
			color: @color-white;
			font-size: 1.5em;
		}

		.feedback-session-options-menu {
			background: @color-secondary-header-background;
			min-width: 0;
			flex-direction: column;
			align-items: center;
		}

		@media (max-width: @screen-xs-min) {
			display: flex;
		}
	}

	// attachments info popover
	.attachments-popover-container {
		display: inline-block;

		> .btn {
			background-color: transparent;
			border-color: transparent;
			font-weight: bold;
			font-size: 1.4em;

			.badge {
				position: absolute;
				// use margin instead of top/left for relative absolute positioning
				margin-top: 4px;
				margin-left: -10px;
				padding: 2px 6px;
			}

			&:focus,
			&:active,
			&:hover {
				color: @color-light-grey2;

				.badge {
					background-color: @color-grey2;
				}
			}
		}

		.popover {
			max-width: 400px;

			.attachments-popover-heading {
				color: @color-darker-grey-font;
				font-weight: 300;
			}
		}
	}

	// This needs to be outside context of feedback session
	.popover {
		left: auto!important;
		background-color: @color-off-white;
		max-width: 100%;
		z-index: 1049;
		margin-left: -10px;

		@media (max-width: @screen-sm-min) {
			left: 10px !important;
			right: 10px;
			max-width: 100vw;
		}

		.arrow {
			left: 24px;
		}

		.popover-inner,
		.popover-content {
			max-height: 60vh;
			overflow-y: scroll;
			background-color: @color-off-white;
		}

		&.bottom > .arrow::after {
			border-bottom-color: @color-off-white;
		}
		&.bottom > .arrow {
			border-bottom-color: @color-off-white;
		}
	}

	.feedback-session-header-item.feedback-session-header-item-download {
		white-space: nowrap;
	}

	.feedback-session-header-item {
		margin-right: 10px;

		button i, a i {
			color: @color-white;
		}

		session-attachments button i {
			color: @color-emperor;
		}
	}

	speed-grader.feedback-session-header-item {
		margin-right: unset;
	}

	.feedback-session-header-inner {
		display: flex;
		flex-direction: row;
		height: @header-height;
		color: @color-white;
	}

	.collapse-left, .collapse-right {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.collapse-right-inner-right {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.collapse-left {
		justify-content: flex-start;
		flex-shrink: 2;
		min-width: 20vw;
	}

	.collapse-right, .collapse-right-inner-right {
		flex: 1;
		max-width: 79vw;
		justify-content: flex-end;
	}

	.session-score {
		background-color: @color-indigo;
		margin-right: 10px;
		padding: 9px 11px;
		cursor: pointer;

		.points.label-input {
			display: inline-block;

			.li-label {
				padding: 0 4px;
				background-color: @color-white;
				display: block;
				color: @color-darker-grey-font;

				.placeholder {
					color: @color-white;
				}
			}

			.li-input-container {
				display: inline-block;
			}

			.li-input {
				width: 45px;
				margin: 0;
				padding: 0;
				vertical-align: middle;
				font-size: 14px;
				height: auto;
			}

			&[disabled=disabled] {
				.li-label {
					color: @color-white;
					border: none;
					background-color: inherit;
				}
			}
		}

		.divider {
			margin-right: 5px;
			margin-left: 5px;
		}

		&[disabled] {
			background-color: @color-xlight-grey;

			.li-label {
				background-color: inherit;
			}

			.total-score {
				color: @color-off-gray;
			}
		}
	}

	// Start hiding stuff at the following breakpoints

	//Hide 'download' text (button still visible) and video details button
	@media (max-width: @screen-sm-min) {
		.video-details-btn {
			display: none;
		}
	}

	//Hide Presenter Slides and Attachments
	@media (max-width: @screen-xs-min) {
		.hide-mobile {
			display: none;
		}
	}
}
