@import '~go-styles/src/_/variables';
@import 'audio-description/style';

media-preview {
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  display: block;

	&.dark-mode {
		background: black;
	}

	&.audio {
		background: transparent;
	}

	.media-preview {
		text-align: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
	}

	.media-processing-message {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		color: white;

		> .processing-icon {
			font-size: 2em;
			margin-right: 10px;
		}
	}

	.media-processing-message-failed {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		color: @color-red;
	}

	.invisible {
		opacity: 0;
		height:0;
		width: 0;
		display: block;
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}

	@modal-padding: 0px;
	.center-vertically {
		position: absolute;
		top: @modal-padding;
		bottom: @modal-padding;
		left: @modal-padding;
		right: @modal-padding;
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			font-size: 0;
		}
		> * {
			display: inline-block;
			vertical-align: middle;
		}

		go-pdf-page canvas {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.no-preview .center-vertically {
		left: @modal-padding - 15;
		right: @modal-padding - 15;
		line-height: 0;
	}

	.no-preview {
		text-align: center;
		color: @color-grey2;
		font-size: 18px;
		flex-grow: 1;
		position: relative;
	}

	.jwplayer {
		position: absolute !important;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		object {
			width: 100%;
			height: 100%;
		}
	}

	.preview-container {
		flex-grow: 1;
		position: relative;
		display: flex;
		height: 100%;
		.video-player-container {
			flex: auto;
			min-width: 0;
			.video-preview {
				height: 100%;
			}
		}
	}
}
