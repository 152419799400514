@import 'variables';

.help-icon,
.help-icon a,
.help-icon:hover {
  font-size: 16px;
  text-decoration: none !important;
  display: inline-block;
  vertical-align: middle;
  padding-left: 1px;
  padding-right: 0px;
  cursor: pointer;

  [class*='ficon'] {
    font-size: 16px;
  }
}

.ficon-help {
  text-decoration: none;
  vertical-align: middle;
  font-size: 16px;

  &:hover,
  &:focus,
  &:active {
    color: @color-emperor;
    text-decoration: none;
  }
}
