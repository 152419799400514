/** BOOTSTRAP EXTENSIONS */
@import 'mixins';
@import 'variables';

.btn-arrow-right {
	padding: 8px 12px 8px 5px;

	&:after {
		transform: rotate(225deg);
	}
}

a {
	text-decoration: none;
}

.btn-arrow-left {
	padding: 8px 5px 8px 12px;

	&:after {
		transform: rotate(45deg);
	}
}

.btn-arrow-left,
.btn-arrow-right {
	background-color: rgba(100,100,100,.7);

	&:hover {
		background-color: rgba(100,100,100,.9);
	}

	&:after {
		content: '';
		border-color: @color-white;
		border-style: solid;
		border-width: 0 0 2px 2px;
		width: 14px;
		height: 14px;
		font-size: 0;
		display: block;
	}
}

.btn-transparent {
	.button-transparent-variant(@color-dark-grey; rgba(255, 255, 255, 0.5); @color-med-grey2);
}

// New Button font sizes
@btn-font-size: 14px;
@badge-font-size: @btn-font-size - 4;

.btn() {
	padding: 5px 16px;
	text-align: center;
	border-width: 1px;
	border-style: solid;
	border-radius: 38px;
	color: @color-white;
	min-height: 31px;
	line-height: normal;
	font-weight: bold;
	text-transform: uppercase;
	font-size: @btn-font-size;
	cursor: pointer;
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,.25);
}

.disabled () {
	cursor: not-allowed;
}

.btn-color(@color) {
	background-color: @color;
	border-color: @color;
}

// Styles for badges inside buttons
.primary-btn,
.secondary-btn {
	& > .badge {
		background-color: transparent;
		border-style: solid;
		border-width: 1px;
		padding: 2px 5px;
		margin-left: 2px;
		font-size: @badge-font-size;
		border-radius: 7px;
	}
}

.secondary-btn > .badge {
	color: @color-white;
	border-color: transparent;
	background-color: @color-grey2;
}

// Small variants for buttons
.primary-btn.small,
.secondary-btn.small {
	font-size: @btn-font-size - 2;
	font-weight: 700;
	padding: 2px 10px;
	height: 28px;
}

// Primary Use button
.primary-btn {
	.btn; // Add base button styles
	.btn-color(var(--color-primary));
	letter-spacing: 1.4px;

	&:focus,
	&:hover {
		.btn-color(var(--color-primary-hover));
		color: @color-white; // Needed to fight back against marketing site styles and <a> tag usage
		text-decoration: none;
	}

	&:disabled {
		.disabled;
		.btn-color(@color-disabled-bg);
	}
}

// Secondary Use button
.secondary-btn {
	.btn; // Add base button styles
	letter-spacing: 1.4px;
	background-color: @color-secondary-bg;
	border-color: @color-secondary-bg;
	color: @color-darker-grey-font;

	&:focus,
	&:hover {
		background-color: @color-xlight-grey2;
		text-decoration: none;
	}

	&:disabled {
		.disabled;
		border-color: @color-disabled-bg;
		color: @color-disabled-bg;
	}

	// When the button is on a darker background, apply this class
	&.light {
		border-color: white;
		color: @color-white;

		i {
			color: @color-white;
		}

		&:focus,
		&:hover {
			background-color: @color-secondary-bg;
			border-color: @color-disabled-bg;
			color: @color-disabled-bg;
			text-decoration: none;
		}

		&:disabled {
			border-color: @color-secondary-light-disabled;
			color: @color-secondary-light-disabled;
		}
	}
}

// Link button
.tertiary-btn {
	.btn;
	min-height: initial;
	height: inherit;
	color: @color-darker-grey-font;
	text-decoration: none;
	border: none;
	border-radius: 5px;
	padding: 6px 8px;
	background: transparent;
	box-shadow: none;
	text-transform: none;

	&:focus,
	&:hover {
		color: @color-darker-grey-font; // To fight marketing site hover styles
		background-color: @color-xlight-grey2;
		text-decoration: none;
	}

	&:disabled {
		.disabled;
		color: @color-disabled-bg;
	}

	&.light {
		color: @color-white;

		&:focus,
		&:hover {
			background-color: @color-off-gray;
			color: @color-white; // To fight marketing site hover styles
		}
	}

	&.medium {
		color: @color-font-medium;

		&:hover {
			color: @color-font-medium-hover;
		}
	}

	&.cancel-btn {
		padding: 5px 16px;
	}
}

.icon-btn-recorder {
	min-height: initial;
	height: inherit;
	text-decoration: none;
	border: none;
	padding: 0;
	background: transparent;
	&[disabled] * {
		cursor: not-allowed;
		opacity: .6;
	}

	.icon {
		font-size: 33px;
	}
}

.icon-btn {
	border: none;
	background: transparent;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	&:focus,
	&:hover {
		background-color: @color-xlight-grey2;
	}
}

.icon-btn-large {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 156px;
	height: 156px;
	text-transform: uppercase;
	border: none;
	color: white;
	font-size: 14px;
	font-weight: bold;
	border-radius: 15px;

	i {
		color: white;
	}
}

.link-btn {
	.btn;
	min-height: initial;
	height: inherit;
	color: @color-darker-grey-font;
	font-weight: normal;
	border: none;
	border-radius: 5px;
	padding: 6px 8px;
	background: transparent;
	box-shadow: none;
	text-decoration: underline;
	text-underline-offset: 3px;
	text-transform: none;

	&:focus,
	&:hover {
		color: @color-darker-grey-font;
		background-color: @color-xlight-grey2;
	}

	&:disabled {
		.disabled;
		color: @color-disabled-bg;
	}

	&.light {
		color: @color-white;

		&:focus,
		&:hover {
			background-color: @color-off-gray;
			color: @color-white; // To fight marketing site hover styles
			text-decoration: underline;
		}
	}

	&.medium {
		color: @color-font-medium;

		&:hover {
			color: @color-font-medium-hover;
		}
	}

	&.add-btn {
		position: relative;
		padding-left: 20px;
		overflow: visible;

		&::before {
			content: '\e995';
			font-size: 1.2em;
			font-family: 'goreact-custom2';
			position: absolute;
			height: 100%;
			width: 34px;
			top: 0;
			left: -7px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

// Block button
.block-btn {
	height: 35px;
	min-width: 100%;
}

// This fixes tooltips and popovers that directly surround disabled buttons
@media (any-hover: hover) {
	[uib-popover],
	[uib-tooltip],
	go-popover {
		.block-btn,
		.tertiary-btn,
		.secondary-btn,
		.primary-btn {
			&[disabled] {
				pointer-events: none;
			}
		}
	}
}

// Global focus outline for bootstrap buttons
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,

.record-btn-indicator {
	color: @color-red;
}
