@import '~go-styles/src/_/variables';

@scrubber-height: 3px;
@controls-volume-slider-background: #8F8F8F;
@controls-volume-slider-progress: var(--color-secondary);
@color-volume-muted: #8F8F8F;

player-controls-settings {
	display: flex;
	flex-direction: column;
	background-color: @color-secondary-header-background;
	padding: 25px;
	font-size: clamp(14px, .875rem, 28px);
	color: #E5E5E5;
	border-radius: 5px;

	.settings-section + .settings-section {
		margin-top: 25px;
	}

	.settings-section + .settings-section[aria-hidden="true"] {
		+ .settings-section {
			margin-top: 25px;
		}
	}

	.settings-section[aria-hidden="true"] {
		+ .settings-section {
			margin-top: 0px;
		}
	}

	.settings-section {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> :nth-child(1) + :nth-child(2) {
			margin-left: 30px;
		}
	}

	.volume-container {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.no-ui-volume-slider {
		// Margin to the right to give space for the toggle mute button
		margin-right: 15px;
		// Grow the width to entire container
		flex: 1;
		// Shrink the bar's thickness
		height: @scrubber-height;
		// Remove vendor grey border
		border: none;
		// We don't want vendor inset 3d progress bar
		box-shadow: none;

		// Set the color of the non-progressed (right hand) region
		background-color: @controls-volume-slider-background;
		// Set the color of the progressed (left hand) region
		.noUi-connect {
			background-color: @controls-volume-slider-progress;
		}

		// Unless we are muted, then go dark
		&.volume-muted {
			&, .noUi-connect, .noUi-handle {
				background-color: @color-volume-muted;
			}
			.noUi-handle {
				border: none;
				box-shadow: none;
			}
		}

		// We want a pointer so people can see you can jump to a location
		&:not([disabled]) {
			cursor: pointer;
		}

		// Let's style the handle
		.noUi-handle {

			// Normally the handles are retangular (34px x 28px = 17/14 = 1.214 ratio)
			// But we want a circular handle (1/1 = 1.0 ratio), so we'll need the inverse ratio
			@ratio: (34/28);
			border-radius: 50%;

			// And we've also changed the scrubber height from the default 18px
			// But we also want the handle to be relatively bigger, so * 3 magic number
			@scale: (@scrubber-height/18px) * 3;

			// So first scale the width to the fraction of the scrubber height
			width: @scale * 34px;
			// And right (normally -17px with a 1px border)
			right: @scale * -16px;
			// Now scale the height, replacing their ratio with our ratio
			@height: @scale * @ratio * 28px;
			height: @height;
			// Top is half of the part of the handle that sticks out from the scrubber
			top: -(@height - @scrubber-height) / 2;

			// The handle normally has two notches (vertical bars) we need to hide
			&:before, &:after {
				display: none;
			}

			// Make the cursor go to the grab/grabbing state when clicked and dragged
			cursor: grab;
			&:active {
				cursor: grabbing;
			}
			&:before {
				display: none !important;
			}
		}

		// But we don't want to show grabbing if we are disabled
		&[disabled] .noUi-handle {
			cursor: not-allowed;
		}
	}
}
