@import '~go-styles/src/_/variables';
@import "go-doc-viewer/go-doc-viewer";
@import "go-pdf-viewer/go-pdf-viewer";

go-pdf-page {
	position: relative;
	height: 100%;
	width: 100%;
	overflow:hidden;

	.loading-indicator {
	  font-size: 4em;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}

	&.loading {
		canvas {
			border: none!important;
		}
	}

	&.scaled {
		&.grab-to-pan-grab {
			cursor: -webkit-grab !important;
			cursor: -moz-grab !important;
			cursor: grab !important;
		}
		&.grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link){
			cursor: inherit !important;
		}
		&.grab-to-pan-grab:active {
			cursor: -webkit-grabbing !important;
			cursor: -moz-grabbing !important;
			cursor: grabbing !important;
		}

		cursor: grab;
		&:active {
			cursor: grabbing;
		}

		canvas {
			max-width: none;
			max-height: none;
			transform: none!important;
			top: 0!important;
		}
	}

	&.scaled:not(.mobile) {
		overflow: hidden;
		position: relative;

		canvas {
			bottom: 0;
			left: 0;
			right: -10px;
			overflow: auto;
		}
	}

	&.scaled.mobile {
		overflow: auto;
	}

	display: block;
	text-align: center;

	canvas {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
		max-height: 100%;
	}

	&:before {
		position: absolute;
		top: 50%;
		bottom: 50%;
		left: 0;
		right: 0;
		line-height: 0;

		animation: spin 4s infinite linear;

		@keyframes spin {
			from {
				transform: rotate(0deg);
			} to {
				transform: rotate(360deg);
			}
		}
	}
}

.grab-to-pan-grabbing {
	cursor: -webkit-grabbing !important;
	cursor: -moz-grabbing !important;
	cursor: grabbing !important;
}
