go-attachments {
	position: relative;
	display: flex;
	flex-direction: row;
	align-content: center;

	.hide-add-btn {
		.add-btn {
			display: none;
		}
		.attachments-list {
			margin-bottom: 0;
		}
	}

	.attachments-list-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1;
		width: 100%;
	}

	.attachments-list {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0px;
		margin: 0;
		flex: 1;
		width: 100%;
		margin-bottom: 10px;

		&:empty {
			margin-bottom: 0;
		}
	}

	.attachment-list-item {
		display: flex;
		flex: 1;
		margin-bottom: 5px;
		min-height: min-content;

		&:last-child {
			margin-bottom: 0;
		}
	}

	go-attachment {
		flex: 1;
		width: 100%;
	}

	.loading-indicator {
		color: @color-dark;

		.ficon-spinner {
			font-size: 18px;
		}
	}
}
