@import 'slide-deck-viewer/style';

slide-presentation-viewer {
  @header-height: 44px;
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  > header {
	display: flex;
	align-items: center;
	background-color: rgba(34,34,34,.75);
	color: @color-white;
	font-size: 14px;
	padding: 8px;

	> .title {
	  display: flex;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	  flex: 1;

	  > .slide-deck-icon {
		position: relative;
		margin-right: 5px;
		font-size: 14px;
	  }
	}
  }

  > section {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&.header-visible {
	  top: @header-height;
	}
  }

	.message-margin {
		margin-bottom: 10px;
	}

	button.outlined-button {
	margin-left: 0;
	border: 1px solid grey;
	text-transform: none;
	padding: 0 3em;
	font-weight: normal;
	}

	.action-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		min-height: 25px;
	}

	.delete-slide-deck-btn {
		display: flex;

		&,
		&:hover {
			color: @color-white;
		}

		.ficon-app-delete {
			color: @color-white;
			margin-right: 5px;
			font-size: 14px;
		}
	}

	.delete-slide-deck-btn-xs {
		display: none;
		padding-bottom: 0;
	}

	.edit-sync-events-btn {
		margin-left: 10px;
		color: @color-white;
	}

	bg-splitter {
		bg-handle {
			display: block !important;
		}
	}

	@media (max-width: @screen-xs-min) {
		> header {
			> .title {
				display: none !important;
			}

			.action-bar {
				flex: 1;
    			justify-content: space-between;

				.delete-slide-deck-btn {
					display: none;
				}

				.delete-slide-deck-btn-xs {
					display: flex;
				}

				.edit-sync-events-btn {
					display: flex;
				}

				&.edit-off {
					justify-content: flex-end;
				}
			}
		}

		slide-deck-instructions {
			min-width: unset;
		}
	}
}
