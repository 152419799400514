
/** LABELS */

.label-grey {
  background-color: @color-dark-grey;
}
.label-grey:hover {
  background-color: @color-dark2;
}
.label-secondary {
  background-color: var(--color-secondary);
}
.label-green {
  background-color: @color-green;
}
.label-red {
  background-color: @color-red2;
}

.label.disabled {
  color: @color-light-grey;
  cursor: not-allowed;
}
