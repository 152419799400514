@import '~go-styles/src/_/variables';

.modal.session-timeout-modal {
	z-index: 9999 !important;

	.form-group {
		margin-bottom: 15px;
	}

	.sign-in-btn {
		width: 100%;
	}

	.login-options-wrapper {
		display: flex;
		flex-direction: row;
		gap: 20px;
		margin-top: 25px;
	}
}

// Ensure sso-button menu appears over the modal
.modal.session-timeout-modal ~ .cdk-overlay-container:has(.mat-menu-panel) {
	z-index: 10000 !important;
}
