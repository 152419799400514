@import '~go-styles/src/_/variables';

@-webkit-keyframes pulsate {
	0% {
		-webkit-transform: scale(1, 1);
		opacity: .0;
	}

	50% {
		opacity: 1.0;
	}

	100% {
		-webkit-transform: scale(1, 1);
		opacity: .0;
	}
}

feedback-tree {
	display: block;
	width: 100%;
	height: 100%;
}

feedback-tree .feedback-tree {
	width: inherit;
	height: inherit;
	margin: 0;

	.btn-xs {
		font-size: 1em;
	}

	li {
		&.end-note {
			padding-top: 7px;

			&.viewed {
				background-color: @color-list-item-hover;
			}

			&::before {
				content : "";
				position: absolute;
				left: 0;
				top: 0;
				margin: 0 36px;
				height: 1px;
				width: calc(100% - 72px);
				border-bottom: 1px solid @color-light-grey2;
			}

			~.end-note {
				padding-top: 0px;

				&::before {
					content: none;
				}
			}
		}

		&:not(:first-of-type) {
			position: relative;
		}

		&[disabled] {
			cursor: not-allowed;
			pointer-events: visible;
		}
	}

	.viewer-btn, .reply-btn {
		background: transparent;
	}

	.ficon-preview-filled {
		font-size: 1.3em;
	}

	/** Ellipsis **/

	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/** Pulsate animation */

	.pulsate {
		opacity: 0;
		animation: pulsate 1.5s ease-out;
		animation-iteration-count: 3;
		-webkit-animation: pulsate 1.5s ease-out;
		-webkit-animation-iteration-count: 3;
	}

	+ .feedback-tree-message {
		text-align: right;

		.message-content {
			display: inline-block;
			margin: 3px 10px;
			padding: 6px 10px;
			background-color: @color-black;
			color: @color-white;
			opacity: .6;
			border-radius: 10px;
			pointer-events: none;
			box-shadow: 0 2px 6px 0 @color-dark;
		}
	}

	/** Don't show root element */
	&.root {
		> li.list-item {
			display: none;
		}
	}

	p {
		margin: 0;
	}

	.visible-when-active {
		visibility: hidden;
	}

	.list-item.active, .list-item:hover, .feedback-item:focus-within {
		.visible-when-active {
			visibility: visible;
		}
	}

	.ng1-marker-pill {
		@tag-size: 30px;
		display: flex;
		align-items: center;
		gap: 6px;
		padding: 1px 10px 1px 1px;
		border: 1px solid @color-xlight-grey2;
		background-color: @color-white;
		border-radius: 20px;
		font-size: 12px;
		color: @color-black;

		.ng1-marker-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: @tag-size;
			height: @tag-size;
			min-width: @tag-size;
			min-height: @tag-size;
			font-weight: bold;
			border-radius: 100%;
			line-height: 2px;
			font-size: 12px;
			border: 2px solid transparent;
			box-shadow: 0 .5px 2px rgb(0 0 0 / 0.2);
			user-select: none;
			> span {
				transform: translateY(0.5px);
			}
		}
	}

	@import 'feedback-item/style';
}

// Inverse theme
feedback-tree {
	&.inverse {
		.list-item {
			.highlight {
				background-color: lighten(@color-dark, 20%);

				.feedback-item {
					&.viewed {
						.feedback-item-name, .feedback-text, .new-timecode-badge {
							color: @color-darker-grey-font;

							&.editable:hover {
								color: @color-white;
							}
						}
					}
				}
			}

			.feedback-item {
				&.viewed {
					background-color: @color-dark;

					.feedback-item-name {
						color: @color-white;
					}
				}
			}
		}

		.list-item:hover, .list-item.active {
			.feedback-item {
				.feedback-item-name, .feedback-text, .new-timecode-badge {
					color: @color-darker-grey-font;

					&.editable:hover {
						color: @color-white;
					}
				}

				.ficon-app-edit, .slide-deck-icon {
					color: @color-emperor;
				}
			}
		}

		.feedback-item {

			.feedback-item-content {
				color: @color-white;
			}

			.cancel-btn,
			.edit-btn,
			.remove-btn:not(.primary-btn)  {
				background-color: transparent;
			}
		}
	}
}
