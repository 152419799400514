toolbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;

	ng-transclude:empty {
		display: none;
	}
}
