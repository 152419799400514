@import '~go-styles/src/_/variables';

.go-tour-backdrop-shadow {
	position: absolute;
	z-index: 9998;
	background: rgba(0,0,0,0.4);

	&.go-tour-element-cover {
		opacity: 0;
	}
}

.custom-sort-tour {
	min-height: auto;
	border-radius: 8px;
	.content {
		padding: 5px 25px 25px 25px;
		tour-step-body {
			padding: 0;
		}
	}
	#tour-step-title {
		padding: 20px 10px 5px 25px;
	}
}

:root {
	--tour-arrow-position: 50%;
}

tour-step {
	@border-radius: 12px;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: @border-radius;
	min-height: 120px;
	width: 350px;

	// Start offscreen until placed
	// in correct position
	position: absolute;
	left: -9999px;
	z-index: 9999;

	// Must keep in sync with value in placement-engine.factory
	@arrow-size: 13px;

	// Styles for arrow
	&.top:after {
		bottom: 100%;
		left: var(--tour-arrow-position);
		border-bottom-color: white;
		margin-left: @arrow-size * -1;
	}

	&.right:after {
		left: 100%;
		top: var(--tour-arrow-position);
		border-left-color: white;
		margin-top: @arrow-size * -1;
	}

	&.bottom:after {
		top: 100%;
		left: var(--tour-arrow-position);
		border-top-color: white;
		margin-left: @arrow-size * -1;
	}

	&.left:after {
		right: 100%;
		top: var(--tour-arrow-position);
		border-right-color: white;
		margin-top: @arrow-size * -1;
	}

	&:after {
		display: var(--tour-arrow-display);
		content: ' ';
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: transparent;
		border-width: @arrow-size;
	}

	header {
		display: flex;
		justify-content: space-between;
		padding: 15px 15px 0px 15px;
		min-height: 35px;
		align-items: center;
		border-top-left-radius: @border-radius;
		border-top-right-radius: @border-radius;

		.title {
			font-weight: bold;
		}

		.left-header-container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;

			.counter {
				color: @color-off-gray;
				font-size: 0.9em;
			}

			.close-btn {
				padding: 0px;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		padding: 0 15px 15px 15px;
	}

	.tour-step-footer-container, .tour-step-body-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.tour-step-footer-slot {
		width: 100%;
	}

	tour-step-body {
		padding: 15px 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1;
	}

	tour-step-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 15px;
	}

	.inline-text {
		display: inline-block;
	}

	.underline {
		text-decoration: underline;
	}
}
