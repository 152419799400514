accessibility-attachments {
	.accessibility-section {
		margin-top: 8px;
	}

	panel-heading {
		display: flex;
		justify-content: space-between;
	}

	panel-body {
		display: flex;
		align-items: center;

		media-thumbnail {
			width: 45px;
			min-width: 45px;
		}
	}
}
