@import '~go-styles/src/_/variables';

course-payment-wall {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	color: @color-grey2;

	p {
		font-size: 24px;
		line-height: normal;
		margin: 12px;

		&.pending-transaction {
			font-size: 14px !important;
		}
	}

	.course-name {
		font-size: 32px;
		font-weight: 300;
	}

	.bottom-links {
		display: block;
		color: @color-grey;
		margin-top: 125px;
		margin-left: auto;
		margin-right: auto;

		button {
			margin-bottom: 20px;
		}
	}

	.zero-state-hmmm-image {
		margin: 10px;
		height: 180px;
	}

	section {
		max-width: 550px;
	}

	section.no-credits-section {
		max-width: 500px;
	}

	.pay-btn {
		margin: 8px 20px;
	}

	zero-state.full-screen zero-state-body {
		width: 95%;
		max-width: 450px;
	}

	.button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
