@import '~go-styles/src/_/variables';

@or-size: 4.6875rem;

.invite-users.modal.goreact-modal {
	// Have to manually set this or else the ngx confirm dialog shows up behind this
	// as well as the tooltip on the copy to clipboard button
	~ .cdk-overlay-container{
		z-index: 1060 !important;
	}

	.modal-dialog {
		max-width: 500px;

		@media (max-width: 500px) {
			width: 100%;
		}
	}

	label {
		font-weight: 400;
	}

	.common-margin {
		margin-bottom: 24px;
	}

	.link-generator-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.link-button {
			margin-bottom: 8px;
			text-decoration: underline;
			text-underline-offset: 6px;
			text-decoration-color: #333333;
		}
	}

	.close-btn {
		box-shadow: none;
		font-weight: bold;
		background-color: transparent;
		border: none;
		box-shadow: none;
	}

	.users-count {
		display: flex;
		align-items: center;
		margin-right: 12px;

		i {
			display: flex;
			font-size: 24px
		}
	}

	.or {
		position: relative;
		text-align: center;
		margin-top: 32px;

		span {
			position: inherit;
			z-index: 1;
			font-weight: bold;
			font-size: 18px;
			background-color: @color-white;
			padding-left: 24px;
			padding-right: 24px;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			width: 100%;
			height: 1px;
			border-bottom: 1px solid @color-light-grey2;

		}
	}

	.goreact-modal-footer {
		justify-content: space-between;
	}

	.invite-content {
		h2.invite-link-title small {
			display: block;
			margin-top: 10px;
			color: @color-darker-grey-font; // override bootstrap color
		}
	}

	.input-group.invite {
		display: block;
		textarea, a {
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
		}
	}

	.role-dropdown-container {
		margin-top: -29px;
	}

	textarea {
		resize: vertical;
		min-height: 100px;
	}

	.break-word {
		word-break: break-word;
	}

	.invite-link-group {
		display: grid;
		grid-template-columns: 1fr auto;
		border: 1px solid @color-light-grey3;
		border-radius: 3px;
		overflow: hidden;
		height: clamp(35px, 2.1875rem, 70px);

		input {
			background-color: @color-light-grey2;
			padding: 0 5px;
			border: none;
			border-right: 1px solid @color-light-grey3;
		}

		button {
			padding: 0 12px;
		}
	}

	@media screen and (max-width: 500px) {
		.users-count-wrapper {
			display: flex;
			justify-content: flex-start;
			width: 100%;
		}

		.action-buttons {
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
	}
}
