.captions {
	// Style
	color: white;
	background-color: rgba(0, 0, 0, 0.75);
	font-size: 15pt;
	user-select: none;
	line-height: 1.6em;
	font-family: proxima-nova, 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	text-align: left;
	padding: 0 0.5em;

	// Position to center above controls
	width: 90%;
	max-width: 600px;
	position: absolute;
	bottom: calc(
		/*player control's bottom*/ 2% +
		/*player control's height*/ 45px +
		/*margin above controls*/ 5px
	);
	left: 0;
	right: 0;
	margin: 0 auto;

	// Only show the last bit of text
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	max-height: 3.2em;
}
