@import 'variables';
@import 'mixins';

[uib-dropdown-menu] {
	li {
		cursor: pointer;
		&[disabled], [disabled] {
			text-decoration: none;
			cursor: not-allowed;
			pointer-events: none;
			opacity: .65;
		}
	}

	&[role="listbox"] li,
	&[role="combobox"] li,
	&[role="menu"] li button {
		display: block;
		position: relative;
		clear: both;
		white-space: normal;
		padding: 3px 20px;
		font-weight: normal;
		line-height: 20/14;
		color: @color-darker-grey-font;
	}

	&[role="listbox"] li,
	&[role="combobox"] li {
		&[aria-selected="true"] {
			&, &:focus, &:hover, &.uib-active-item {
				&::before {
					content: '';
					width: 8px;
					left: 0px;
					top: 0px;
					height: 100%;
					position: absolute;
					background: @color-tertiary;
				}
			}
		}

		&:hover, &:focus, &.uib-active-item {
			background-color: @color-xlight-grey2;
		}
	}

	&[role="menu"] {
		// When we are mouse hovering, then the keyboard option should not have the focus outline
		&:hover :focus {
			outline: none !important;
		}

		li button:hover {
			background-color: @color-xlight-grey2;
		}

		&:not(:hover) li button {
			&:focus, &.uib-active-item {
				background-color: @color-xlight-grey2;
			}
		}
	}

	// Some additional resets for button
	&[role="menu"] li button {
		background: none;
		border: none;
		width: 100%;
		text-align: left;
	}
}

uib-dropdown {
	&.btn-group.open {
		.dropdown-toggle {
			box-shadow: none;
		}
	}
}

.default-select.rich-dropdown {
  flex: 1;
  height: @default-select-height;
  min-width: 170px;
  width: 100%;
  background: @color-white;

  > button.btn {
      border-radius: 5px;
      border-color: @color-input-outline;
      padding: 3px 0 0 @default-select-internal-padding-left-right;
      width: 100%;
      height: @default-select-height;
      display: flex;
      align-items: center;
      position: relative;
      color: @color-darker-grey-font;

      [rich-dropdown-selection] {
          flex: 1;
      }

      & + .dropdown-menu {
          width: 100%;
      }

      &:after {
          font-family: 'goreact-custom2';
          content: '\e930';
          height: calc(@default-select-height - 3);
          width: @default-select-height;
          font-size: @default-select-font-size;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          color: @color-emperor;
      }

      > .caret {
          display: none;
      }
  }

  .dropdown-menu > li {
      > a {
          white-space: normal;
      }

      &.active > a {
          color: @color-white;
      }
  }

  &.dropdown .btn a {
     color: @color-darker-grey-font;
  }
}

// Feedback Filter
uib-dropdown.feedback-filter.rich-dropdown {
	flex: 1;
	height: 40px;
	min-width: 0;

	> button.btn {
		border: none;
		border-radius: 0;
		padding: 0 0 0 15px;
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		position: relative;
		a {
			color: @color-dark-background;
			i {
				padding-right: 10px;
			}
		}

		[rich-dropdown-selection] {
			flex: 1;
		}

		& + .dropdown-menu {
			width: 100%;
		}

		&:after {
			font-family: 'goreact-custom2';
			content: '\e930';
			height: 40px;
			width: 40px;
			font-size: 1.5em;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 0;
			color: @color-emperor;
		}

		> .caret {
			display: none;
		}

		.comment-dropdown-category {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			> .badge {
				margin: 5px;
				color: white;
				background-color: @color-emperor;
			}
		}
	}

	li > a {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 1.6;
		padding-left: 12px;
		align-items: center;
		color: @color-dark-background;
		i {
			margin-right: 5px;
			color: gray;
		}
	}
}

/* Action Menu */
uib-dropdown.action-menu {
	font-size: 1.5em;

	> button {
		height: 40px;
		padding: 0 10px;
	}

	.action-menu-item > span {
		display: flex;
		align-items: center;
	}

	.action-menu-icon {
		padding-right: 30px;
	}
}

.go-select-group {
	display: flex;
	position: relative;

	select.go-select {
		appearance: none;
		box-shadow: none;
		height: 40px;
		outline: none;
		border: 1px solid @color-input-outline;
		border-radius: 5px !important;
		padding: 3px 32px 0 9px;
		color: @color-darker-grey-font;
		text-overflow: ellipsis;
	}

	&:focus-visible {
		outline: 1.5px solid @color-darker-grey-font !important;
	}

	&:after {
		content: '\E930';
		z-index: 4;
		font-size: 1.5em;
		font-family: 'goreact-custom2';
		position: absolute;
		height: 98%;
		width: 40px;
		top: 2%;
		right: 1px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		color: @color-emperor;
	}
}
