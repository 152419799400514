@import '~go-styles/src/_/variables';

media-thumbnail {
	position: relative;
	display: flex;
	color: @color-dark-grey2;
	cursor: default;
	overflow: hidden;

	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
	}

	> .inner-content {
		display: flex;
		flex: 1;
		width: 100%;
		max-width: 100%;
		color: @color-emperor;

		&.unpreviewable {
			cursor: default!important;
		}

		&.playable {
			.thumbnail-cover-overlay {
				display: flex;
			}
		}

		&.image-loaded {
			.thumbnail-cover-content {
				padding: 0;
			}
		}

		&.highlight-duration {
			.thumbnail-cover {
				.duration-label {
					background-color: @color-primary-error;
				}
			}
		}

		&:hover,
		&:focus,
		&.action-focused {
			.media-actions {
				opacity: 1;
			}
		}
	}

	.thumbnail-cover {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1;
		width: 100%;
		max-width: 100%;

		&.failed-state {
			> .thumbnail-cover-title {
				color: @color-red;
				text-align: center;
			}
		}

		&:focus,
		&:hover {
			.thumbnail-cover-overlay {
				background-color: rgba(0, 0, 0, 0.4);
			}
			.preview-icon-container {
				opacity: 0.85;
			}
		}

		.thumbnail-cover-inner {
			button i, img {
				color: @color-white;
			}
		}
	}

	.thumbnail-cover-content {
		position: relative;
		display: flex;
		flex: 1;
		padding: 16%;

		go-pdf-page {
			min-height: 70px;
		}

		.thumbnail-cover-content-renderer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			flex: 1;
		}
	}

	.thumbnail-cover-title {
		margin-top: 2px;
		font-weight: 300;
		text-align: center;
		color: black;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.thumbnail-cover-overlay {
		display: none;
		align-content: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: transparent;

		.preview-icon-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			padding: 0 25%;
			opacity: 0;
		}

		.preview-icon {
			height: 1em;
			line-height: 100%;
			i {
				color: @color-white;
			}
		}

		.duration-label {
			position: absolute;
			bottom: 0;
			right: 0;
			border-radius: 2px;
			background-color: rgba(0, 0,0 , 0.65);
			color: #fff;
			padding: 0 3px;
			margin: 2px;
			font-size: clamp(14px, .875rem, 28px);
		}
	}

	.media-actions {
		opacity: 0;
		display: flex;
		transition: opacity .25s ease-in-out;
		position: absolute;
		flex-direction: row;
		justify-content: center;
		left: 0;
		right: 0;
		bottom: 15%;

		> ul {
			display: flex;
			flex-direction: row;
			margin: auto;
			padding: 0;

			list-style: none;
			border-radius: 4px;
			background-color: rgba(255, 255, 255, 0.9);

			box-shadow: 1px 2px 10px 0 rgba(0,0,0,.5);
			white-space: nowrap;
			border: none;

			> li {
				display: flex;
				flex: 1;

				&:only-child {
					.btn {
						min-width: 60px;
					}
				}

				.btn {
					flex: 1;
				}
			}
		}

		button.btn {
			padding: 2px 8px;

			.ficon-play {
				color: @color-grey;
			}

			.ficon-app-edit {
				color: var(--color-secondary);
			}

			.ficon-times {
				color: @color-red2;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.5);
			}
		}
	}

	.fallback-icon {
		line-height: 100%;
		height: 1em;
	}

	.fill {
		flex: 1;
	}
}
