/** BADGES */
@import 'variables';

.goreact-token {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: @color-off-white;
    box-shadow: @default-black-box-shadow;
    color: @color-off-gray;
    font-size: 12px;
    font-weight: bold;
    height: 26px;
    min-width: 26px;
    cursor: pointer;

    i {
        color: @color-off-gray;
    }
}
