@color-transparent-black: rgba(0, 0, 0, 0.65);
@color-button-background: rgba(100, 100, 100, 0.7);

.grab-to-pan-grabbing {
	cursor: grabbing;
}

.dark-mode go-doc-viewer {
	color: white;

	.control-btn {
		color: white;
		background: @color-button-background;

		i {
			color: inherit;
		}
	}
}

go-doc-viewer {
	height: 100%;
	width: 100%;
	display: block;
	position: relative;

	.fullscreen-option-container {
		position: absolute;
		right: 10px;
		bottom: 0px;
		z-index: 100;

		button {
			padding: 5px 13px 2px 13px;
			background-color: @color-button-background;
			margin-bottom: 10px;

			i {
				color: white;
				&.ficon-app-fullscreen-expand {
					font-size: 1em;
				}
			}
		}
	}

	.img-viewer {
		overflow: auto;
		text-align: center;
		position: relative;
		&.scaled {
			overflow: hidden;
			&:before {
				content: none;
			}
			img.scale-img {
				max-height: none;
				display: block;
				max-width: none;
				margin: 0 auto;
			}
		}

		&.scaled.mobile {
			img.scale-img {
				overflow: auto;
			}
		}

		height: 100%;
		width: 100%;
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			margin-right: -0.25em; /* Adjusts for spacing */
		}
	}

	img.scale-img {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
		max-height: 100%;
	}

	section {
		height: 100%;
		width: 100%;

		&.scaled {
			cursor: grab;
			&:active {
				cursor: grabbing;
			}
		}
	}

	go-pdf-page canvas {
		border: 1px solid @color-light-grey;
	}

	go-pdf-page .loading-indicator {
		font-size: 4em;
	}

	.arrow-navigation {
		width: inherit;
	  position: absolute;
	  transform: translateY(-50%);
	  top: 50%;
		z-index: 3;

		.btn-arrow-left {
			margin-left: 10px;
		}

		.btn-arrow-right {
			margin-right: 10px;
		}
	}

	/* bottom middle align */
	footer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;
		z-index: 3;

		text-align: center;
	}

	.page-indicator, .control-btn {
		color: @color-white;
		background: @color-transparent-black;
		height: 2em;
	}

	.controls {
		margin: 10px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 5;
	}

	.control-btn {
		cursor: pointer;
	}

	.control-btn {
		vertical-align: top;
		padding-top: 3px;
		padding-bottom: 10px;
		float: left;
		margin-left: 5px;
	}

	.page-indicator {
		display: inline-block;
		width: 10ch;

		border-radius: 4px;
		border: 0;

		line-height: 2em;
		text-align:center;
	}
}
