@import '~go-styles/src/_/variables';

ng-messages {
	font-size: 12px;

	&.error-text, .error-text, .error-text span {
		color: @color-primary-error;
	}
}

form[name], ng-form[name] {
	input.ng-touched.ng-invalid,
	textarea.ng-touched.ng-invalid,
	select.ng-touched.ng-invalid,
	go-typeahead-select.ng-touched.ng-invalid input,
	#braintree-v3-pay-form .braintree-form__field-group--has-error .braintree-form__hosted-field,
	datetimepicker.ng-touched.ng-invalid input {
		border: 1px solid @color-primary-error;
	}
}

form[name].ng-submitted, ng-form[name].ng-submitted {
	input.ng-invalid,
	textarea.ng-invalid,
	select.ng-invalid,
	go-typeahead-select.ng-invalid input,
	#braintree-v3-pay-form .braintree-form__field-group--has-error .braintree-form__hosted-field,
	datetimepicker.ng-invalid input {
		border: 1px solid @color-primary-error;
	}
}

