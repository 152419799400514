@import '~go-styles/src/_/variables';

.rb-text {
  &.rb-widget {
	padding: 4px 4px 4px 8px;
	border: 1px dashed @color-grey;
	border-radius: 8px;
	background: @color-white;
	cursor: pointer;
	text-align: left;

	.rb-label {
	  margin-bottom: 8px;
	  font-weight: bold;
	}

	.rb-avatar {
	  padding-bottom: 10px;
	  text-align: left;
	  font-style: italic;
	}
  }
}
