label-wrapper {
  position: relative;
  display: inline-block;
  > label.label-wrapper-label {
    font-weight: normal;
    position: absolute;
    background-color: #6D6F70;
    top: -28px;
    left: 4px;
    display: inline-block;
    max-width: inherit;
    color: white;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    z-index: 200;
    white-space: nowrap;
    font-size: 13px;
  }
}

// Overrides for stupid awesome bootstrap checkbox issues
.checkbox label-wrapper label::before {
	opacity: 0;
	position: absolute;
	content: '';
	width: 0;
	height: 0;
}

// this adds additional spacing for rb-category type inputs
.rb-category.rb-edit, .rb-checkbox.rb-edit {
  label-wrapper > label.label-wrapper-label {
    left: 12px;
  }
}
