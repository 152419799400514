@import '~go-styles/src/_/variables';

toolbar-button {
	display: inline-block;

	.action-icon {
		font-size: inherit;
	}

	&[action="edit"] button {
		color: @color-emperor;
	}

	&[action="remove"] button {
		color: @color-emperor;
	}
}
