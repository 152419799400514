@import '~go-styles/src/_/variables';

.rb-panel {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: @panel-width;
  border-right: 1px solid #ccc;
  background-color: @color-off-white;
  padding: 10px 16px;
  min-height: @canvas-min-height;

  .rb-panel-header {
		margin-bottom: 10px;

    h3 {
			margin-top: 0;
    }
  }

  [rubric-widget] {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
