/** Ellipsis **/

.ellipsis {
	.text-overflow();
}

.btn-toggle {
	color: @color-med-grey;
	box-shadow: none;

	&.active {
		color: @color-dark-grey;
	}
}

.close-btn {
	color: @color-grey2;
}
