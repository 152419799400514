&.tools-disabled {
	@media (min-width: @screen-collapse-min) {
		#main-splitter {
			> .bg-pane-right {
				display: none;
				left: auto;
				right: 0;
			}

			> bg-handle {
				// modified to visibility hidden instead of `display:none`
				// to keep track of bg handle current location when toggling feedback tools
				visibility: hidden;
			}

			> .bg-pane-left,
			> .bg-pane-left > #left-section {
				top: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
				right: 0 !important;
				width: auto !important;
				height: auto !important;
			}
		}

		media-display {
			padding: 0px 5px;
		}
	}
}

&.feedback-disabled {
	#right-section {
		display: none;
	}

	#left-section {
		right: 0;
	}
}

/** drop feedback display down to bottom */
&.comments-disabled {
	feedback-display .feedback-display-content {
		bottom: 0 !important;
	}
}
