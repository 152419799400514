.tooltip .tooltip-inner {
	max-width: 500px;
}

.rb-linebreak {
	height: 12px;
}

.rb-canvas {
	position: relative;
	height: 100%;
	padding: 20px;
	min-height: @canvas-min-height;
	min-width: 450px;
	max-width: 1700px;

	.btn {
		border: none;
		border-radius: 12px;
	}

	.rb-droppable {
		position: relative;
		min-height: @canvas-min-height;

		&.rb-droppable-empty {
			border: 1px dashed @color-med-grey;

			.rb-element-placeholder {
				display: none;
			}
		}

		.rb-element-placeholder {
			opacity: .5;
			background-color: @color-light-yellow;
			cursor: move;
		}

		&.active {
			border-color: @color-light-grey;
		}

		.rb-droppable-desc {
			font-size: 18px;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		&.active .rb-droppable-desc {
			display: none;
		}

	}

	input,
	textarea {
		padding: 2px 6px;
	}

	/** Label input */
	.label-input {
		.li-input {
			text-align: inherit;
			font-size: inherit;
			height: auto;

			width: 100%;
			margin: 0;
			box-sizing: border-box;
		}

		.li-label {
			cursor: text;
			padding: 3px 6px;
			outline: 1px dashed transparent;

			&:hover {
				outline: 1px dashed @color-med-grey;
			}
		}

		&.placeholder {
			.li-label {
				font-style: italic;
			}
		}
	}

	&.rb-edit {
		margin-left: @panel-width + 1px;

		rubric-element {
			.rb-showHide {
				display: block;
			}

			.rb-actions {
				.btn {
					margin-left: 5px;
				}
			}
		}
	}

	.rb-header {
		margin-bottom: 20px;
		margin-top: 8px;

		.rb-title {
			font-size: 24px;
		}
	}

	.rb-editable {
		background: none;
	}

	.rb-actions {
		display: none;

		position: absolute;
		top: -14px;
		right: 0;

		z-index: 1;
		padding: 4px;
		border-radius: 6px;

		.btn {
			min-width: 0;
			font-size: 12px;
		}

		.btn:first-child {
			margin-left: 0 !important;
		}
	}

	.rb-element-draggable {
		&:hover {
			background: rgba(255,255,255,0.85);
		}
	}

	.rb-grouped {
		.rb-element {
			padding-left: @category-indent;
		}
	}

	// element style changes for when descriptions are in hidden mode
	&.rb-desc-hide {
		.rb-desc {
			display: none!important;
		}

		.rb-numeric {
			.rb-scale-option {
				min-width: 40px;
			}

			.btn {
				min-width: 30px;
			}
		}

		.rb-qualitative {
			.rb-scale-option {
				min-width: 40px;
			}

			.btn {
				min-width: 30px;
			}
		}
	}
}
