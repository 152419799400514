go-attachment {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
go-attachment media-thumbnail {
  width: 45px;
}
go-attachment .title {
  margin: 0 8px;
  flex: 1;
  color: #333333;
}
go-attachment .action-buttons {
  margin-left: auto;
  min-width: 50px;
}
go-attachment .edit-attachment-btn {
  color: var(--color-secondary);
}
go-attachment .remove-attachment-btn {
  color: var(--color-primary);
}
go-attachments {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
}
go-attachments .hide-add-btn .add-btn {
  display: none;
}
go-attachments .hide-add-btn .attachments-list {
  margin-bottom: 0;
}
go-attachments .attachments-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
}
go-attachments .attachments-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px;
  margin: 0;
  flex: 1;
  width: 100%;
  margin-bottom: 10px;
}
go-attachments .attachments-list:empty {
  margin-bottom: 0;
}
go-attachments .attachment-list-item {
  display: flex;
  flex: 1;
  margin-bottom: 5px;
  min-height: min-content;
}
go-attachments .attachment-list-item:last-child {
  margin-bottom: 0;
}
go-attachments go-attachment {
  flex: 1;
  width: 100%;
}
go-attachments .loading-indicator {
  color: #333;
}
go-attachments .loading-indicator .ficon-spinner {
  font-size: 18px;
}
