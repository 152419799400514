@import 'icon-fonts-basics';
@import 'icon-fonts-extended';
@import 'icon-fonts-aliases';
@import 'custom-fonts';

// _ files
@import '_/arrows';
@import '_/badge';
@import '_/button-dropdown';
@import '_/button-groups';
@import '_/button-none';
@import '_/button';
@import '_/custom-colors';
@import '_/dropdowns';
@import '_/forms';
@import '_/general';
@import '_/help';
@import '_/inputs';
@import '_/labels';
@import '_/lists';
@import '_/mixins';
@import '_/modals';
@import '_/nav-pills';
@import '_/notification-ribbons';
@import '_/popover';
@import '_/side-panel';
@import '_/user-widget';
@import '_/ui-select';
@import '_/animations';
@import '_/iframe';
@import '_/snack-bar-panel';
@import '_/tease-wall';

// Colors defined here in root are modifiable
// by partners, and need to be used as CSS variables
// and not LESS variables
:root {
	--color-primary: #E12A43;
	--color-primary-hover: #9F001C;
	--color-secondary: #00A39A;
	--color-secondary-hover: #00A39A;
	--color-tertiary: #EDCB50;

	// Extra colors
	--color-secondary-light: #86D0CC;
	--color-secondary-extra-light: #C2E6E4;
	--color-secondary-ultra-light: #DCF5F1;
	--color-light-yellow: #FCF7E3;
	--color-light-red: #FCEAE6;

	// Specialized colors
	--color-form-border: #929292;
	--color-teal: #00847D;
	--color-chilean-fire: #D45728;
	--color-indigo: #2D3750;
}

.accessibility-text {
	position: absolute;
	margin: -1px;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

*:focus, .checkbox input[type="checkbox"]:focus ~ label:before, .outlined-focus-within:focus-within {
	outline: @outline-width solid fade(@color-outline, 90%) !important;
}

.focus-outline-white {
	&:focus, *:focus, .checkbox input[type="checkbox"]:focus ~ label:before, .outlined-focus-within:focus-within {
		outline: @outline-width solid fade(@color-outline-white, 90%) !important;
	}
}

.focus-outline-dark, .focus-outline-white .focus-outline-dark {
	&:focus, *:focus, .checkbox input[type="checkbox"]:focus ~ label:before, .outlined-focus-within:focus-within {
		outline: @outline-width solid @color-outline !important;
	}
}

*:focus:not(:focus-visible) {
	outline: 3px auto fade(@color-primary-focus, 60%) !important;
	outline-offset: 2px;
}

*:focus-visible, .checkbox input[type="checkbox"]:focus-visible ~ label:before, .outlined-focus-within:focus-within {
	outline: @outline-width auto @color-outline !important;
}

.focus-outline-dark, .focus-outline-white .focus-outline-dark {
	&:focus-visible, *:focus-visible, .checkbox input[type="checkbox"]:focus-visible ~ label:before, .outlined-focus-within:focus-within {
		outline: @outline-width auto fade(@color-outline, 90%) !important;
	}
}

.focus-outline-white, .focus-outline-dark .focus-outline-white {
	&:focus-visible, *:focus-visible, .checkbox input[type="checkbox"]:focus-visible ~ label:before, .outlined-focus-within:focus-within {
		outline: @outline-width auto fade(@color-outline-white, 90%) !important;
	}
}

.mat-form-field-appearance-outline {
	*:focus {
		outline: none !important;
	}
}

[role=tab][aria-selected=true] {
	font-weight: bold;
}

//Date picker button outline
.date-button:focus {
	outline: 3px auto @color-outline !important;
	outline-offset: 2px !important;
}

.tooltip-link-light {
	color: @color-white;
	text-decoration: underline;

	&:focus, &:hover {
		color: @color-white;
	}
}

@media screen and (max-width: 600px) {
	// Custom tooltip directive needs to be centered better on smaller screens
	.clipboard-directive-tooltip {
		position: relative;
		top: 20px !important;
		left: 15px !important;
	}
}

.mat-icon {
	font-size: 1.3em;
}

td, th {
	font-family: proxima-nova, "Open Sans", sans-serif;
}

// ngx-dialog
.cdk-overlay-pane > .mat-dialog-container {
	padding: 0;
}

.ngx-dialog {
	max-width: 500px;
	min-width: 200px;

	.dialog-header {
		background-color: @color-alabaster;
		padding: 20px 18px;
		border-bottom: 1px solid @border-default;
		margin: 0;
		font-size: 1.25em;
	}

	.dialog-content {
		padding: 20px;
	}

	.dialog-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 20px 18px;

		button {
			margin-left: 10px;
		}
	}
}

.mat-option .mat-option-text {
	color: @color-darker-grey-font;
}

.mat-menu-panel {
	margin-bottom: 5px;
	min-height: unset !important;

	// For markers
	&.tag-menu {
		max-height: 410px !important;
	}

	.mat-menu-item {
		min-width: 160px;
		padding: 3px 20px;
		color: @color-dark;

		&.tag-menu-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 40px;
			line-height: 22px;

			> span {
				vertical-align: bottom;
			}
		}

		&.selected {
			background-color: @color-grey-highlight;
		}

		// For markers
		&:not(.tag-menu-item) {
			height: 30px;
			line-height: 24px;
		}

		> mat-icon {
			font-weight: normal;
			font-style: normal;
			font-size: 16px;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			color: @color-emperor;
			vertical-align: top;
		}

		> span {
			margin-left: 10px;
		}

		&:hover {
			background-color: @color-xlight-grey2 !important;
			text-decoration: none;
		}

		&:focus {
			text-decoration: none;
		}
	}
}

.zero-state {
	display: flex;
	justify-content: center;

	> div {
		margin-top: 30px;
		text-align: center;

		.zero-state-title {
			font-weight: 700;
			font-size: 42px;
		}

		.zero-state-subtitle {
			font-weight: 400;
			font-size: 18px;
			margin-top: 8px;
		}

		.zero-state-image {
			margin-top: 45px;
			margin-bottom: 10px;
		}
	}
}

.go-select.mat-form-field {
	border: 1px solid @color-xlight-grey2;
	border-radius: 25px;
	padding: 0px 5px;

	&:hover {
		background-color: @color-xlight-grey2;
	}

	.mat-form-field-wrapper {
		padding-bottom: 0px !important;
	}

	mat-error {
		padding-top: 5px;
		font-size: 12px;
	}

	.mat-select-value {
		max-width: 100%;
		width: auto;
	}

	.mat-form-field-underline {
		display: none;
	}
}


[hidden] {
	display: none !important;
}
