@import '~go-styles/src/_/variables';

user-activity-history {
  display: block;

  .list-group {
    margin-bottom: 0;

    & > {
      height: inherit;
      overflow: auto;
    }
  }

  .list-group-item {
    padding: 5px 8px;
    border-right: none;
    border-left: none;
    font-weight: 300;
    color: @color-xdark;

    &.active {
      .media-right {
        color: @color-light-grey;
      }
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }

  .categorized {
    &.list-group {
      > .list-group-item {
        border: none;

        > .list-group-item-heading {
          margin: 0 0 5px 0;
          font-size: 18px;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .media {
    .media-heading {
      font-size: 14px;
    }

    .media-right {
      min-width: 130px;
      text-align: right;
      color: @color-grey;
    }
  }

  @media (max-width: @screen-sm-min - 1) {
    .media {
      .media-right {
        display: none;
      }
    }
  }
}
