/** BADGES */
@import 'variables';

// for all of these values it is current value in px, that value/16 (default browser font size), double that value for 200% font size
// calculated values copied from session-list-item-view style
@fourteen: clamp(13.8px, .8625rem, 27.6px);
@twenty: clamp(19.6px, 1.225rem, 39.2px);

.badge-tiny {
  font-size: 8px;
  padding: 3px 6px;
}

.badge-char-icon {
  font-size: 12px;
}

.badge-white {
  color: @color-dark;
  background-color: @color-white;
}
.badge-white:hover {
  color: @color-dark;
  background-color: @color-white;
}
.badge-xlight-grey {
  color: @color-dark-grey;
  background-color: @color-xlight-grey;
}
.badge-xlight-grey:hover {
  color: @color-dark-grey;
  background-color: @color-xlight-grey;
}
.badge-light-grey {
  color: @color-dark2;
  background-color: @color-light-grey;
}
.badge-light-grey:hover {
  color: @color-dark2;
  background-color: @color-light-grey;
}
.badge-med-grey {
  background-color: @color-med-grey;
}
.badge-med-grey:hover {
  background-color: @color-med-grey;
}
.badge-grey {
  background-color: @color-emperor;
	color: @color-white;

	strong {
		color: @color-white
	}
}
.badge-grey:hover {
  background-color: @color-darker-grey-font;
}
.badge-dark-grey {
  background-color: @color-dark2;
}
.badge-dark-grey:hover {
  background-color: @color-dark-grey;
}
.badge-green {
  background-color: #63AE28!important;
}
.badge-green:hover {
  background-color: #6DC12A!important;
}
.badge-yellow {
  background-color: @color-yellow;
}
.badge-yellow:hover {
  background-color: @color-yellow2;
}
.badge-red {
  background-color: @color-primary;
	color: @color-white;

	strong {
		color: @color-white;
	}
}
.badge-red:hover {
  background-color: @color-red;
}

.corner-badge {
  position: relative;

  .badge {
    background-color: @color-grey2;
    &.top-right {
      position: absolute;
      text-align: center;
      top: -4px;
      left: 100%;
      margin-left: -14px;
    }

    &.badge-inverse {
      background: @color-xlight-grey;
      color: @color-black;
    }
  }
}

.badge-primary, .badge-secondary, .badge-light, .badge-muted {
  &.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
}

.badge-primary {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.badge-muted {
  background-color: transparent;
  border: 1px solid @color-emperor;
	color: @color-darker-grey-font;
}

.badge-secondary {
  background-color: transparent;
  border: 1px solid @color-link;
  color: @color-link;
}

.badge-light {
  background-color: transparent;
  border: 1px solid @color-med-grey-font;
  color: @color-white;
}

.goreact-badge {
	background-color: @color-goreact-badge;
	font-size: 14px;
	font-weight: normal;
	border: 1px solid @color-goreact-badge-border;
	color: @color-goreact-badge-font;
	cursor: text;
	border-radius: 2px;
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	line-height: 1;
	text-align: center;
}

.badge-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  font-size: @twenty;

  .badge-indicator-value {
    font-size: @fourteen;
    font-weight: normal;
    margin-left: 5px;
  }
}