/* Import global style variables */
@import '~go-styles/src/_/variables';
@import 'styles/variables';
@import 'styles/mixins';

feedback-session {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	background-color: @color-white;

	.content {
		display: flex;
		flex-direction: row;
		flex: 1;

		@media (max-width: @screen-collapse-min) {
			flex-direction: column;
			background: @color-indigo;

			>feedback-display-panel {
				display: none;
			}
		}

		feedback-session-content {
			flex: 1;
			background-color: @color-black;
		}
	}

	.btn:active:focus {
		color: @color-darker-grey-font;
	}

	@import 'styles/general';
	@import 'styles/button';
	@import 'styles/screen-orientation';
	@import 'header/style';
	@import 'content/layout-modes';
	@import 'content/content';
	@import 'media-display/style';
	@import 'single-media-display/style';
	@import 'multi-media-display/style';
	@import 'session-media-display/style';
	@import 'stimulus-response-viewer/style';
	@import 'slide-presentation-viewer/style';
	@import 'feedback-display/feedback-display';
	@import 'feedback-tree/style';
	@import 'comment-sync-event-viewer/style';
	@import 'comment-captions/comment-captions';
	@import 'notification-ribbon/notification-ribbon';
}
