@import '~go-styles/src/_/variables';

.rb-checkbox {
  &.rb-widget {
	padding: 8px;
	border: 1px dashed @color-grey;
	border-radius: 8px;
	cursor: pointer;
	text-align: left;

	&:hover,
	&:active {
	  background: @color-xlight-grey;
	}

	.rb-avatar {

	  .rb-checkbox {
		display: inline-block;
		float: left;
		margin-right: 4px;
	  }

	  .rb-label {
		font-weight: bold;
	  }
	}
  }
}
