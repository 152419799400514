@import '~go-styles/src/_/variables';

.universal-media-chooser-modal {
	z-index: 1082 !important;

	&.goreact-modal {
		// Have to manually set this or else the ngx confirm dialog shows up behind this
		~ .cdk-overlay-container:has(app-confirm-dialog),
		~ .cdk-overlay-container:has(app-message-dialog),
		~ .cdk-overlay-container:has(ios-upload-dialog) {
			z-index: 1083 !important;
		}

		.modal-dialog {
			width: auto;
			height: 100%;
			max-height: calc(100% - (2 * @modalTopMargin));
			margin: 0 auto;

			@media (min-width: @maxModalWidth) {
				width: 820px;
				height: 700px;
			}
		}

		.modal-content {
			display: flex;
			flex-direction: column;
			height: 100%;
			min-height: 400px;

			form {
				display: flex;
				flex-direction: column;
				height: 100%;
				min-height: 0;
			}
		}

		.goreact-modal-header {
			background-color: @color-off-white;
			padding: 5px;

			.umc-header {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				padding: 0;
			}

			.umc-option {
				&.active::after, &.active::before {
					top: 110%;
				}
			}
	  }

	  .goreact-modal-body {
			padding: 0;
			height: 100%;
			min-height: 0;
	  }

	  .goreact-modal-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-wrap: wrap;
			height: 60px;
			padding: 5px 15px;

			.help-link-container {
				margin-right: auto;

				.support-link {
					vertical-align: middle;
				}
			}

			.cancel-btn {
				height: auto;
			}

			@media (max-width: @screen-xs-min) {
				flex-direction: column;
				height: auto;
				padding: 15px;

				.primary-btn {
					order: 1;
					width: 100%;
				}
				.cancel-btn { order: 2; }
				.help-link-container {
					order: 3;
					margin-right: unset;
				}
			}
		}
	}
}
