@import '~go-styles/src/_/variables';

.fine-uploader-container {
	@import 'uploader';

	.slow-upload-warning {
		margin-top: 10px;
	}

	.ios-upload-banner {
		position: absolute;
		padding: 1rem;
		top: -1px;
		width: 100%;
		background: var(--color-light-yellow);
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div {
			.open-ios-modal-button {
				font-weight: bold;
			}
		}

		.close-banner {
			opacity: 1;
		}
	}

	& > div {
		height: 100%;
		min-height: inherit;
		max-height: inherit;
	}

	line-height: 1.42857143;

	.fup-error {
		color: @color-primary-error;
		background-color: white;
		border-radius: 3px;
		padding: 12px;
		text-align: center;
	}

	.files {
		margin: 0;
		padding: 0;
		list-style: none;

		.file {
			line-height: normal;

			input {
				vertical-align: middle;
				background-color: rgba(0, 0, 0, 0.3);
				padding: 6px 12px;
				font-size: 14px;
				line-height: 1.42857143;
				color: @color-white;
				background-image: none;
				border: 1px solid #555;
				border-radius: 4px;
				-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				-o-box-sizing: border-box;
				-ms-box-sizing: border-box;
				box-sizing: border-box;
				height: auto;
			}
		}
	}

	.outer {
		display: table;
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: @color-dark;
	}

	.middle {
		display: table-cell;
		vertical-align: middle;
	}

	.inner {
		text-align: center;
		color: #fff;
	}

	.no-select {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.btn {
		line-height: 1.42857143;
		box-shadow: none;
	}

	.progress {
		margin-bottom: 0;
	}

	.fup-alert {
		margin-bottom: 0;
	}

	.zoom-help-text-container {
		margin-top: 15px;
	}

	.zoom-qq-upload-button {
		width: 90%;
	}

	.zoom-help-text-container {
		width: 90%;
		display: inline-block;
	}

	@media (max-width: 600px) {
		.upload-zoom-local-recording-label {
			white-space: normal;
		}
	}

	.upload-message {
		text-align: left;
		font-size: 13px;
		margin-top: 10px;
		max-width: 340px;
	}

	.small-upload-title {
		font-size: 0.85em;
	}

	.extra-small-upload-title {
		font-size: 0.65em;
	}
}
