@import 'time-slider/style';
@import '~go-styles/src/_/variables';

.feedback-item {

	&.extra-padding {
		padding-bottom: 13px;
	}

	.unviewed-dot {
		background-color: var(--color-secondary);
		content: '';
		cursor: pointer;
		padding: 3px;
		border-radius: 100%;
		height: 5px;
		width: 5px;

		&.not-directed-at-me {
			border: 1px solid @color-font-medium-hover;
			background-color: transparent;
			padding: 2.5px;
		}
	}

	.feedback-item-content {
		flex: 1;
		color: @color-dark;
	}

	.feedback-text {
		margin-left: 0px;

		ul {
			list-style-type: disc;

			ul {
				list-style-type: circle;

				ul {
					list-style-type: square;
				}
			}
		}

		img {
			max-width: 450px;
			min-width: 268px;
			border-radius: 10px;
			width: 100%;
		}
	}

	.time-editor-form {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: left;
		flex: 1;
		margin: 0px 10px 10px 10px;

		feedback-tree-item-time-slider {
			flex: 1;
			margin: 0 10px;
			padding-top: 1px;
		}

		.action-buttons {
			display: flex;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2em;
				height: 2em;

				&:first-of-type {
					margin-right: 5px;
				}
			}

			.keep-btn {
				> i.ficon-app-checkmark {
					color: @color-emperor;
					font-size: 1em;
				}
			}

			.discard-btn {
				> i.ficon-app-x {
					font-size: 1.5em;
				}
			}
		}
	}

	.slide-preview {
		height: 265px;
		width: 95%;
		margin: 0px 10px 10px 15px;
	}

	.btn-bar {
		.btn:hover, .action-menu:hover {
			background-color: @color-xlight-grey;
		}
	}

	.feedback-item-cover {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 10px;
		border: none;
		border-left: 6px solid transparent;
		gap: 8px;
	}

	.timestamp {
		font-size: 12px;
		white-space: nowrap;
		color: @color-off-gray;
	}

	.new-timecode-badge-container {
		height: 20px;
		display: flex;
		align-items: center;

		// Style for this badge should match transcript timestamp
		.new-timecode-badge {
			font-size: 12px;
			background-color: @color-xlight-grey2;
			padding: 0 6px;
			border-radius: 3px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.editable:hover {
				background-color: @color-dark;
				color: @color-white;
			}
		}
	}

	.comment-header {
		display: flex;
		align-items: center;
		height: 20px;
		gap: 8px;

		.feedback-item-author {
			font-size: 15px;
			font-weight: bold;
		}

		.timestamp {
			font-size: 10px;
		}
	}

	.tag-list {
		display: flex;
		margin-top: 10px;
		margin-bottom: 5px;
		margin-left: 0px;
		margin-right: 0px;
		flex-wrap: wrap;

		> * {
			margin-right: 10px;
		}
	}

	.feedback-item-toolbar {
		margin-left: 6px;
		display: flex;
		align-items: center;
		background: @color-white;
		position: absolute;
		margin-top: -23px;
		right: 10px;
		height: 26px;
		padding: 0px 10px;
		border-radius: 360px;
		font-size: 0.8em;
		box-shadow: 1px 1px 4px 0 rgba(0,0,0,.25);
		min-width: max-content;
		z-index: 20;

		.divider {
			height: 18px;
			width: 1px;
			background-color: @color-xlight-grey2;
			margin: 0px 8px 0px 12px;
		}

		.btn, uib-dropdown.action-menu {
			height: 100%;

			> button {
				height: 100%;
			}

			.edit-btn, .remove-btn, .manage-ai-markers-btn {
				max-width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.media-list {
		.media-item {
			display: flex;
			flex-direction: column;
			margin-top: 15px;
			margin-bottom: 30px;

			.media-item-content {
				display: grid;
				grid-template-columns: 3fr 1fr;
				grid-template-rows: 1fr;
				max-width: 450px;
				min-width: 268px;

				&.has-preview {
					aspect-ratio: 4/3;
				}

				.media-item-preview {
					grid-column-start: 1;
					grid-column-end: 4;
					grid-row-start: 1;
					border-radius: 10px;
				}

				.media-item-toolbar {
					grid-column-start: 3;
					grid-row-start: 1;
					z-index: 6;
					background: @color-white;
					border-radius: 20px;
					box-shadow: 1px 1px 4px 0 rgba(0,0,0,.25);
					font-size: 0.8em;
					height: 26px;
					margin-top: -15px;
					margin-right: -15px;
					padding: 0px 3px;
					visibility: hidden;

					.btn, uib-dropdown.action-menu {
						height: 100%;

						> button {
							height: 100%;
						}
					}

					.btn:hover, .action-menu:hover {
						background-color: @color-xlight-grey;
						border-radius: 20px;
						display: flex;
					}
				}
			}

			.media-item-title {
				color: @color-off-gray;
				font-size: 12px;
				margin-top: 8px;
			}
		}

		.media-item:last-child {
			margin-bottom: 15px;
		}

		.media-item.active, .media-item:hover, .media-item:focus-within {
			.media-item-toolbar {
				visibility: visible;
			}
		}
	}

	uib-dropdown.action-menu > button {
		padding: 0;
	}

	> .default-mode {
		cursor: pointer;
	}

	// Have a 6px border always there so
	// items don't seem displaced when active
	// or highlighted
	border-left: 6px solid transparent;

	&.filter-highlight, &.filter-highlight:not(:hover) {
		opacity: 1;
		background-color: @color-list-item-hover;

		.timestamp {
			color: @color-font-medium-hover;
		}
	}

	.slide-deck-icon {
		vertical-align: middle;
	}

	@media (max-width: @screen-xs-min) {
		.feedback-item-author {
			max-width: 50vw;
			overflow: hidden;
			text-overflow: ellipsis;
			text-wrap: nowrap;
			display: block;
		}

		&.unviewed-dot-visible {
			.feedback-item-author {
				max-width: 25vw;
			}
		}
	}
}

.list-item, .list-item-child {
	&:hover, &.active {
		.feedback-item {
			background-color: @color-grey-highlight;
			.timestamp {
				color: @color-font-medium-hover;
			}

			.new-timecode-badge {
				background-color: @color-payne-grey;
				color: @color-white;
			}
		}
	}

	&.active {
		.feedback-item {
			border-left: 6px solid var(--color-tertiary);

			.feedback-item-author {
				max-width: 30vw;
			}
		}
	}
}

.list-item-child {
	.reply-indicator {
		margin-right: 7px;
		margin-left: 17px;
	}
}

.list-item.viewed, .list-item-child.viewed {
		.feedback-item {
			background-color: @color-list-item-hover;
		}
		&:hover, &.active {
			.feedback-item {
				background-color: @color-grey-highlight;
				.timestamp {
					color: @color-font-medium-hover;
				}
			}
	}
}

// Reply display
.feedback-item .reply-indicator {
	display: none;
	font-family: FontAwesome;
	transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	color: @color-dark-grey;
}

ul ul.tree-view .feedback-item .reply-indicator {
	display: inline-block;
}


// Set border color to color secondary when we are
// highlighting or dealing with an active feedback item
.list-item.active .feedback-item,
.list-item .feedback-item.filter-highlight {
	border-left-color: var(--color-tertiary);
}

.read-receipts-popover {
	width: fit-content;
	max-width: 335px;

	@media (max-width: @screen-xs-min) {
		width: 190px;
	}

	.popover-inner {
		width: 100%;

		.popover-content {
			width: 100%;
			max-height: clamp(50px, 20vh, 200px);
			overflow: auto;

			.read-receipts-popover-wrapper {
				display: flex;
				flex-direction: column;

				.ficon-spinner {
					color: white;
					font-size: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.comment-viewers-title {
					font-weight: bold;
				}

				.comment-viewers-item {
					display: flex;
					justify-content: space-between;

					.comment-viewers-name {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: @color-white;
					}

					.comment-viewers-date {
						margin-left: 10px;
						white-space: nowrap;
						color: @color-white;
					}
				}
			}
		}
	}
}
