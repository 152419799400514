@import 'variables';

/** List 2 */
.list2 {
  background-color: @color-white;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;

  .list-header {
    padding: 5px 10px;
    margin-top: 5px;
    color: @color-grey;
  }

  .list-item {
    line-height: 18px;

    .list-label {
      display: block;
    }

    .list-head {
      .btn {
        &:hover {
          background-color: @color-med-grey;
        }

        &[disabled] {
          background: none;
        }
      }
    }

    .list-head {
      color: @color-dark-grey;
      padding: 8px;
      cursor: pointer;
      white-space: nowrap;

      &.disabled {
        .badge,
        .list-label {
          opacity: .7;
        }
      }

      &.active {
        color: @color-dark;
        background-color: @color-med-grey2;

        &:hover {
          background-color: @color-light-grey;
        }
      }

      &:hover {
        background-color: @color-light-grey2;
      }

      &.list-head-title {
        padding: 10px 8px;
      }

      &.active {
        .btn {
          &.focus {
            background-color: @color-med-grey;
            -moz-box-shadow:    inset 0 0 4px @color-grey2;
            -webkit-box-shadow: inset 0 0 4px @color-grey2;
            box-shadow:         inset 0 0 4px @color-grey2;
          }
        }
      }
    }
  }

  .badge {
    display: inline-block
  }

  .list2 {
    .list-item {
      .list-head {
        padding-left: 14px;
      }
    }

    .list2 {
      .list-item {
        .list-head {
          padding-left: 20px;
        }
      }

      .list2 {
        .list-item {
          .list-head {
            padding-left: 26px;
          }
        }
      }
    }
  }
}


/** Switch to color icons on list item hover */

/** Hide badges if button disabled */
.list > li .btn[disabled] .badge {
  display: none;
}

  /*.list li .btn-band .btn:hover,*/
.list .btn-none:hover {
  background-color: @color-med-grey;
}

.list > li:hover .badge-tiny,
.list > li.active .badge-tiny {
  background-color: @color-red2;
}

.list-header {
  line-height: 18px;
  padding: 5px 10px;
  margin: 0px;
  background-color: @color-white;
  color: @color-grey;
}

/** LIST */

.list {
  background-color: @color-white;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.list > li {
  padding: 8px 6px;
  color: @color-dark;
  cursor: pointer;
  background-color: @color-xlight-grey;
  border-top: 1px solid @color-light-grey;
  list-style: none;
}
.list > li:last-child {
  border-bottom: 1px solid @color-light-grey;
}
.list > li:hover {
  background-color: @color-light-grey2;
}
.list > li.active {
  background-color: @color-med-grey2;
  border-color: @color-grey;
}
.list > li.active + li {
  border-top: 1px solid @color-grey;
}
.list > li.active:last-child {
  border-color: @color-grey;
}
.list > li.active:hover {
  background-color: @color-light-grey;
}
.list > li.list-category {
  font-weight: bold;
  padding: 3px 5px;
  background: none;
  cursor: default;
}

.list > li .badge {
  /*padding-top: 2px;*/
  z-index: 1;
}
.list > li .btn-band {
  display: inline-block;
  margin-top: -1px;
}
.list > li .btn-band .band {
  display: inline-block;
}
/** btn focus */
.list .active .btn.focus {
  background-color: @color-med-grey;
  -moz-box-shadow:    inset 0 0 4px @color-grey2;
  -webkit-box-shadow: inset 0 0 4px @color-grey2;
  box-shadow:         inset 0 0 4px @color-grey2;
}
.list > li .ellipsis {
//  display: inline-block;
//  max-width: 125px;
}
.list > li.active .desc.badge {
  background-color: @color-white;
  color: @color-dark;
}
.list > li .desc {
//  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}

.list > li.disabled,
.list > li[disabled='disabled'] {
  color: @color-grey2;
}
.list > li.disabled.active,
.list > li[disabled='disabled'] {
  background-color: @color-light-grey;
}


/** List flow **/
.list-flow {
  list-style-type: none;
  margin: 0;
}

.list-flow > * {
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
}

// Nav-List (Library, etc)
.nav-list {
  li > a {
    color: rgba(0,0,0,.87);
  }
  li.active > a,
  li.active > a:hover {
    background-color: @color-menu-bg-active;
    color: @color-white;
  }
  li > a:hover {
    background-color: @color-menu-bg-hover;
  }
}
