
.mat-snack-bar-container {
	&.snack-bar-panel  {
		color: unset;
		background-color: #fff;
		max-width: 400px;
		padding: 20px 25px;
		border-radius: 10px;
	}
}
