.rb-scale {
	.rb-title {
		font-weight: bold;
	}

	.rb-desc {
		text-align: left;
	}

	.flex-container {
		display: flex;
		flex-direction: row;
	}

	.rb-scale-option {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: @scale-option-width;
		white-space: normal;
		padding: 0 15px 0 0;

		.rb-label {
			margin-top: 5px;
		}

		.rb-desc {
			font-size: 12px;
			margin-bottom: 0;
			line-height: 14px;

			textarea {
				resize: none;
				min-height: 80px;
			}
		}

		.rb-option-title {
			margin: 2px;
			background-color: @color-list-item-hover;
		}

		.btn-danger {
			background-color: transparent;
			min-width: 0;
			cursor: pointer;

			i {
				color: @color-primary;
			}
		}

		.btn-success {
			cursor: pointer;
		}

		> .btn {
			position: relative;
			min-width: 30px;
			cursor: pointer;
		}
	}

	.option-handle {
		height: 100%;
		width: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: @color-list-item-hover;
		cursor: move;
	}

	.btn-default {
		border-radius: 2px;
		background-color: transparent;
		border: 1px solid @color-xlight-grey2;
		color: @color-darker-grey-font;
		display: flex;
		align-items: center;
	}

	.btn-rubric-on {
		border-radius: 2px;
		background-color: @color-emperor;
		border: 1px solid @color-emperor;
		color: @color-white;

		&:hover {
			background-color: @color-emperor;
		}

		&:active {
			color: @color-white;
		}
	}

	// for some reason !important is required to get these elements to print properly
	@media print {
		.btn-default {
			background-color: @color-alabaster !important;
		}

		.btn-rubric-on {
			background-color: @color-emperor !important;
			color: @color-white !important;
		}
	}

	&.rb-edit {
		.rb-scale-option {
			> .btn {
				padding: 0;

				.li-label {
					padding: 6px 12px;
				}

				.li-input {
					padding: 6px 12px;
					width: 100px;
				}
			}

			&.rb-scale-add-option {
				text-align: left;

				> .btn {
					cursor: pointer;
					padding: 6px 12px;

					&.btn-success {
						background-color: @color-secondary;

						&:hover {
							background-color: @color-secondary;
						}
					}
				}
			}
		}
	}

	&.rb-live {
		.btn {
			cursor: pointer;
		}
	}

	&.rb-print {
		.rb-scale-option {
			> .btn {
				cursor: default;
			}
		}

	}
}
