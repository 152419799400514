@import '~go-styles/src/_/variables';

rubric-grade-totals-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: @color-off-white;
	position: relative;

	&.visible {
		border-bottom: 1px solid @color-separator;
	}

	.rubric-scores {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		padding: 10px;
	}

	.rubric-score {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 15px;
		font-size: 14px;

		.rubric-score-icon {
			margin-right: 10px;
		}
	}
}
