@color-transparent-black: rgba(0, 0, 0, 0.65);

go-pdf-viewer {
	height: 100%;
	width: 100%;
	display: block;
	position: relative;

	section {
		height: 100%;
		width: 100%;
	}

	go-pdf-page .loading-indicator {
		font-size: 4em;
	}

	.pdf-nav {
		/* vertical align */
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 10;
		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			font-size: 0;
		}

		/* left and right align */
		width: 100%;
		text-align: justify;
		&:after {
			content: '';
			display: inline-block;
			width: 100%;
		}
	}

	/* bottom middle align */
	footer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;

		text-align: center;
	}

	.page-indicator {
		color: @color-white;
		background: @color-transparent-black;
		height: 2em;
	}

	.page-indicator {
		display: inline-block;
		width: 10ch;

		border-radius: 4px;
		border: 0;

		line-height: 2em;
		text-align:center;
	}
}
