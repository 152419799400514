@import '~go-styles/src/_/variables';

bg-splitter {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;

	.bg-pane,
	bg-pane-left,
	bg-pane-right,
	bg-handle {
		position: absolute;
		display: block;
	}

	bg-handle.disabled {
		pointer-events: none;
	}

	bg-pane-left,
	bg-pane-right,
	.bg-pane {
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		overflow: hidden;
	}

	bg-handle .handle-grip {
		height: 36px;
		width: 18px;
		background-color: @color-white;
		border: 1px solid @color-grey2;
		display: flex;
		z-index: 4;
		position: relative;
		align-items: center;
		border-radius: 2px;
		user-select: none;

		&[aria-grabbed="true"] {
			background-color: @color-light-grey;
		}
	}

	/* Landscape orientation */
	&.horizontal {
		> bg-handle {
			width: 5px;
			cursor: col-resize;
			left: 50%;
			background-color: @color-black;
			.handle-grip {
				left: -5px;
				top: calc(50% - 18px);
			}
		}
	}

	/* Portrait orientation */
	&.vertical {
		> bg-handle {
			height: 5px;
			cursor: ns-resize;
			background-color: @color-black;
			top: 50%;
			.handle-grip {
				left: calc(50% - 18px);
				top: -17px;
				transform: rotate(90deg);
			}
		}
	}
}
