@import 'variables';

/** FORM STYLES */
@default-border-radius: 5px;
@default-input-height: 40px;

form {
	input {
		margin-bottom: 0;
	}

	.form-group {
		margin-bottom: 0px;
	}
}

form.adjacent .control-label {
	float: left;
	width: auto;
	text-align: left;
}
form.adjacent .controls {
	margin-left: 120px;
}

// Modify default .form-control styles
.form-control {
	border: 1px solid var(--color-form-border);
	border-radius: 2px;
	background-color: @color-white;
	color: @color-darker-grey-font;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
	background-color: @color-white
}

.goreact-label {
	display: block;
	font-weight: normal;
	font-size: 14px;
}

.mat-form-field {
	font-family: proxima-nova, "Open Sans", sans-serif;
}

.mat-form-field-label {
	color: @color-off-gray;
}

// to style like mat-form-field
md-input-container {
	width: 100%;
	padding: 0;
	margin: 0;

	&.medium-height {
		margin-top: 15px;
	}

	&.extra-height {
		margin-top: 25px;
	}

	&.readonly {
		margin-left: -8px;

		&.md-input-has-value {
			label {
				color: @color-off-gray;

				&:not(.md-no-float) {
					transform: translate3d(6px, 17px, 0);
				}
			}
		}

		&.md-input-focused {
			.input-outline, .textarea-outline {
				border: none;
			}
		}

		.input-outline {
			border: none;
		}
	}

	label {
		text-decoration: none;

		&:not(.md-no-float):not(.md-container-ignore) {
			transform: translate3d(12px, 35px, 0);
		}
	}

	&.md-input-has-value {
		label {
			color: @color-darker-grey-font;

			&:not(.md-no-float) {
				transform: translate3d(8px, 17px, 0);
				width: auto;
				background: @color-white;
				z-index: 3;
			}
		}
	}

	&.md-input-focused {
		// use box-shadow instead of border to avoid shifting
		.input-outline, .textarea-outline {
			box-shadow: 0 0 0 1.5px @color-darker-grey-font;
			border-color: transparent;

			.md-input {
				padding-bottom: 1px;
			}
		}

		label {
			color: @color-off-gray !important;

			&:not(.md-no-float) {
				transform: translate3d(8px, 17px, 0);
				width: auto;
				background: @color-white;
				z-index: 3;
			}
		}
	}

	&.md-input-invalid, &.ng-invalid {
		.input-outline, .textarea-outline {
			border: 1.5px solid @color-primary-error;

			.md-input {
				padding-bottom: 1px;
			}
		}
	}

	.input-outline {
		display: flex;
		height: @default-input-height;
		border: 1px solid @color-input-outline;
		border-radius: @default-border-radius;

		input {
			appearance: none;
			background: transparent;
			color: @color-darker-grey-font;
			border: none;
			border-radius: @default-border-radius;
			outline: none;
			padding-left: 6px;
			margin: 11px 6px;
			height: 14px;
			width: 100%;
			max-width: 100%;
			text-align: inherit;
			box-sizing: content-box;
			box-shadow: none;
			line-height: normal;

			&.ng-invalid {
				border: none !important;
			}

			&:focus {
				box-shadow: none !important;
			}

			&:focus-visible {
				outline: none !important;
			}
		}

		datetimepicker.ng-touched.ng-invalid input {
			border: none !important;
		}
	}

	.textarea-outline {
		display: flex;
		border: 1px solid @color-input-outline;
		border-radius: @default-border-radius;

		.md-resize-wrapper {
			width: 100%;
		}

		textarea {
			appearance: none;
			background: transparent;
			color: @color-darker-grey-font;
			border: none;
			outline: none;
			padding: 8px;
			width: 100%;
			max-width: 100%;
			box-shadow: none;

			&.ng-invalid {
				border: none !important;
			}

			&:focus {
				box-shadow: none !important;
			}

			&:focus-visible {
				outline: none !important;
			}
		}
	}
}

.dropdown-label {
	width: fit-content;
	color: @color-off-gray;
	transform: translate3d(6px, 16px, 0);
	padding: 0px 4px;
	z-index: 4;
	position: relative;
	background: @color-white;
}

.search-bar {
	.input-group {
		display: flex;
		border: 1px solid @color-silver;
		border-radius: 38px;
		height: 30px;

		.search-txt {
			display: flex;
			align-items: center;
			padding: 0px 15px;
		}

		input {
			border: none;
			border-left: 1px solid @color-silver;
			height: auto;
		}

		.input-group-addon {
			background-color: unset;
			border: none;
			border-radius: 38px;
			padding: 7px 30px 7px 15px;
		}

		.search-clear {
			display: flex;
			align-items: center;

			button {
				padding: 0;
				font-size: 20px;
			}
		}
	}
}
