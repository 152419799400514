@import '~go-styles/src/_/variables';

course-editor {
	display: block;
	position: relative;
	text-align: left;
	white-space: normal;

	.no-license-text, .free-trial-text {
		padding-bottom: 20px;
		padding-top: -10px;
	}

	.date-section section {
		display: inline-block;
		flex: 1;
		+ section {
			margin-left: 15px;
		}
	}

	.date-section {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0px !important;
	}

	.course-payment {
		display: flex;
		justify-content: center;

		.payment-radio-group {
			width: 100%;
			display: flex;
			justify-content: flex-start;
		}

		.radio {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 15px;

				// Fight against STUPID awesome bootstrap poo scrape
				&::before {
					width: 0;
					height: 0;
					border-color: transparent;
				}
			}

			input[type="radio"] {
				// Fight against STUPID awesome bootstrap poo scrape
				opacity: 1;
			}
		}
	}

	.student-only {
		border: 1px solid @color-light-grey;
		padding: 10px 15px;
		background-color: @color-white;
		width: 100%;
		font-weight: normal;
	}

	.student-only-container {
		width: 100%;
	}

  	.billing-required-none-available {
	  margin-top: 20px;
	}

	em {
		color: @color-primary-error;
		font-style: italic;
		&::before {
			content: '\2014\a0'; // emdash followed by nbsp
			color: @color-darker-grey-font;
			font-weight: normal;
		}
	}
}

@media (max-width: @screen-xs-min) {
	course-editor {
		.date-section {
			display: block;
			section {
				display: block;
				+ section {
					margin-left: 0;
				}
			}
		}
	}
}
