@import './variables.less';

.goreact-modal {
	@border-radius: 10px;

	&.modal.in {
		.modal-dialog {
			transform: translateY(-50%);
		}
	}

	.modal-dialog {
		width: 500px;
		margin: 0 auto;
		position: absolute;
		border-radius: @border-radius;
		left: 0;
		right:0;
		top: 50%;
		transform: translateY(-50%);
		max-height: 100%;

		@media (max-width: 525px) {
			width: 100%;
			margin: 0;
		}
	}

	.modal-content {
		border: none;
		border-radius: @border-radius;
		box-shadow: 0px 2px 10px 0 rgba(0,0,0,.25) !important;
	}

	.goreact-modal-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: @color-white;
		border-top-left-radius: @border-radius;
		border-top-right-radius: @border-radius;

		.modal-title {
			font-weight: bold;
			font-size: 18px;
			color: @color-darker-grey-font;
		}

		.close-btn {
			background: transparent;
			border: none;
			font-size: 1.3em;
		}
	}

	.goreact-modal-header {
		padding: 11px 17px 10px 27px;
	}

	.goreact-modal-footer {
		padding: 10px 25px 20px 27px;
	}

	.goreact-modal-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		// Spacing on footer buttons
		.goreact-btn + .goreact-btn,
		.tooltip-wrap + .goreact-btn,
		.goreact-btn + .tooltip-wrap,
		.tooltip-wrap + .tooltip-wrap {
			margin-left: 10px;
		}

		@media (max-width: @screen-xs-min) {
			flex-direction: column;
			*:nth-child(1) {
				margin-bottom: 5px;
			}
			* + * {
				margin-bottom: 5px;
				margin-top: 5px;
				margin-left: initial !important;
				margin-right: initial !important;
			}
			.goreact-btn {
				width: 100%;
			}
			.primary-btn {
				order: 3;
			}
			.cancel-btn { order: 2; }
			.troubleshooting-link {
				order: 1;
				margin: unset;
			}

		}
	}

	.goreact-modal-body {
		padding: 15px 25px;
		border-bottom-left-radius: @border-radius;
		border-bottom-right-radius: @border-radius;
	}
}
