.rb-checkbox {

	.ficon-square-o {
		min-width: 15px;
		color: @color-grey;
		vertical-align: middle;
		display: inline-block;
	}

	.checkbox {
		margin-top: 0;
		margin-bottom: 0;

		> label {
			font-weight: bold;
		}

		input[type="checkbox"]:disabled + label {
			opacity: 1;
		}
	}

	&.rb-edit {

		.checkbox {
			> label {
				display: block;
				margin-right: 120px;

				&:before {
					margin-top: 3px;
				}
			}
		}

		.rb-label {
			display: block;
			min-width: 300px;
		}

		.rb-chkpoints-input {
			width: 50px;
			text-align: center;
			display: inline-block;
		}
	}

	&.rb-live {
		.rb-label {
			display: inline-block;
		}
	}

	&.rb-print {
		.rb-label {
			display: inline-block;
		}
	}
}
