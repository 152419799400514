go-attachment {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;

	media-thumbnail {
		width: 45px;
	}

	.title {
		margin: 0 8px;
		flex: 1;
		color: @color-darker-grey-font;
	}

	.action-buttons {
		margin-left: auto;
		min-width: 50px;
	}

	.edit-attachment-btn {
		color: var(--color-secondary);
	}

	.remove-attachment-btn {
		color: var(--color-primary);
	}
}
