/* Styles for Portrait screen */
@media (max-width: 640px) {
	multi-media-display {
		#stimulus-response-splitter {
			.bg-pane-right {
				display: block !important;
			}
		}
	}
}

/** Feedback only mode */
&.feedback-only-mode {
	bg-splitter > bg-handle {
		display: none;
	}

	#left-section,
	.bg-pane-left {
		display: none;
	}
	#right-section,
	.bg-pane-right {
		display: block;
		top: 0 !important;
		bottom: 0 !important;
		height: auto !important;
		width: auto !important;
		left: 0 !important;
	}
}
