@import '~go-styles/src/_/variables';

session-activity-selector {
	.activity-mover.activity-mover--inline {
		width: 250px;
		margin-top: -16px;

		@media (max-width: @screen-collapse-min) {
			width: 200px;
		}

		@media (max-width: @screen-xs-min) {
			width: 150px;
		}

		label {
			background-color: @color-off-white;
		}

		select {
			background-color: @color-off-white;
		}
	}
}
