@import 'break/break';
@import 'category/category';
@import 'checkbox/checkbox';
@import 'numeric/numeric';
@import 'points/points';
@import 'qualitative/qualitative';
@import 'scale/scale';
@import 'text/text';

rubric-element {
	display: block;
	position: relative;
	@color-bg-points: @color-list-item-hover;

	.rb-element {
		position: relative;
		padding: 15px 10px;
		border-top: 1px solid @color-xlight-grey;

		.rb-title {
			margin-right: 120px;
		}
	}

	.rb-category {
		margin-top: 30px;
	}

	&:first-child {
		.rb-category {
			margin-top: 0;
		}
	}

	.rb-max-points {
		position: absolute;
		top: 0;
		right: 0;
		padding: 15px 0 0 0;
		color: @color-emperor;
		font-weight: bold;

		.badge {
			font-size: 14px;
			font-weight: normal;
			background-color: @color-bg-points;
			border: 1px solid @color-xlight-grey2;
			color: @color-emperor;
			border-radius: 2px;
			cursor: text;

			&:hover {
				background-color: @color-bg-points;
			}
		}
	}

	.rubric-option-handle {
		padding: 8px;
		margin-right: 4px;
	}

	.btn-success, .btn-danger {
		i {
			color: @color-white;
		}
	}
}
