/** Notifications ribbon */

#ribbon {
  /*display: none;*/
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
}

.notification .ignore {
  float: right;
  line-height: 28px;
  font-size: 20px;
  font-weight: bold;
  color: @color-black;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.notification a.ignore {
  text-decoration: none;
}
.notification .ignore:hover {
  color: @color-black;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.notification button.ignore {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.notification {
  padding: 0px 12px;
  color: @color-white;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
  height: 32px;

  background: #4096ee;
  background: -moz-linear-gradient(top, #4096ee 0%, #4096ee 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4096ee), color-stop(100%, #4096ee)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #4096ee 0%, #4096ee 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #4096ee 0%, #4096ee 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #4096ee 0%, #4096ee 100%); /* IE10+ */
  background: linear-gradient(to bottom, #4096ee 0%, #4096ee 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#4096ee', endColorstr = '#4096ee', GradientType = 0); /* IE6-8 */

  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
}
.notification.notification-red {
  background: #ee4140;
  background: -moz-linear-gradient(top, #ee4140 0%, #ee4140 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ee4140), color-stop(100%, #ee4140)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ee4140 0%, #ee4140 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ee4140 0%, #ee4140 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ee4140 0%, #ee4140 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ee4140 0%, #ee4140 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ee4140', endColorstr = '#ee4140', GradientType = 0); /* IE6-8 */

  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
}
.notification.notification-yellow {
  background: #fdb900;
  background: -moz-linear-gradient(top, #fdb900 0%, #fdb900 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdb900), color-stop(100%, #fdb900)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #fdb900 0%, #fdb900 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #fdb900 0%, #fdb900 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #fdb900 0%, #fdb900 100%); /* IE10+ */
  background: linear-gradient(to bottom, #fdb900 0%, #fdb900 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#fdb900', endColorstr = '#fdb900', GradientType = 0); /* IE6-8 */

  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
}