
.btn-group.btn-group-secondary .btn {
  border: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: @color-white;
  background-color: var(--color-secondary);
}
.btn-group.open.btn-group-secondary .btn.dropdown-toggle {
  background-color: var(--color-secondary);
}
.btn-group.btn-group-secondary .caret {
  border-top: 4px solid var(--color-secondary);
}
