/** BUTTON DROP DOWN **/
@import 'variables';

.btn-dropdown-rnd .btn:first-child {
	border-radius: 10px 0px 0px 10px!important;
}

.btn-dropdown-rnd .dropdown-toggle {
	border-radius: 0px 10px 10px 0px!important;
}

.btn-dropdown-rnd .dropdown-menu {
	min-width: 130px;
}


// Correct hover/active states for dropdown
uib-dropdown, [uib-dropdown] {
	> button {
		// We use important here to avoid fighting with
		// bootstrap default values
		border-color: var(--color-form-border) !important;
	}
}

[uib-typeahead-popup] {
	& > li.active > a,
	& > li.active > a:focus,
	& > li.active > a:hover {
		background-color: @color-menu-bg-active;
		color: @color-white;
	}

	& > li > a:hover {
		background-color: @color-menu-bg-hover;
	}
}


/** button drop-down **/

.rounded-dropdown .btn.dropdown-toggle {
	border-radius: 10px 10px 10px 10px;
}
