@import '~go-styles/src/_/variables';

go-banner-list {
	width: 100%;
}

go-banner {

	.content {
		display: flex;
		min-height: 50px;
		align-items: center;
		padding: @go-banner-padding-top 15px;
	}

	.message {
		font-size: 14px;
		padding-right: 15px;
	}

	a, .is-dismissable > * {
		cursor: pointer;
	}

	.is-dismissable {
		margin: 0 0 0 auto;
		font-size: 18px;
	}

	a {
		color: inherit;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: underline;

		&:hover, &:visited, &:active, &:link {
			color: inherit;
		}
	}

	.warning, .notice {
		color: @color-black;

		.btn-none {
			color: @color-black;
		}
	}

	.warning {
		background: @go-banner-warning-color;
		color: @color-black;
	}

	.notice {
		background: @go-banner-notice-color;
		color: @color-black;
	}

	.informational {
		background: @go-banner-informational-color;

		a {
			color: @color-black;
		}

		&:has(#update-banner) {
			button.is-dismissable {
				color: @color-black;
			}
		}
	}

	.warning, .notice {
		a:hover {
			color: @color-black;
		}
	}
}

