@import '~go-styles/src/_/variables';

@panel-width: 230px;
@category-indent: 15px;
@canvas-min-height: 200px;
@scale-option-width: 250px;

.rubric {
  position: relative;

  &,
  > .rb-content {
    height: 100%;
  }

  .error-message {
    color: @color-primary;
    font-size: 12px;
    padding-left: 4px;
  }

  .center {
	text-align: center;
	}

	.rb-print-total-score {
		display: none;
		padding: 15px 10px;
		border-top: 1px solid @color-xlight-grey;
		align-items: center;

		.points {
			flex: 1;
			font-size: 1.2em;
			font-weight: 500;
		}
	}

	.rb-points-badge {
		@color-bg-points: @color-list-item-hover;

		float: right;
		font-size: 14px;
		font-weight: normal;
		background-color: @color-bg-points;
		border: 1px solid @color-xlight-grey2;
		color: @color-emperor;
		border-radius: 2px;
		cursor: text;

		&.positive {
			background-color: @color-bg-points;
		}

		&.negative {
			background-color: @color-bg-points;
		}

		// for some reason !important is required to get these elements to print properly
		@media print {
			color: @color-emperor!important;
			background-color: @color-bg-points !important;
		}
	}

	@media print {
		.rb-print-total-score {
			display: flex;
		}
	}

  @import 'panel/panel';
  @import 'canvas/canvas';
  @import 'element/element';
  @import 'widget/widget';
}

@import 'rubric-element-with-handle-wrapper/style.less';
