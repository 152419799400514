@import '~go-styles/src/_/variables';

.side-panel.modal.session-editor-panel {
	@size: 450px;

	// Have to manually set this or else the ngx confirm dialog shows up behind this
	~ .cdk-overlay-container{
		z-index: 1060 !important;
	}

	.side-panel-container {
		max-width: 100%;
	}

	.side-panel-body .tab-content {
		margin-top: 30px;

		session-editor {
			flex: 1;
		}

		.subheader {
			margin-bottom: 10px;
			font-weight: bold;
			text-transform: uppercase;
			position: relative;
		}

		.table-headers {
			display: flex;
			padding: 10px 0;
		}

		.left-header, .user-name {
			width: 144px;
			margin-right: 10px;
		}

		.right-header, .user-email {
			width: 186px;
		}

		.guest-name-header {
			margin-right: 50px;
		}

		.presenter-list .user-email {
			width: 226px;
		}

		.pending-guest-list .user-email {
			width: 340px;
		}

		.user-name, .user-email {
			display: inline-block;
			margin-right: 10px;
			word-break: break-word;
		}

		.list-item {
			border-top: 1px solid @color-xlight-grey2;
			padding: 20px 0;
			min-height: 60px;
			display: flex;
			position: relative;
		}

		.border-bottom {
			border-bottom: 1px solid @color-xlight-grey2;
		}

		.presenter-list, .guest-list, .removed-guest-list, .pending-guest-list {
			margin-bottom: 0;
			list-style-type: none;
			border-bottom: 1px solid @color-xlight-grey2;
			padding: 0;
		}

		.guest-reviewer-section, .removed-guest-list {
			margin-bottom: 30px;
		}

		.add-presenter-btn {
			margin: 20px 0 30px 0;
		}

		.add-presenter-row {
			height: 59px;
		}

		go-typeahead-select > uib-dropdown .form-group {
			width: 154px;
		}

		.add-presenter-row input,
		go-typeahead-select > uib-dropdown .selected-item-template-container {
			width: 154px;
			height: 58px;
			border: 2px solid @color-darker-grey-font;
			border-radius: 5px;
		}

		go-typeahead-select > uib-dropdown li {
			padding:10px;
			height: 58px;
		}

		.right-button button {
			width: 20px;
			height: 20px;
			padding: 0;
		}

		.empty-state-margin {
			margin-bottom: 10px;
		}

		.side-panel-footer .video-details {
			margin-right: auto;
		}

		.remove-button {
			position: absolute;
			right: 0px;
		}

		.guest-type-icon {
			display: inline-block;
			margin-right: 20px;
			width: 20px;

			.ficon-app-email {
				font-size: 16px;
			}

			.ficon-app-link {
				font-size: 20px;
				margin: -4px 0 0 -2px;
			}
		}

		.guest-reviewers-subheader {
			display: flex;
			justify-content: space-between;

			button {
				height: 20px;
				width: 20px;
				padding: 0;
			}
		}

		.expire-button {
			i {
				font-size: 20px;
				position: relative;
				top: 2px;
			}
		}

		.empty-state-text {
			margin-top: 18px;
		}
	}

	@media (min-width: @screen-sm-min) {
		.modal-dialog {
			width: @size;
		}
	}
}
