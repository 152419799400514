@import '~go-styles/src/_/variables';

/** Tree view */

.tree-view {
	overflow: visible;
	margin: 0;
	padding: 0;
	list-style: none;
	display: block;

	/** highlighting - fade in / out */
	.before-highlight .feedback-item {
		transition: background-color 1.5s linear!important;
	}

	.highlight .feedback-item {
		background-color: @color-list-item-active!important;
	}

	.list-item {
		color: @color-dark-grey;

		&:focus {
			outline-width: 0;
		}
	}
}
