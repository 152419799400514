@font-face {
    font-family: 'fontawesome';
    src: url('fonts/fontawesome-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'goreact-custom2';
	src: url('fonts/goreact-custom2.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/*  Font Awesome styles
    ------------------------------------------------------- */
[class^="ficon-"],
[class*=" ficon-"] {
    font-family: fontawesome !important;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-style: normal;
    text-decoration: inherit;
    font-size: 1.1em;
    -webkit-font-smoothing: antialiased;
}

[class^="ficon-"]:before,
[class*=" ficon-"]:before {
    text-decoration: inherit;
    display: inline-block;
    speak: none;
}
/* makes sure icons active on rollover in links */
a [class^="ficon-"],
a [class*=" ficon-"] {
    display: inline-block;
}
/* makes the font normal size relative to the icon container */
.ficon-small:before {
    font-size: 0.9em !important;
}
/* makes the font 33% larger relative to the icon container */
.ficon-large:before {
    font-size: 1.25em !important;
}
.ficon-2x {
    font-size: 2em;
}
.ficon-2x.ficon-border {
    border-width: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.ficon-3x {
    font-size: 3em;
}
.ficon-3x.ficon-border {
    border-width: 3px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.ficon-4x {
    font-size: 4em;
}
.ficon-4x.ficon-border {
    border-width: 4px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
