@import '~go-styles/src/_/variables';

.popover-container {
	display: inline-flex;
}

.popover-content {
	width: 270px;
}

@media (max-width: 350px) {
	.popover-content {
		width: 190px;
	}
}
