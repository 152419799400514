slide-deck-instructions {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: @color-white;
  background-color: rgba(0,0,0,0.95);
  min-width: 400px;// This is so that arrow navigation will not overlap text
  max-height: none;

  > form {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 310px;
	overflow: hidden;
	position: relative;
	// Needs to be position over pdf and aligned with arrow navigation
	top: -14%;

	> header {
	  display: flex;
	  align-items: center;
	  margin-bottom: 20px;

	  .title {
		display: flex;
		align-items: center;
		color: @color-white;

		:first-child {
		  margin-right: 10px;
		}
	  }
	}

	> section {
	  margin-bottom: 5px;
	  margin-left: 10px;

	  p {
		color: @color-white;
	  }
	}

	> footer {
	  display: flex;
	  align-items: center;
	}
  }

  .media-processing-message {
	font-weight: 300;
	margin-bottom: 0;
  }

  .media-processing-failed-message {
	> span {
	  color: @color-red;
	}
  }

  button.outlined-button {
	margin-left: 0;
	border: 1px solid grey;
	text-transform: none;
	padding: 0 3em;
	font-weight: normal;
	z-index: 1;
  }

	.message-margin {
		margin-bottom: 10px;
	}

  // Arrow navigation needs to be directly over pdf arrow navigation
  > .arrow-navigation {
	position: absolute;
	width: inherit;
	transform: translateY(-50%);
	top: 50%;
	margin-top: -75px;
	left: 0;

	.btn {
	  margin: 10px;
	  opacity: 0.6;
	  cursor: default;
	}
  }

  @media (max-width: @screen-xs-min) {
	  .media-processing-complete,
	  .media-processing-message {
		width: calc(100vw - 180px);
		text-align: center;
	  }

	  > form {
		  top: 0px;

		  > header {
			  .title {
				  font-size: 20px;
			  }
		  }
	  }
  }
}

// When the user hovers over the
// feedback session, animate the arrows.
&:hover {
  slide-deck-instructions {
	.arrow-navigation {
	  .btn {
		&:first-child {
		  animation: arrow-left-bounce-in 1s ease-in-out 0s, arrow-pulsate 1s ease-in-out 4 1.3s;
		}
		&:last-child {
		  animation: arrow-right-bounce-in 1s ease-in-out 0s, arrow-pulsate 1s ease-in-out 4 1.3s;
		}
	  }
	}
  }
}

@keyframes arrow-pulsate {
  0% {transform: scale(0.9, 0.9);}
  50% {transform: scale(1.1, 1.1); border-color: rgba(255,255,255, 0.8)}
  100% {transform: scale(1, 1);}
}

@keyframes arrow-left-bounce-in {
  0%, 20%, 50%, 80%, 100% {
	transform: translateX(0);
  }
  40% {
	transform: translateX(30px);
  }
  60% {
	transform: translateX(15px);
  }
}

@keyframes arrow-right-bounce-in {
  0%, 20%, 50%, 80%, 100% {
	transform: translateX(0);
  }
  40% {
	transform: translateX(-30px);
  }
  60% {
	transform: translateX(-15px);
  }
}
