@import '~go-styles/src/_/variables';

@slide-toggle-min-width: clamp(30px, 1.875rem, 70px);
@slide-toggle-height-width-ratio: 14/30;

slide-toggle-control {
	width: @slide-toggle-min-width;
	min-width: @slide-toggle-min-width;
	box-sizing: border-box;
	position: relative;
	display: inline-table;
	border-radius: 360px;
	background-color: @color-off-gray;
	transition: background-color 250ms linear;
	cursor: pointer;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.65;
	}

	&.inactive {
		.inactive-icon {
			opacity: 1;
			transition: opacity 250ms ease-in-out;
		}

		slide-toggle-thumb {
			left: -1px;
		}
	}

	&.active {
		background-color: var(--color-secondary);

		.active-icon {
			opacity: 1;
			transition: opacity 250ms ease-in-out;
		}

		// Position the slide thumb on the right end
		slide-toggle-thumb {
			left: calc(100% - 14px);
		}
	}

	&.sliding {
		.icon-container {
			display: none;
		}
	}

	.slide-toggle-container {
		display: flex;
		flex-direction: row;
		margin: 0;
		border-radius: inherit;
		height: 10px;
		cursor: inherit;
	}

	.slide-toggle-input {
		position: absolute;
		overflow: hidden;
		height: 0px;
		width: 0px;
		left: -10000px;
		padding: 0;
		border: 0;
		clip: rect(0 0 0 0);
		appearance: none;
	}
}
