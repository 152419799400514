@import '~go-styles/src/_/variables';

.comment-caption {

	/** Feedback tag (should be nearly identical to tag editor tag) */
	.comment-tag {
		@tag-size: 25px;
		position: relative;
		display: inline-block;
		border-collapse: separate;
		white-space: nowrap;
		vertical-align: middle;
		cursor: pointer;
		margin-right: 5px;
	}
}
