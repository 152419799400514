@import '~go-styles/src/_/variables';

.rb-audio {
  &.rb-widget {
	font-size: 13pt;
	font-weight: bold;
	text-align: left;
	cursor: pointer;

	.rb-title {
	  font-size: 16px;
	  font-weight: bold;
	  background: @color-med-grey;
	  color: @color-white;
	  padding: 8px 12px;

	  &:hover,
	  &:active {
		background-color: #bababa;
	  }
	}

	i {
	  margin-right: 8px;
	}
  }
}
