// when creating a new folder in the ai markers dialog in the activity editor inside the library
.modal-open .create-course-modal:has(~ .library-collection-viewer) {
	z-index: 1081 !important;
}

// when creating a new folder in the ai markers dialog in the activity editor outside the library
.modal-open .create-course-modal:has(~ .activity-editor-panel) {
	z-index: 1061 !important;
}

.modal.create-course-modal {
	.modal-dialog {
		width: auto;
		max-width: 580px;

		.goreact-modal-footer {
			.add-dates-form {
				flex: 1;
				margin-bottom: 0px;
			}
		}
	}
}
