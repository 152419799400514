slide-deck-item-renderer {
  display: inline;

  header {
	text-align: center;
	font-size: 16px;
	margin-bottom: 4px;
	color: white;
  }

  footer {
	margin-top: 4px;
	text-align: center;
	color: white;
	font-size: 13px;
  }
}
