@import '~go-styles/src/_/variables';

feedback-display-panel {
	.panel-content {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
		overflow: auto;

		@media (max-width: @screen-collapse-min) {
			display: flex;
			flex-direction: column;
			align-items: center;
			border-bottom: 1px solid @color-light-grey2;

			.mobile-handle-spacer {
				width: 100%;
				height: 25px;
			}
		}

		ul {
			display: grid;
			justify-content: center;
			min-width: 75px;

			@media (max-width: @screen-collapse-min) {
				grid-auto-flow: column;
			}

			li {
				border-left: 5px solid transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				height: 73px;
				min-width: inherit;
				border-bottom: 1px solid #ddd;

				@media (max-width: @screen-collapse-min) {
					border-right: 1px solid #ddd;
					border-left: none;
					border-bottom: none;
					height: 40px;
					margin-bottom: 7px;

					&:last-child {
						border-right: none;
					}
				}

				&:hover {
					border-left: 5px solid @color-light-grey2;

					@media (max-width: @screen-collapse-min) {
						border-left: none;

						::after {
							content: "";
							position: absolute;
							bottom: -6px;
							left: 50%;
							width: 80%;
							height: 4px;
							background-color: @color-light-grey2;
							transform: translateX(-50%);
						}
					}
				}

				button {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					position: relative;
					top: 3.5px;

					&:hover, &:active {
						// Important needed for video sharing
						border-bottom: none !important;
					}


					.tab-title {
						font-size: 0.85em;
						text-transform: capitalize;
						color: @color-off-gray;
					}

					.tab-icon {
						font-size: clamp(19.6px, 1.225rem, 39.2px);
						i {
							color: @color-off-gray;
						}

						@media (max-width: @screen-collapse-min) {
							font-size: 16px;
						}
					}
				}

				&.active {
					font-weight: bold;
					border-left: 5px solid @color-tertiary;

					@media (max-width: @screen-collapse-min) {
						border-left: none;

						::after {
							content: "";
							position: absolute;
							bottom: -6px;
							left: 50%;
							width: 80%;
							height: 4px;
							background-color: @color-tertiary;
							transform: translateX(-50%);
						}

						button {
							height: 35px;
							width: 100%;
						}
					}

					.feedback-kind-btn {
						border-bottom: none !important;
					}

					button .tab-title, button .tab-icon i {
						color: @color-dark;
					}
				}

				+li {
					margin-left: 0px;
				}

				&.comment-tab {
					.feedback-kind-btn {
						margin-top: 5px;
					}
				}

				.comment-indicator {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 25px;
					width: 25px;
					position: absolute;
					pointer-events: none;
					font-size: 11px;
					background: var(--color-secondary);
					top: 3px;
					right: 8%;
					color: @color-white;
					border: 2px solid white;
					border-radius: 25px;
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
					letter-spacing: -0.5px;
					transform-origin: top center;

					@media screen and (max-width: @screen-collapse-min) {
						top: -39%;
						right: 13%;
					}

					&.pulse {
						animation: pulse 0.6s ease-in-out 3;
					}
				}

				@keyframes pulse {
					0% {
						transform: scale(1);
					}
					50% {
						transform: scale(1.5);
					}
					100% {
						transform: scale(1);
					}
				}
			}
		}

		.expand-button-container {
			margin-top: auto;

			.expand-button {
				border: none;
				margin: 20px;
				font-size: 16px;
				border-radius: 5px;
			}

			@media (max-width: @screen-collapse-min) {
				display: none;
			}
		}
	}

	@media (max-width: @screen-collapse-min) {
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		background: @color-white;
	}
}
