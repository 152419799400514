@import '~go-styles/src/_/variables';

.goreact-modal.media-preview, .goreact-modal.media-preview-full {
	z-index: 1081 !important;

	.goreact-modal-body {

		&.full-screen {
			canvas {
				border: none!important; // dont show border on pdf previews
			}

			button.close {
				position: absolute;
				right: 20px;
				top: 20px;
				z-index: 20;
				opacity: 1;
				color: @color-white;
				background-color: rgba(0,0,0,0.5);
				border-radius: 5px;
				border: none;
				padding: 5px 10px;
			}
		}

		form {
			text-align: left;

			.media-url-input {
				margin-bottom: 15px;
			}

			.error {
				background-color: @color-primary-error;
				color: @color-white;
			}

			#media-description-wrap {
				padding-bottom: 11px;
			}
		}
	}

	.goreact-modal-footer {
		display: flex;
		justify-content: space-between;
		padding-bottom: 15px;

		.leftside-footer {
			display: flex;

			.download-btn {
				min-width: 185px;
				line-height: inherit;
			}
		}

		.cancel-btn {
			height: 33px;
		}

		@media (max-width: @screen-xs-min) {
			flex-direction: column;
			padding-top: 7px;

			.leftside-footer {
				width: 100%;

				.download-btn {
					min-width: 100%;
				}
			}

			.rightside-footer {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				height: 43px;

				.cancel-btn {
					margin-bottom: 0;
				}
			}
		}
	}

	.no-preview {
		height: auto;
	}
}

.goreact-modal.media-preview-full {
	.modal-content {
		display: flex;
		flex-direction: column;
	  	background-color: rgba(0, 0, 0, 0.85);
	}

	.no-preview {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		font-size: 3em;
		color: @color-white;

		> .download-btn {
			margin-top: 20px;
		}
	}

	go-pdf-page::before {
		color: @color-white;
	}

	.goreact-modal-header {
		height: 60px;
		padding: 10px;
		color: @color-white;
		opacity: 1;
		display: flex;
		align-items: center;
		background-color: @color-black;
		border: none;

		a {
			padding-top: 0;
			padding-bottom: 0;
			height: 27px;
		}

		i {
			color: @color-white;
		}

		button, a {
			color: @color-white;
			font-size: 1.2em;
		}
	}

	.media-actions {
		font-size: 1.2em;
		flex: 1;
		z-index: 1;
	}

	.media-title {
		font-size: 1.4em;
		margin-right: 25px;
		font-weight: 300;
		color: @color-white;
	}

	@media (max-width: @screen-xs-min) {
		.media-title {
			display: none;
		}
	}

	media-preview {
		flex: 1;
	}

	.goreact-modal-body {
		height: ~'calc(100% - 60px)!important';
	}
}

.modal.media-preview {
	.goreact-modal-body {
		display: flex;
		flex-direction: column;
	}
	.form-container {
		display: flex;
		form {
			width: 100%;

			.no-resize {
				resize: none;
			}
		}
	}

	accessibility-attachments {
		margin-top: 15px;
	}

	media-preview {
		flex: 1;
		display: flex;
		min-height: 200px;
		.media-preview {
			height: auto;
		}
	}

	.uploads {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-top: 1em;

		.help-icon {
			margin-left: 10px;
			display: flex;
			align-content: center;
			justify-content: center;
			flex-direction: column;
			padding: 0; // Override goreact style padding (looks bad inside a button tag)

			@media (max-width: @screen-xs-min) {
				position: absolute;
				left: 4px;
				bottom: 25px;
			}
		}

		uib-dropdown {
			button.dropdown-toggle {
				padding-bottom: 7px;
				.caret {
					margin-left: 8px;
				}
			}
			ul > li {
				cursor: pointer;
			}
			@media (max-width: @screen-xs-min) {
				width: 100%;

				button {
					width: 100%;
				}
			}
		}
	}

	.attachments {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;

		media-thumbnail {
			margin-top: 10px;
			max-width: 120px;
			margin-right: 25px;
		}
	}
}
