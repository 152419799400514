@import 'item-renderer/style';
@import 'slide-deck-instructions/style';
@import 'sync-event-toast/style';

slide-deck-viewer {
  @footer-height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  > section {
	position: relative;
	width: inherit;
	height: inherit;
	color: @color-white;

	> slide-deck-item-renderer {
	  // Center the main canvas vertically / horizontally
	  go-pdf-page {
		canvas {
		  position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%);
		  transform: translateY(-50%);
		}
	  }

	  // Modify main item renderer footer only
	  footer {
		position: absolute;
		bottom: 0;
		left: 50%;
		color: @color-white;
		padding: 5px 10px;
		border-radius: 4px;
		font-size: 16px;
		transform: translateX(-50%);
		margin-bottom: 10px;
		background-color: rgba(0,0,0,0.7);
	  }
	}

	&.show-footer {
	  height: ~"calc(100% - @{footer-height})";
	}

	.arrow-navigation {
	  width: inherit;
	  position: absolute;
	  transform: translateY(-50%);
	  top: 50%;
	  left: 0;

	  .btn {
		margin: 10px;
	  }
	}

	.media-processing {
	  height: 100%;
		margin: 0 20px;

		.media-processing-failed-message {
			font-size: 1.4em;
		}

	  .media-processing-message {
		.ficon-spinner {
		  margin-right: 5px;
		}
	  }
	}
  }

  > footer {
	flex: 1;
	height: @footer-height;
	width: inherit;

	.slide-deck-navigation {
	  display: flex;
	  flex-direction: row;
	  z-index: 1;
	  position: relative;
	}
  }

  .slide-deck-navigation {

    .navigation-item {
      margin: 5px;
      display: flex;
      flex: 50;
      &.left {
		justify-content: flex-start;
        button {
          padding-left: 8px;
        }
      }
      &.right {
		justify-content: flex-end;
        button {
          padding-right: 8px;
        }
      }
      button {
    	max-width: 180px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.5);
		cursor: pointer;
		box-shadow: -9px 10px 10px rgba(0,0,0,0.3);
		&:hover {
		  background-color: rgba(0, 0, 0, 0.7);
		  box-shadow: -9px 10px 10px rgba(0,0,0,0.5);
		  &:disabled {
			background-color: rgba(0, 0, 0, 0.5);
			box-shadow: -9px 10px 10px rgba(0,0,0,0.3);
		  }
		}
		&:disabled {
		  cursor: default;
		}
      }
    }
  }
}
