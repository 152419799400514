@import 'variables';

.ui-select-container {
  margin-bottom: 30px;
  height: @ui-select-height;
  display: flex;
  flex-direction: column;

  // Delay showing options
  &.delay-options {
    .ui-select-choices {
      display: none!important;
    }

    &.open.text-entered {
      .ui-select-choices {
        display: block!important;
      }
    }
  }

  // Overrides in ui-select library
  .ui-select-match,
  .ui-select-toggle {
    height: inherit;
    flex: 1;
    display: flex;
    border-radius: 0;

    &[disabled] {
      .ui-select-toggle {
        &:hover {
          background-color: @color-xlight-grey;
        }
      }
    }
  }

  .ui-select-toggle {
    transition: none;
    padding: 0 0 0 10px;
    color: @color-dark;

    &:hover {
      background-color: @color-white;
    }
  }

  .ui-select-placeholder {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: @ui-select-font-size;
  }

  .ui-select-match-text {
    height: @ui-select-height;
    padding: 0;
    font-size: @ui-select-font-size;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .ui-select-choices-row {
    height: @ui-select-height;
    font-size: @ui-select-font-size;

    .ui-select-choices-row-inner {
      height: inherit;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .ui-select-search {
    height: @ui-select-height;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    width: initial!important;
    flex: 1;
    font-size: @ui-select-font-size;
  }
}
