aspect-ratio-container {
	display: block;
	box-sizing: border-box;
	position: absolute;

	ng-transclude {
		display: block;
		width: 100%;
		height: 100%;
	}
}

@media all and (-ms-high-contrast:none) {
	*::-ms-backdrop, aspect-ratio-container { position: static !important; } /* IE11 */
}
