@import '~go-styles/src/_/variables';
@import '../styles/variables';
@import '../styles/mixins';

feedback-display {
	flex-grow: 1;
	@header-height: 42px;
	@sub-header-height: 36px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	background-color: @color-white;

	.open-ai-markers-button {
		text-decoration: underline;
		cursor: pointer;
	}

	.feedback-display-ai-markers-tour,
	.feedback-display-manage-ai-markers-tour {
		position: sticky;
		padding: 1rem;
		top: 0;
		background: @color-light-grey-blue;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;

		> div {
			display: flex;
			align-items: center;
			gap: 5px;

			p {
				margin: 0;
			}
		}

		.ficon-lightbulb-o {
			color: #EDCB50;
		}

		.close-feedback-display-ai-markers-tour,
		.close-manage-ai-markers-tour {
			opacity: 1;
		}
	}

	.sidebar-toggle {
		display: none;
	}

	.btn:active:focus {
		color: @color-darker-grey-font;
	}

	.accessible-comment {
		opacity: 0;
	}

	// Main header
	> header.main-header {
		background-color: @color-white;
		border-bottom: 1px solid @color-light-grey2;
		display: flex;
		flex: none;

		&.rubric-mode {
			border-bottom: none;
		}

		> :first-child {
			flex: 1;
			padding-left: 1px;
			min-width: 260px;
			white-space: nowrap;

			li {
				position: relative;
				margin-left: 0px;

				button {
					margin: 0 2px;
				}

				&:not(:first-child):before {
					content: "";
					position: absolute;
					top: 5px;
					bottom: 5px;
					border-left: 1px solid @color-light-grey2;
				}
			}

			@media (max-width: @screen-xs-min) {
				min-width: 230px;
			}
		}

		.tab-list-end {
			display: flex;
			position: relative;
			flex-flow: row;
			justify-content: flex-end;

			section {
				&:before {
					content: "";
					position: absolute;
					top: 0px;
					bottom: 0px;
					border-left: 1px solid @color-light-grey2;
				}
			}
		}

		.feedback-kind-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 6px 12px;

			.badge {
				margin-left: 8px;
				top: 0;
			}
		}

		.feedback-kind-btn-ff {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 90px;
			height: 40px;

			> .badge-indicator {
				margin: auto;
			}

			@media (max-width: @screen-xs-min) {
				width: 70px;
			}
		}

		.close {
			height: 40px;
			width: 40px;
			opacity: 1;
		}
	}

	// Sub-header
	.sub-header-title {
		font-size: 14px;
		font-weight: bold;
		margin: 0;
	}

	header.sub-header {
		display: flex;
		align-items: center;
		padding: 0 2px 0 0;
		border-bottom: 1px solid @color-light-grey2;
		border-radius: 0;
		height: 55px;
		background: @color-white;
		justify-content: space-between;

		.sub-header-left {
			display: flex;
			align-items: center;
			margin-left: 15px;
		}

		.action-menu {
			border: none;
		}

		.degraded-container {
			display: flex;
			align-items: center;
			margin-left: auto;

			.degraded-text {
				color: @color-primary;
				@media (max-width: @screen-xs-min) {
					display: none;
				}
			}

			> i.ficon-question-circle {
				padding: 0 5px;
				color: @color-primary;
			}
		}

		uib-dropdown {
			&.feedback-filter.rich-dropdown {
				flex: none;

				button {
					font-weight: bold;

					.category {
						margin-right: 40px;
						width: fit-content;
						min-width: 100px;
						display: block;
					}
				}
			}

			li {
				&.autoplay-menu-item {
					display: block !important;
				}
			}
		}

		.sync-event-comments-btn {
			margin-right: 5px;
			margin-top: 2px;
		}
	}

	[uib-dropdown-menu][role="listbox"] li {
		padding: 2px 5px;
	}

	// Content
	> section.feedback-display-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		background-color: @color-white;
		overflow-x: hidden;
		overflow-y: auto;

		@media (max-width: @screen-xs-min) {
			overflow-x: unset;
			overflow-y: auto;
		}

		&.no-comment-access {
			bottom: 0;
		}

		&.rubric-mode {
			border-top: none;
		}

		&.assignment-info {
			background-color: @color-white;

			.fd-activity-content {
				display: flex;
				flex-direction:column;

				.sub-header {
					justify-content: space-between;

					button.close-feedback-panel {
						opacity: unset;
						padding: 0 10px;
						height: 100%;
					}
				}
			}

			.divider {
				@media (min-width: @screen-xs-min) {
					border-top: 1px solid @color-xlight-grey;
					padding-bottom: 15px;
				}
			}

			.fd-activity-information {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				background-color: @color-white;
				border-bottom: 1px solid @color-light-grey2;
				height: 56px;

				button.close-feedback-panel {
					opacity: unset;
				}
			}

			.fd-activity-instructions {
				padding: 20px;
				margin-bottom: 20px;
			}

			.fd-section {
				padding-bottom: 15px;

				.fd-submission-date {
					font-style: italic;
				}

				&:last-child {
					padding-bottom: 0px;
				}
			}

		}

		&.transcription,
		&.analytics {
			.fd-transcription-content,
			.fd-analytics-content {
				display: flex;
				flex-direction:column;
				flex: 1;
				overflow-y: hidden;
			}

			.fd-transcription-information,
			.fd-analytics-information {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: @color-white;
				border-bottom: 1px solid @color-light-grey2;
				padding-right: 12px;
				height: 56px;
				min-height: 56px;

				button.close-feedback-panel {
					opacity: unset;
				}
			}
		}
	}

	.action-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 5px;

		button.close-feedback-panel {
			opacity: unset;
			padding: 0 10px;
		}

		uib-dropdown {
			li.autoplay-menu-item {
				display: none;
			}

			button.action-menu-item {
				> span {
					height: 23px;
				}

				.action-menu-icon {
					font-size: 20px;
    				line-height: 22px;
					padding-right: 0px;
				}

				span.action-menu-action {
					margin-right: 0px;
					margin-left: 6px;
					white-space: nowrap;

					&.rerun-ai-feedback {
						margin: 0 6px 0 0;
					}
				}
			}
		}
	}

	&.resize-sensor-480 {
		overflow-y: scroll;
		background-color: @color-white;
		display: flex;

		.comment-sub-header {
			.sub-header-left {
				margin-left: 0;

				.sub-header-title {
					display: none;
				}
			}

			.action-bar {
				button.dropdown-toggle, button.close-feedback-panel {
					padding: 0;
				}

				button.close-feedback-panel {
					padding-right: 10px;
				}
			}
		}

		.sidebar-toggle {
			height: 40px;
			display: block;

			button {
				border-right: 1px solid @color-separator;
				opacity: unset;
				padding: 0 10px;
				height: 100%;
			}
		}

		uib-dropdown {
			li {
				&.autoplay-menu-item {
					display: block !important;

					slide-toggle {
						height: 23px;
					}
				}
			}

			button.action-menu-item {
				.action-menu-icon {
					width: 38px;
				}
			}

			feedback-filter.rich-dropdown {
				button {
					&.btn {
						padding: 5px 10px;
					}

					> span {
						max-width: 100%;
					}
				}
			}
		}
	}

	.hide-feedback-tree {
		display: none;
	}

	.no-comments {
		overflow-y: hidden !important;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 300px;

		ngx-zero-state {
			display: flex;
			flex-flow: column;
			padding-left: 20px;
			padding-top: 20px;
			max-width: 330px;
			align-items: center;
			justify-content: center;
			font-size: 24px;

			.heading {
				font-size: 24px;
			}

			.subtext {
				width: auto;
			}

			.graphic-bottom {
				padding-top: 30px;
			}
		}

		.add-ai-markers-comment-zero-state {
			[zero-state-subtext] {
				text-align: center;
				margin-bottom: 24px;
			}
		}
	}

	.read-only-text-container {
		padding: 1rem;
		background: @color-light-red;

		.read-only-text {
			margin: 0;
		}
	}
}
