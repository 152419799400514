/*  USER WIDGET  */
.user-widget {
	display: inline-block;

	.btn {
		font-size: 13px;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.dropdown-menu {
		font-size: 13px;
		overflow: auto;
		max-height: calc(100vh - 50px);

		a {
			cursor: pointer;
		}
	}

	.mask {
		color: @color-red;
	}
}
