@import 'record-view/style';
@import 'playback-view/style';
@import 'live-view/style';

session-media-display {
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;

	> .inner-display {
		display: flex;
		flex: 1;
		min-width: 0;
	}
}
