@import '~go-styles/src/_/variables';

@slider-height: 8px;
@slider-background: @color-xlight-grey;

feedback-tree-item-time-slider {
	// Have the controls inline and centered vertically
	display: flex;
	flex-direction: row;
	align-items: center;

	.ui-stepper {
		// Make the +/- buttons on top of each other
		display: flex;
		flex-direction: column;

		// Add some space between the +/- buttons and the time-slider
		margin-right: 8px;

		// Decrease the size
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 2em;
			width: 2em;
			font-size: 0.85em;
			line-height: 1;
			color: @color-dark-grey !important;
			background-color: @color-white !important;
			box-shadow: 1px 1px 4px 0 rgba(0,0,0,.25);

			&:first-of-type {
				margin-bottom: .5em;
			}

			&:hover {
				background-color: @color-xlight-grey !important;
			}
		}
	}

	.time-slider {
		// Grow the width to entire container
		flex: 1;
		// Shrink the bar's thickness
		height: @slider-height;
		// Darken vendor grey border
		border-color: @color-dark;
		// We don't want vendor inset 3d progress bar
		box-shadow: none;

		// Set the color of the bar
		background-color: @color-xlight-grey;

		// We want a pointer so people can see you can jump to a location
		&:not([disabled]) {
			cursor: pointer;
		}

		// Style the handles
		.noUi-handle {
			background-color: @color-white;
			box-shadow: 0px 1px 3px rgba(255, 255, 255, 30%);
			border-color: @color-med-grey;

			// Normally the handles are pretty square (34px x 28px = 17/14 = 1.214 ratio)
			// But we want a more retangular handle (8/15 = 0.5333 ratio)
			@ratio: ((34/28) / (8/15));

			// And we've also changed the scrubber height from the default 18px
			@scale: (@scrubber-height/18px);

			// So first scale the width to the fraction of the scrubber height
			width: @scale * 34px;
			// And right (normally -17px with a 1px border)
			right: @scale * -16px;
			// Now scale the height, replacing their ratio with our ratio
			@height: @scale * @ratio * 28px;
			height: @height;
			// Top is half of the part of the handle that sticks out from the scrubber
			top: -(@height - @scrubber-height) / 2;

			// The handle normally has two notches (vertical bars) we need to hide
			&:before, &:after {
				display: none;
			}

			// Make the cursor go to the grab/grabbing state when clicked and dragged
			cursor: grab;
			&:active {
				cursor: grabbing;
			}

			.noUi-tooltip {
				// Right now it's too big and bulky. We're going to shrink it down and increase the aspect ratio.
				// This needs to be text size aware, so everything should be measured in em
				width: 8ch;
				height: 1.5em;
				box-sizing: content-box;
				padding: 0;
				border-radius: 0.2em;

				// The font is too big. Shrink it down
				font-size: 0.8em;

				// Invert the contrast
				color: @color-white;
				background: @color-dark;

				// Semi-transparent
				opacity: 0.85;

				// Give it a halo
				box-shadow: 1px 1px 4px 1px @color-med-grey;

				// Position it at the bottom
				bottom: unset;
				top: 120%;
			}
		}

		// Style the disabled original-value handle
		[disabled] {
			// Ensure that it shows up behind non-disabled ones
			// nouislider sets z-index directly on the element style so we have to make it !important
			z-index: -2 !important;

			.noUi-handle {
				// Make it partially transparent
				opacity: 0.6;
				// And the handle red
				background-color: @color-red;
			}
		}
	}
}
