.modal.user-activity-history {
	.modal-dialog {
		width: auto;
		height: 100%;
		max-height: calc(100% - (2 * @modalTopMargin));
		margin: 0 auto;

		@media (min-width: @maxModalWidth) {
			width: 820px;
			height: 700px;
		}

		.modal-content {
			display: flex;
			flex-direction: column;
			height: 100%;

			.goreact-modal-body {
				display: flex;
				flex: 1;
				overflow: auto;

				.container-fluid {
					width: 100%;
				}
			}
		}
	}

  .nav-tabs {
    border: none;
  }

  .main-nav {
    margin-bottom: 8px;
  }

  .right-nav {
    display: flex;
    white-space: nowrap;

    @media (min-width: 768px) {
      float: right;
    }

		input {
			min-width: 160px;
		}

    > .secondary-btn {
      margin-right: 5px;
    }
  }

  user-activity-history {
    height: 240px;
    margin-bottom: 10px;
  }

  .recent-uploads {
    table {
      table-layout: fixed;

      td {
        word-wrap:break-word;
      }
    }
  }

  td pre {
    max-width: 380px;
    overflow: hidden;
  }
}
