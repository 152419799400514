@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/mixins';

session-editor {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.session-editor-group {
		flex: 1;
		width: 100%;

		&:not(:only-child) {
			margin-right: 30px;
		}
	}

	.no-users-available {
		display: flex;
		align-items: flex-end;
		height: 40px;
		padding-left: 8px;
	}

	.presenter-chips-container {
		height: auto;
		min-height: 40px; // Match other inputs
		border-radius: 5px;
		padding: 6px 12px;

		md-chips , .md-chips{
			font-family: proxima-nova, "Open Sans", sans-serif;
			min-height: 32px;
		}

		.md-chips {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 0;
			font-size: 14px;

			&,
			&.md-chips-focused {
				box-shadow: none;
			}

			.md-chip-input-container {
				margin: 0;

				md-autocomplete-wrap {
					&,
					input {
						height: inherit;
					}
				}

				md-autocomplete {
					height: inherit;
				}
			}

			md-chip {
				height: 24px;
				line-height: 24px;
				margin: 2px 5px;
				margin-left: 0;
				float: none;
				background-color: @color-xlight-grey2;
				color: @color-emperor;

				.md-chip-remove {
					top: -4px;
				}

				md-chip-template,
				.md-chip-content,
				.md-contact-name,
				.md-chip-remove,
				.md-chip-remove-container {
					height: inherit;
				}

				.md-contact-name {
					padding: 0 5px;
					font-size: 12px;
				}

				.md-chip-remove md-icon {
					color: rgba(0,0,0,0.54);
					height: 15px;
					width: 15px;
				}
			}
		}
	}

	.score-section {
		.score-input {
			width: 60px;
		}
	}

	.slide-deck-media,
	.stimulus-media {
		margin-top: 20px;

		.goreact-label {
			margin-left: 10px;
		}

		media-thumbnail {
			max-width: 120px;
		}
	}

	.max-presenter-count-alert {
		font-size: 12px;
		color: @color-red;
	}
}

// because this is an immediate child of body, we cannot scope it
.md-virtual-repeat-container.md-autocomplete-suggestions-container {
	height: 170px;
	width: 400px;
	z-index: 1500;
	padding: 0 18px;

	.md-autocomplete-suggestion.selected {
		outline: 2px auto @color-outline !important;
		outline-offset: -1px !important;
	}

	.md-contact-suggestion span.md-contact-email {
		color: @color-off-gray;
	}
}
