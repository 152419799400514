/** Buttons */

.btn {
	&.reply-btn {
		color: @color-dark-grey;
	}

	&.edit-btn {
		color: var(--color-secondary);
	}

	&.cancel-btn {
		color: @color-dark-grey;
	}

	&.close-btn {
		color: @color-dark-grey;
	}

	&.remove-btn {
		color: @color-dark-grey;

		[class^="ficon"] {
			color: var(--color-primary);
		}
	}

	&.keep-btn,
	&.post-btn {
		[class^="ficon"] {
			color: var(--color-secondary);
		}
	}

	&.discard-btn {
		[class^="ficon"] {
			color: #BE1E2D;
		}
	}

	&.download-btn {
		color: @color-dark-grey;
	}

	&.print-btn {
		color: @color-dark-grey;
	}

	&.reply-text-comment-btn {
		color: @color-dark-grey;
	}

	&.reply-audio-comment-btn {
		color: @color-dark-grey;
	}

	&.reply-video-comment-btn {
		color: @color-dark-grey;
	}

	&.reply-video-comment-upload-btn {
		color: @color-dark-grey;
	}

	&.toggle-desc-btn {
		color: @color-dark-grey;
	}
}
