.rb-element.rb-category {
  padding: 5px 0;
  border-top: none;

  .rb-title {
	display: block;
	font-size: 16px;
	color: @color-emperor;
	font-weight: normal;
	margin-right: 120px;
  }

  .rb-desc {
	display: block;
	color: @color-emperor;
	margin-right: 120px;
	font-style: italic;
  }

  .rb-category-badge.rb-points-badge {
	background-color: transparent;
	border: none;
  }
}
