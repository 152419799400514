notification-ribbon {
	position: relative;
	display: block;
	overflow: hidden;
	height: 0;
	transition: height 0.5s ease 0s;

	.alert {
		border-radius: 0;
		padding: 10px;
		margin-bottom: 0;
	}

	&.active {
		z-index: 1;
		height: 42px;
	}
}
