@import '~go-styles/src/_/mixins';

.fine-uploader {
	.qq-upload-drop-area {
		&.qq-upload-drop-area-active {
			border: 2px dashed @color-grey;
		}
	}

	.qq-upload-button {
		background: none;
		font-size: 120px;
		padding: 20px;
		border: 1px solid @color-dark-grey;

		&:lang(de) {
			// German locale
			padding: 20px 0;
		}

		i, .title {
			color: @color-white;
		}

		&:active,
		&:hover {
			border: none;
			text-shadow: none;
			background-color: @color-dark-grey;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			text-decoration: none;
			color: @color-white;
		}

		.title {
			font-size: 28px;
			font-weight: 300;
			text-align: center;
		}

		.desc {
			color: @color-white;
			font-size: 14px;
			font-weight: 400;
			padding: 5px;
		}
	}

	.keep-btn {
		.ficon-check {
			color: var(--color-secondary);
		}
	}

	.discard-btn {
		.ficon-ban {
			color: @color-red;
		}
	}

	/** Single file uploader */
	&.single {
		position: relative;
		width: 100%;
		height: 100%;

		.files {
			text-align: center;

			.file {
				width: clamp(300px, 18.75rem, 450px);
				display: inline-block;

				input {
					display: block;
					width: 100%;
					margin-bottom: 10px;
				}

				.progress-indicator {
					font-size: 57px;
					font-weight: 300;

					i, span {
						color: @color-white;
					}
				}

				.progress {
					margin-bottom: 10px;
					//        height: 28px;
					background-color: @color-dark-grey;
					box-shadow: inset 1px 1px 2px @color-black;

					.bar,
					.progress-bar {
						border-top-left-radius: 4px;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 4px;
						background-color: @color-white;
						box-shadow: inset 1px 1px 1px @color-black;
						line-height: 21px;
						text-shadow: 1px 1px 1px #aaa;
						color: @color-white;
					}
				}

				.control-bar {
					color: @color-white;
					margin-top: 15px;

					button + button {
						margin-left: 20px;
					}

					.title {
						font-size: 16px;
						margin-bottom: 10px;
					}

					.btn {
						display: block;
						width: 100%;
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.fup-alert {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		margin-bottom: 0;
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		padding: 8px 14px;

		&.error-message {
			margin: 0;

			.close {
				position: static;
				color: inherit;
			}

			button.close {
				padding: 0;
				cursor: pointer;
				background: transparent;
				border: 0;
				-webkit-appearance: none;
			}
		}
	}

	.alert-danger.focus-order {
		position: relative;

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
		}
	}
}
