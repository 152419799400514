// @import '~go-modules/src/player-controls/style';
// Keep in sync with go-modules/src/player-controls/style
@controls-height: clamp(70px, 2.8125rem, 90px);

multi-media-display {
	position: relative;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;

	.stimulus-section {
		flex: 1;
	}

	> section.player-controls-enabled {
		bottom: clamp(55px, 3.4375rem, 110px);
	}

	> comment-captions {
		position: absolute;
		left: 0;
		right: 0;
		bottom: calc(@controls-height + .625rem); // Render always .625rem above the 45px tall player controls
	}

	.post-discard-bar {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 45px;
		color: @color-white;

		.post-discard-buttons {
			display: flex;
			gap: 8px;

			.tertiary-btn {
				color: @color-white;

				&:focus, &:hover {
					background-color: @color-off-gray;
				}
			}

			.secondary-btn {
				background-color: @color-white;

				&:focus, &:hover {
					background-color: @color-xlight-grey2;
				}
			}
		}
	}
}
