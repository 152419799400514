.rb-category {
  &.rb-widget {
	font-size: 13pt;
	font-weight: bold;
	text-align: left;
	cursor: pointer;

	.rb-title {
	  font-size: 16px;
	  font-weight: bold;
	  background-color: @color-emperor;
	  color: white;
	  padding: 8px 12px;
	  border-radius: 8px;

	  &:hover,
	  &:active {
		background-color: #888888;
	  }
	}
  }
}
