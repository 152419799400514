session-attachments {
	display: flex;
	flex: 1;
	min-width: 240px;

	go-attachments {
		flex: 1;
		width: 100%;
	}
}
