@import '~go-styles/src/_/variables';

rubric-element-with-handle-wrapper {
	display: block;
	margin-bottom: 16px;

	&:focus-within, &:hover {
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	}

	.rubric-element-with-handle-wrapper {
		display: flex;
		flex-flow: row;

		.rubric-element-with-handle {
			display: flex;
			align-items: center;
			background-color: @color-light-bg;
			cursor: move;

			&[aria-grabbed="true"] {
				background-color: @color-dark-grey;

				i {
					color: @color-white;
				}
			}
		}

		ng-transclude {
			width: 100%;
		}
	}
}

