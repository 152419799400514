@import '~go-styles/src/_/variables';
@import 'control/style';
@import 'thumb/style';

slide-toggle {
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	color: inherit;

	.slide-toggle-label {
		order: 1;
	}

	slide-toggle-control {
		order: 2;
	}

	&[disabled] {
		.slide-toggle-label {
			cursor: default;
		}
	}

	.slide-toggle-label {
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		color: inherit;
	}

	ng-transclude {
		margin-right: 6px;

		&:empty {
			display: none;
		}
	}

	&.toggle-left {
		slide-toggle-control {
			order: 1;
		}

		.slide-toggle-label {
			order: 2;
		}

		ng-transclude {
			margin-right: 0px;
			margin-left: 6px;

			&:empty {
				display: none;
			}
		}
	}

	&.toggle-right {
		.slide-toggle-label {
			order: 1;
		}

		slide-toggle-control {
			order: 2;
		}

		ng-transclude {
			margin-right: 6px;
			margin-left: 0px;

			&:empty {
				display: none;
			}
		}
	}
}
