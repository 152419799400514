// For Safari the canvas and video elements need to be visible on the screen.
//  However, this way the user will never notice.
.motion-detector {
	position: fixed; // In case the user scrolls the window.
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	opacity: .001;
}
