slide-toggle-thumb {
	@thumb-bg-color: #fafafa;
	position: relative;
	display: inline-block;
	line-height: 0;
	transition: left 100ms linear;
	width: 14px;
	height: 14px;
	border-radius: inherit;
	top: -2px;

	.symbol {
		fill: @thumb-bg-color;
		overflow: visible;
		width: 100%;
		filter: drop-shadow(1px 1px 4px rgba(0,0,0,.25));

		&:hover {
			fill: lighten(@thumb-bg-color, 8%);
		}

		&:active {
			fill: darken(@thumb-bg-color, 8%);
		}
	}

	&[disabled] {
		pointer-events: none;
	}
}
