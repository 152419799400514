feedback-session-content {
	display: block;

	bg-pane-left {
		display: flex;
		flex-direction: column;
	}

	feedback-display-panel {
		background-color: @color-white;
		border-right: 1px solid @color-light-grey2;
		z-index: 3;
	}

	feedback-display,
	comment-sync-event-viewer {
		&.ng-enter, &.ng-leave {
			transition:opacity ease-in-out 0.5s
		}

		&.ng-enter,
		&.ng-leave.ng-leave-active {
			opacity: 0;
		}

		&.ng-leave,
		&.ng-enter.ng-enter-active {
			opacity: 1;
		}
	}

	#left-section {
		min-width: 240px;

		> ng-transclude {
			display: flex;
			flex-direction: column;
		}
	}

	#right-section {
		display: flex;
		justify-content: left;
		min-width: 240px;

		@media (max-width: @screen-collapse-min) {
			flex-direction: column;
			background: @color-indigo;
		}
	}

	media-display {
		flex: 1;
	}

	/** Horizontal line */
	.line {
		height: 0px;
		margin: 0px;
		border-bottom: 1px solid @color-light-grey;
		font-size: 1px;
		display: inline-block;
		vertical-align: middle;
	}

	@media (max-width: @screen-collapse-min) {
		bg-splitter#main-splitter {
			> bg-handle {
				background-color: @color-indigo;

				.handle-grip {
					left: calc(50% - 5px);
					top: -12px;
					height: 60px;
					width: 6px;
					background-color: @color-light-grey2;
					border: none;
					display: flex;
					z-index: 4;
					position: relative;
					align-items: center;
					border-radius: 6px;

					i.ficon-app-drag-drop {
						display: none;
					}

					// to enlarge the clickable area
					&::before {
						content: "";
						position: absolute;
						top: -20px;
						left: -11px;
						width: 23px;
						height: 100px;
						background-color: transparent;
						z-index: -2;
					}

					&[aria-grabbed="true"] {
						background-color: @color-light-grey;
					}
				}
			}
		}
	}
}
