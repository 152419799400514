time-remaining-countdown {
	display: block;
	font-size: inherit;
	font-weight: inherit;
	opacity: 0;
	transition: opacity 4s;
	transition-timing-function: ease-in;

	&.show-time-display {
		transition-duration: 0.5s;
		opacity: 1;
	}

	&.hidden {
		display: none;
	}
}
