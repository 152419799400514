@import '~go-styles/src/_/variables';

go-typeahead-select {
	display: flex;
	flex-direction: column;
	background-color: white;

	&.large {
		@large-size: 45px;
		@large-font: 1.3em;
		.selected-item-template-container, ul > li > span {
			min-height: @large-size;
			font-size: @large-font;
			display: flex;
			align-items: center;
		}

		input {
			min-height: @large-size;
			font-size: @large-font;
		}

		li > a {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: @large-font;
			min-height: @large-size;
		}
	}

	> uib-dropdown {

		// No extra margin on button
		.form-group {
			margin: 0;
		}

		input {
			transition: none !important; // Screw you bootstrap, you make me flicker as I come and go
		}

		// Since we want our info options, but we dont
		// want them keyboard navigable, we must
		// copy styles from .dropdown-menu > li > a
		// We also want the transcluded item to look
		// the same in both places.
		.selected-item-template-container, ul > li > span {
			display: block;
			padding: 3px 12px;
			clear: both;
			font-weight: 400;
			line-height: 1.42857143;
		}

		.selected-item-template-container {
			cursor: pointer;
			display: flex;
			justify-content: flex-start;
			text-align: left;
			align-items: center;
			height: initial;
			min-height: 34px;
			padding-left: 12px;
			border: 1px solid #c6c6c6;
			border-radius: 2px;
		}

		input {
			cursor: pointer;
		}

		> ul {
			width: 100%;
		}

		go-typeahead-item-template {
			flex: 1;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			white-space: normal;

			.presenter-name {
				font-weight: bold;
			}

			.presenter-name, .presenter-email {
				display: block;
				overflow-x: hidden;
				max-width: 375px;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-left: 5px;
			}
		}
	}

	// Handle ellipsis overflow
	.go-typeahead-item-container a {
		white-space: normal;
	}

	.zero-state-item {
		a:hover {
			background-color: transparent !important; // Override hover color
			cursor: default;
		}
	}

	// Simulate a disabled look on the selected item
	.typeahead-input-group.disabled {
		cursor: not-allowed;

		.selected-item-template-container {
			background-color: @color-gallery;
			border: 1px solid @color-xlight-grey;
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075); // Match disabled input box shadow
		}

		* {
			pointer-events: none;
		}
	}

	.go-typeahead-item-container + .zero-state-item {
		margin-top: 20px;
	}
}
