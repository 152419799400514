@import 'variables';

.border-none {
  border: none;
}


[class^="btn-none-"],
[class*=" btn-none-"],
.btn-none {
  border: none;
  text-shadow: none;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-decoration: none;

  &.disabled,
  &[disabled] {
    color: lighten(@color-emperor, 10%);
    background: none;
    cursor: no-drop;
	}

	&.btn-none-primary {
		color: var(--color-primary);
	}
}
