@import '~go-styles/src/_/variables';

.equipment-check-modal {

  video-scene {
    height: 300px;
    max-width: 400px;
	margin: auto;
	margin-top: 25px;
	position: relative;
  }

  .equipment-status-check {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.title {
		display: flex;

		.icon {
			margin-right: 8px;

			&.ficon-network {
				font-size: 0.8em;
			}
		}
	}

	.status {
		margin-left: auto;
	}

	.status-item {
		display: flex;
		align-items: center;

		.icon {
			margin-right: 5px;
		}

		&.success .icon {
			color: var(--color-secondary);
		}

		&.failure .icon {
			color: @color-red3;
		}
	}
  }

  .troubleshooting-link {
    margin-right: auto;
  }

  .connection-status-fail {
    p {
		color: @color-primary-error;
    }
  }
}
