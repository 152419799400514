@import '~go-styles/src/_/variables';

// If this changes, please update single-media-display and multi-media-display
@controls-height: clamp(70px, 2.8125rem, 90px);

@controls-horizontal-margin: 15px;
@controls-main-slider-progress: var(--color-secondary);
@controls-main-slider-background: #FEFEFE;
@controls-main-slider-trim-background: #C4C4C4;
@controls-main-slider-tooltip-color: @color-darker-grey-font;
@controls-main-slider-tooltip-background: #D9D9D9;

// Colors
@controls-color-white: #F1F1F1;
@controls-color-dark: #606F70;
@controls-color-icons: #F3F3F3;
@controls-color-cuepoints: #FDB900;
@controls-btn-sedentary: #9B9B9B;

@controls-trim-slider-background: #000000;

@controls-volume-slider-container-background: #595959;

@controls-live-toggle: #EC1C24;

@btn-width: 30px;
@btn-height: 27px;
@scrubber-tooltip-triangle-height: 0.5em;

@controls-btn-disabled: rgba(255, 255, 255, 0.35);

.player-controls-media-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
}

player-controls {
	height: @controls-height;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	font-size: clamp(14px, .875rem, 28px);
	position: relative; // Needed for settings popover
	background-color: @color-indigo;
	padding: 10px @controls-horizontal-margin 13px @controls-horizontal-margin;

	&:hover, &:focus-within, &:focus {
		.noUi-tooltip, .noUi-handle:before {
			display: initial;
		}
	}

	.noUi-tooltip, .noUi-handle:before {
		display: none;
	}

	&.minimized-controls {
		border-radius: 10px;
		background-color: @color-indigo;

			.controls-section {
				.bordered-section {
					> button:not(:first-child)  {
						padding: 0px 8px;
					}

					> button:first-child {
						padding-right: 8px;
					}
				}

				.centered-section {
					display: flex;
					align-items: center;

					> * + * {
						margin-left: 8px;
					}
				}
			}

		.scrub-bar-section {
			margin-bottom: calc(@scrubber-height - .0625rem);
		}

		.timeline-slider {
			.noUi-handle {
				display: none;
			}
		}

		&:hover {
			.timeline-slider {
				.noUi-handle {
					display: block;
				}
			}
		}
	}

	.scrub-bar-section {
		height: @scrubber-height;
		margin-top: calc(@scrubber-height - .0625rem);
		display: flex;
		position: relative;

		.duration {
			color: @color-white;
			margin-left: 10px;
			font-size: clamp(11px, .6875rem, 22px);
			align-self: center;
			font-family: sans-serif;
		}
	}

	.live-section {
		&.stream-inactive {
			justify-content: space-between;
			flex: 1;
		}
	}

	.live-timer {
		color: @color-white;
		margin: 0;
		font-size: clamp(24px, 1.5rem, 48px);
	}

	.live-stream-viewer-count {
		color: @color-white;
	}

	.trim-stepper {
		display: flex;
		align-items: center;
		background-color: rgba(0,0,0,0.3);
		padding: 0px 5px;
		margin-right: 6px;

		.steps {
			display: flex;
			flex-direction: column;
		}

		.trim-increment, .trim-decrement {
			font-size: clamp(10px, .625rem, 20px);
		}

		.trim-time {
			color: @color-white;
			flex: 1;
			margin-left: 5px;
		}
	}

	.timeline-slider {
		// Grow the width to entire container
		flex: 1;
		// Shrink the bar's thickness
		height: @scrubber-height;
		// Remove vendor grey border
		border: none;
		// We don't want vendor inset 3d progress bar
		box-shadow: none;

		border-radius: 10px;

		// Set the color of the non-progressed (right hand) region
		background-color: @controls-main-slider-background;
		// Set the color of the progressed (left hand) region
		.noUi-connect {
			background-color: @controls-main-slider-progress;
		}
		// And in trim mode, we've got to change the color
		&.trim-mode {
			background-color: @controls-trim-slider-background;
		}

		// We want a pointer so people can see you can jump to a location
		&:not([disabled]) {
			cursor: pointer;
		}

		&.settings-active {
			// Don't show slide tooltip when the settings popup is open
			.noUi-tooltip {
				display: none;
			}

			// Nor its triangle in trim or non-trim mode
			&:not(.trim-mode) .noUi-handle:before,
			&.trim-mode .noUi-handle[data-handle="1"]:before {
				display: none;
			}
		}

		// Make the normal time handle disappear
		// In trim mode this will be handle number 1
		&:not(.trim-mode) .noUi-handle,
		&.trim-mode .noUi-handle[data-handle="1"] {
			// Disappear the background color, shadow, and border
			background-color: transparent;
			box-shadow: none;
			border: none;

			// Normally the scrubber height is 18px, and the handle is 28px
			// So we need to make the handle the same ratio.
			@scale: (@scrubber-height/18px);
			// Even though it's now invisible, it affects the position
			// of the tooltip and the touch area.
			@height: @scale * 28px;
			height: @height;
			// Top is half of the part of the handle that sticks out from the scrubber
			@top: -(@height - @scrubber-height) / 2;
			top: @top;

			// The handle normally has two notches (vertical bars) we need to hide
			// The right side we will just outright hide
			&:after {
				display: none;
			}

			// The left side we will transform into the notch into a triangle that
			// will point the tooltip to the exact spot on the slider
			&:before {
				// First neutralize the nouislider's notch
				height: 0;
				width: 0;
				background: none;

				// Now let's make a triangle
				border-top: solid @scrubber-tooltip-triangle-height @controls-main-slider-tooltip-background;
				border-left: solid @scrubber-tooltip-triangle-height transparent;
				border-right: solid @scrubber-tooltip-triangle-height transparent;

				// Center it horizontally
				left: 50%;
				transform: translate(-50%);

				// Position it at the top of the scrubber bar. Now this is relative to the
				// handle, so we will have to add it's negative top to the triangle size
				top: calc(-@scrubber-tooltip-triangle-height - @top);
			}

			// Make the cursor go to the grab/grabbing state when clicked and dragged
			cursor: grab;
			&:active {
				cursor: grabbing;
			}

			// Focus should put the outline around the tooltip, not the invisible handle
			// We generally want to keep all focus stuff in goreact-style.less and just change the dom
			// But since this is 3rd party, we are going to bend the rules a little
			&:focus {
				outline: none !important;
				.noUi-tooltip {
					outline: @outline-width auto @color-outline !important;
				}
			}

			// Restyle the tooltip. Right now it's too big and bulky. We're going
			// to shrink it down and increase the aspect ratio.
			.noUi-tooltip {
				// Position it right above the triangle
				bottom: calc(100% + @scrubber-tooltip-triangle-height + @top);

				// This needs to be text size aware, so everything should be measured in em
				width: 8ch;
				height: 1.5em;
				box-sizing: content-box;
				padding: 0;
				border-radius: 0.2em;

				// The font is too big. Shrink it down
				font-size: 0.8em;

				// Color is too bright, reset it
				color: @controls-main-slider-tooltip-color;
				background: @controls-main-slider-tooltip-background;
			}
		}

		&.trim-mode .noUi-handle:not([data-handle="1"]) {
			background-color: var(--color-secondary);
			box-shadow: 0px 1px 3px rgba(255, 255, 255, 30%);
			border: none;

			// Normally the handles are pretty square (34px x 28px = 17/14 = 1.214 ratio)
			// But we want a more retangular handle (3/5 = 0.6 ratio)
			@ratio: ((34/28) / (3/5));

			// And we've also changed the scrubber height from the default 18px
			@scale: (@scrubber-height/18px);

			// So first scale the width to the fraction of the scrubber height
			width: @scale * 34px;
			// And right (normally -17px with a 1px border)
			right: @scale * -16px;
			// Now scale the height, replacing their ratio with our ratio
			@height: @scale * @ratio * 28px;
			height: @height;
			// Top is half of the part of the handle that sticks out from the scrubber
			top: -(@height - @scrubber-height) / 2;

			// The handle normally has two notches (vertical bars), but we want 4 horizontal ones
			// The right side we will just outright hide
			&:after {
				display: none;
			}

			// The left side we will instead use a font-icon
			&:before {
				// Set the size of the icon
				height: 0.75em;
				font-size: 0.75em;
				width: 100%;
				line-height: 1;

				background: none;
				font-family: goreact-custom2;
				color: @controls-color-white;
				content: "\e90f";

				// Center it horizontally/vertically
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			// Make the cursor go to the grab/grabbing state when clicked and dragged
			cursor: grab;
			&:active {
				cursor: grabbing;
			}
		}

		// But we don't want to show grabbing if we are disabled
		&[disabled] .noUi-handle {
			cursor: not-allowed;
		}
	}

	player-controls-settings {
		position: absolute;
		width: clamp(250px, 14.375rem, 460px);
		bottom: calc(@controls-height + .3125rem);
		left: @controls-horizontal-margin;
		z-index: 1;
	}

	.controls-section {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 12px;

		player-controls-settings {
			&.settings-active {
				left: unset;
				right: 15px;
			}
		}

		.controls-actions {
			button + button {
				border-left: 1px solid @color-payne-grey;
			}
		}

		.settings-button {
			font-size: 16px;
		}

		.bordered-section {
			display: flex;
			align-items: center;

			.play-btn {
				font-size: clamp(17px, 1.0625em, 34px);
				display: flex;
				align-items: center;
				justify-content: center;
				height: 19px;
				width: 30px;
				overflow: hidden;
			}

			.playback-rate {
				display: flex;
				justify-content: center;
				width: 43px;
				font-weight: bold;
				font-size: clamp(11.2px, 0.7rem, 22.4px);
			}

			> button:not(:first-child)  {
				padding: 0px 15px;
			}

			> button:first-child {
				padding-right: 15px;
			}
		}

		.centered-section {
			display: flex;
			align-items: center;

			> * + * {
				margin-left: 15px;
			}
		}

		button {
			background: transparent;
			border: none;
			font-size: 12px;
			padding: 0;
			min-width: 20px;

			i, span {
				color: @color-white;
			}

			&.btn-active {
				background-color: var(--color-secondary);
				padding: 0 3px;
				border-radius: 2px;
			}
		}

		.ficon-play {
			padding: 2.5px;
			width: 26px;
		}

		.ficon-app-player-pause {
			font-size: clamp(21px, 1.3125rem, 42px);
		}

		.fullscreen-btn {
			font-size: clamp(13px, .8125rem, 26px);
		}

		.volume-button, .skip-forward, .skip-backward {
			font-size: clamp(14px, .875rem, 28px);
		}
	}

	&.size-less-than-380 {
		.trim-stepper {
			.steps {
				display: none;
			}
		}
	}

	&.size-less-than-320 {
		.btn-sync {
			display: none;
		}

		.trim-stepper {
			display: none;
		}

		.live-timer {
			margin-right: auto;
			margin-left: auto;
		}

		.live-stream-viewer-count {
			display: none;
		}
	}

	.mute-warning {
		z-index: 1;
		color: white;
		background: @color-mic-bars;
		border-radius: 4px;
		padding: 15px 30px;
		align-self: center;
		position: absolute;
		bottom: 80px;
		box-shadow: -1px 1px 3px rgba(0,0,0,0.4), 1px 1px 3px rgba(0,0,0,0.4);

		&.ng-leave {
			opacity: 1;
			transition: opacity 0.3s linear;
		}

		&.ng-leave.ng-leave-active {
			opacity: 0;
		}
	}
}
