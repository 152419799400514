@import '~go-styles/src/_/variables';

.rb-scale {
  &.rb-widget {
	padding: 4px 4px 4px 8px;
	padding-bottom: 0;
	border: 1px dashed @color-grey;
	border-radius: 8px;
	cursor: pointer;
	text-align: left;

	&:hover,
	&:active {
	  background-color: @color-light-grey;
	}

	.rb-label {
	  margin-bottom: 3px;
	  font-style: italic;
	  text-align: left;
	}

	.rb-avatar {
	  font-size: 14px;
	}

	ul {
	  display: inline-block;
	  margin-bottom: 4px;
	  padding: 0;

	  li {
		display: inline-block;
		margin-left: 12px;
		font-weight: bold;
	  }
	}
  }
}
