.edit-user.modal {
	md-input-container:not(.readonly) {
		margin-bottom: 10px;
	}

	.language-section {
		select.language-selector {
			min-width: 170px;
		}
	}

	.change-password-btn {
		margin-right: 20px;
	}

	.email-input {
		margin-top: 16px;
	}

	.uneditable {
		margin: 0;
		color: @color-off-gray;
	}

	.credits {
		margin-top: 10px;
	}

	.email-and-password-buttons {
		margin-top: 10px;
	}

	.user-role-dropdown {
		display: flex;
		align-items: center;

		.permission-dropdown {
			flex: 1;
		}

		.move-btn {
			margin: 25px 0px 0px 10px;
		}
	}
}

.edit-user.modal ~ .cdk-overlay-container:has(.change-password-dialog-container),
.edit-user.modal ~ .cdk-overlay-container:has(.change-email-dialog-container) {
	z-index: 1060 !important;
}
