@import '~go-styles/src/_/variables';

.rb-points {
  &.rb-widget {
	padding: 10px 8px 10px;
	border: 1px dashed @color-grey;
	border-radius: 8px;
	cursor: pointer;
	text-align: left;

	&:hover,
	&:active {
	  background-color: @color-xlight-grey;
	}

	.rb-label {
	  margin-bottom: 8px;
	}

	.rb-avatar {
	  .rb-input {
		display: inline;
		padding: 4px;
		text-align: left;
		border: 1px solid @color-grey;
		background: @color-white;
	  }
	}
  }
}
