// @import '~go-modules/src/player-controls/style';
// Keep in sync with go-modules/src/player-controls/style
@controls-height: clamp(70px, 2.8125rem, 90px);

single-media-display {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;

	player-controls {
		> comment-captions {
			bottom: calc(@controls-height + .625rem);
		}
	}

	> comment-captions {
		position: absolute;
		left: 0;
		right: 0;
		bottom: calc(@controls-height + .625rem);
	}
}
