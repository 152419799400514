@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/mixins';

@overlay-black: rgba(43, 43, 43, 0.85);
@time-remaining-red: rgba(174, 43, 23, 0.85);
@controls-height: 25px;

video-scene {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;

	.recorder-main {
		display: flex;
		justify-content: center;
		background-color: @color-black;
		flex: 1;
		flex-direction: column;
		align-items: center;
	}

	.mic-warning {
		z-index: 1;
		color: white;
		background: @color-mic-bars;
		border-radius: 4px;
		padding: 15px 30px;
		align-self: center;
		position: absolute;
		bottom: 60px;
		box-shadow: -1px 1px 3px rgba(0,0,0,0.4), 1px 1px 3px rgba(0,0,0,0.4);

		&.ng-leave {
			opacity: 1;
			transition: opacity 0.3s linear;
		}

		&.ng-leave.ng-leave-active {
			opacity: 0;
		}
	}

	.output-container {
		display: grid;
		grid-template-rows: repeat(auto-fit, minmax(calc(50% - 2px), 1fr));
		grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2px), 1fr));
		grid-gap: 2px;
		align-items: center;
		justify-content: center;
		height: 100%;

		&.mirror-video {
			.GR_publisher {
				transform: scale(-1, 1);
			}
		}

		&[num-streams="5"], &[num-streams="6"],
		&[num-streams="7"], &[num-streams="8"], &[num-streams="9"] {
			grid-template-columns: repeat(auto-fit, minmax(calc(33% - 2px), 1fr));
			grid-template-rows: repeat(auto-fit, minmax(calc(33% - 2px), 1fr));
		}

		.position-stream (@nth, @position) {
			// https://stackoverflow.com/a/18122516/1248889
			@children: ~":nth-child(@{nth})";
			> @{children} {
				canvas, video {
					object-position: @position;
				}
			}
		}

		// For num-streams 1 and 2
		.position-stream('n', center);

		&[num-streams="3"], &[num-streams="4"] {
			.position-stream('2n - 1', right);
			.position-stream('2n', left);
		}

		&[num-streams="5"], &[num-streams="6"] {
			.position-stream('-n + 3', bottom);
			.position-stream('n + 4', top);
		}

		&[num-streams="7"], &[num-streams="8"], &[num-streams="9"] {
			.position-stream('3n - 2', right);
			.position-stream('3n - 1', center);
			.position-stream('3n', left);
		}
	}

	ngx-status-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.aspect-ratio-babysitter {
		flex: 1;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	aspect-ratio-container.media-container {

		> ng-transclude {
			position: relative;
		}

		video {
			width: 100%;
			height: auto;
			max-height: 100vh;
		}

		time-remaining-countdown {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: @controls-height;
			right: 0;
			left: 0;
			z-index: 2;
			color: @color-white;
			background-color: @time-remaining-red;
			font-size: 46px;
			font-weight: 300;
			white-space: nowrap;
		}
	}

	.no-audio-msg {
		color: @color-white;
	}

	.small-label {
		font-size: 12px;
	}

	.media-footer-bar {

		background-color: @color-indigo;

		&.layout-update {
			@media (max-width: @screen-xsm-min) {
				justify-content: flex-start;

				.recording-controls {
					gap: 4px;
					margin: 0 8px 0 10px;
				}
			}

			@media (max-width: @screen-xxsm-min) {
				height: 80px;
				flex-direction: row;
				justify-content: flex-start;

				.recording-controls {
					flex-direction: column;
					margin-bottom: 10px;
				}

				.toggle-controls {
					.options-menu-btn {
						bottom: 20px;
					}
				}

				.options-menu {
					bottom: 70px;
				}
			}
		}

		.toggle-controls {
			button span, button i {
				color: @color-white;
			}
		}

		.timer-container {
			display: flex;
			align-items: center;
			ngx-time-display,
			time-remaining {
				font-size: 16px;
			}
			.record-btn-indicator {
				visibility: hidden;
				font-size: 10px;
				margin-right: 5px;
				&.blink {
					visibility: visible;
				}
			}
		}

		.options-menu-btn {
			display: flex;
			justify-content: center;
			position: absolute;
			right: 8px;
			bottom: 8px;
			background-color: @color-secondary-header-background;
			color: @color-white;
			border: none;
			font-size: 20px;
			// Width needed for ios safari
			width: 35px;
		}

		volume-indicator {
			margin: 0 2px;

			.black {
				background: @color-mic-bars;
			}
		}

		.restart-btn {
			color: @color-white;

			&:focus, &:hover {
				background-color: @color-off-gray;
			}
		}

		.restart-icon-btn {
			height: 40px;

			.icon {
				font-size: 24px;
			}
		}

		.secondary-btn {
			background-color: @color-white;
		}
	}

	.options-menu {
		display: flex;
		flex-direction: column;
		position: absolute;
		right: 2px;
		bottom: 55px;
		border-radius: 5px;
		background-color: @color-secondary-header-background;
		padding: 5px;

		button {
			margin: 0 2px;
			line-height: 1.3;
		}
	}

	.media-settings {
		right: 0;
		left: 0;
		margin: auto;
		display: grid;
		padding: 25px;
		gap: 25px;
		width: clamp(300px, 90vw, 400px);

		.item-container {
			display: grid;
			color: @color-white;

			label {
				font-weight: normal;
				color: @color-white;
			}

			.item-content {
				display: grid;
				grid-template-columns: 1fr 45px;
				align-items: center;
				gap: 5px;

				.select-box {

					select {
						background: transparent;

						// This is needed to make the dropdown look correct on windows DEV-7706
						color: @color-white;
						border: 1px solid @color-white;
						border-radius: 3px;
						outline: none;
						padding: 8px 40px 8px 15px;
						width: 100%;
						appearance: none;
						z-index: 1;

						// This is needed to make the dropdown look correct on windows DEV-7706
						option {
							color: @overlay-black;
						}
					}

					&::after {
						content: "\E930"; // chevron down
						font-size: 1.5em;
						font-family: "goreact-custom2";
						z-index: 0;
						margin-left: -30px;
						vertical-align: middle;
						pointer-events: none;

						// This is needed to make the dropdown look correct on windows DEV-7706
						color: @color-white;
					}
				}

				.item-action-icon {
					i {
						color: @color-white;
					}
				}

			}
		}

		.tertiary-btn {
			color: @color-white;
		}
	}

	.recording-controls {
		display: flex;
		gap: 8px;
		margin: 0 20px 0 10px;
		white-space: nowrap;

		@media (max-width: @screen-xs-min) {
			margin: 0 10px 0 10px;
		}

		.recording-control {
			margin-right: 5px;
		}

		:last-child	{
			margin-right: 0;
		}
	}

	.toggle-control {
		margin: 0 5px;
		line-height: 1;
		outline-offset: 2px;
	}

	.toggle-controls {
		display: inline-flex;
		margin: 0 10px;
		white-space: nowrap;
	}

	// Prevent opentok from breaking our layout
	// Make the min-dimensions on the video below instead
	.OT_subscriber {
		min-width: initial;
		min-height: initial;
	}

	// Styles for display destination
	.GR_publisher {
		display: flex;
		align-content: center;
		height: 100%;

		> video[autoplay] {
			position: relative;
			min-width: 48px;
			min-height: 48px;
		}

		> canvas {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.GR_publisher,
	.OT_widget-container {
		background-color: #151515;
	}

	// The user name is displayed in this bar
	.OT_publisher .OT_edge-bar-item,
	.OT_subscriber .OT_edge-bar-item {
		position: absolute;
		top: auto !important;
		bottom: 0;
		font-size: 12px;
		height: 24px;
		line-height: 24px;
	}

	// When multi-presenter layout is on, the CSS
	// needs slight adjustments for the layout changes
	// Moves from grid (best fit) to our custom crown layout
	.output-container.screen-share-layout {
		grid-template-columns: repeat(auto-fit, minmax(calc(12.5% - 2px), 1fr));
		grid-template-rows: [viewers-start] 12.5% [presenter-start] auto [presenter-end];
		background: black;
		grid-template-rows: [viewers-start] 16.66% [presenter-start] auto [presenter-end];

		.screen-share-active {
			grid-row: presenter-start / presenter-end;
			grid-column: span 8;

			// Fix the CSS when screen-share-er is not the publishing user
			&.OT_subscriber > .OT_widget-container {
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;

				> video.OT_video-element {
					height: initial;
				}
			}

			// Make some alterations to publisher div
			&.GR_publisher {
				display: flex;
				justify-content: center;
				overflow: hidden;
				align-items: center;

				> canvas {
					height: initial;
				}
			}
		}
	}
}

