@import '~go-styles/src/_/variables';
@import '~go-styles/src/_/modals';

@import 'umc/modal';
@import 'invite-users/modal';
@import 'status/modal';
@import 'user-activity-history/modal';
@import 'confirm/modal';
@import 'edit-user/modal';
@import 'edit-session-score/modal';
@import 'rubric-unpublished/modal';
@import 'session-timeout/modal';
@import 'create-course/modal';

.modal .modal-dialog.modal-xlg {
	@media (min-width: @screen-sm-min) {
		width: 600px;
		margin: 30px auto;
	}
	@media (min-width: @screen-md-min) {
		width: 900px;
	}
	@media (min-width: @screen-lg-min) {
		width: 1280px;
	}
	width: auto;
	margin: 10px;
}

// always full screen
.modal .modal-dialog.modal-full {
	width: 100%!important;
	height: 100%!important;
	margin: 0;

	.goreact-modal-body {
		height: 100%;
		padding: 0;
	}
	.modal-content {
		overflow: hidden;
		height: 100%!important;
		border: none;
	  	border-radius: 0;
	}
}

.modal .goreact-modal-footer {
	button + button {
		margin-left: 5px;
	}
}
