/** nav-pills */
@import 'variables';

ul.nav-pills,
ul.nav-tabs {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	border-bottom: none;

	> li {
		margin-bottom: 0;
		> a,
		button {
			background-color: transparent;
			border: none;
			text-decoration: none;
			border-radius: 0;
			text-transform: uppercase;

			// This removes negative space from the top
			// If our font size or line-height were to change
			// this may need to be adjusted
			&:before {
				content: '';
				margin-top: -6px;
				height: 0;
				width: 0;
				display: block;
			}

			&:focus {
				background-color: transparent;
			}

			&:hover {
				opacity: 1;
				background-color: transparent;
				border: none;
				border-bottom: 3px solid @color-light-grey;
			}

			&.light {
				color: white;
			}
		}
		&.active {
			> a,
			> a:focus,
			> a:hover,
			button,
			button:hover,
			button:focus {
				opacity: 1;
				color: @color-dark;
				background-color: transparent;
				border: none;
				border-bottom: 3px solid var(--color-tertiary);

				&.light {
					color: white;
				}
			}
		}
		&.disabled,
		&:disabled {
			@opacity: .65;
			text-decoration: none;
			cursor: not-allowed;
			pointer-events: none; // Future-proof disabling of clicks
			opacity: @opacity;
		}
	}

	> .btn-group {
		padding: 3px;
	}

	> .btn-group button {
		color: white;
		text-shadow: none;
		background-color: @color-med-grey;
	}

	> .btn-group button:hover {
		background-color: @color-med-grey2;
	}

	> .active.btn-group button {
		background-color: @color-dark-grey2;
	}

	> .active.btn-group button:hover {
		background-color: @color-grey;
	}
}
